import React, { useEffect, useState } from "react";
import { NavLink, useLocation, matchPath } from "react-router-dom";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  Typography,
  Button,
  Divider,
  Paper,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import EmbedLogo from "../assets/single_embed_logo.svg";
import CaseListIconSVG from "../assets/case_list_icon.svg";
import CaseDetailIconSVG from "../assets/case_detail.svg";
import ArdavanAvatar from "../assets/avatar_ardavan_golshan.svg";
import ThemeToggle from "../common/components/ThemeToggle";
import DashboardIconSVG from "../assets/dashboard_icon.svg";
import TableListSVG from "../assets/table_icon.svg";
import { FaTable } from "react-icons/fa";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { IoSettingsSharp } from "react-icons/io5";

const Sidebar = () => {
  const theme = useTheme();
  const { user, isLoading, getAccessTokenSilently, logout } = useAuth0();

  // Popover anchor
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Our “role” could be "Admin" or "Analyst" or something else
  const [role, setRole] = useState<string | null>(null);

  // If the user is an admin, we store and show the "client_id"
  const [clientId, setClientId] = useState<string | null>(null);

  // We might not actually need to store “decodedToken” in state,
  const [decodedToken, setDecodedToken] = useState<any>(null);
  const location = useLocation();

  // On mount or when user changes, decode the access token
  useEffect(() => {
    const decodeAccessToken = async () => {
      if (!isLoading && user) {
        try {
          const accessToken = await getAccessTokenSilently();
          const decoded: any = jwtDecode(accessToken);
          setDecodedToken(decoded); // for debugging/inspection

          // 1. Check roles from the namespaced claim
          const roles: string[] =
            decoded["https://app.embedsecurity.com/roles"] || [];

          // 2. Check user's email domain (also toLowerCase for safety)
          const userEmail = user?.email?.toLowerCase() || "";

          // 3. If roles contains "admin" or email ends with @embedsecurity.com
          //    treat as admin
          const isAdmin = roles.some((r) => r.toLowerCase() === "admin");

          if (isAdmin) {
            setRole("Admin");
            // Also read client_id if we want to show it
            const maybeClientId =
              decoded["https://app.embedsecurity.com/client_id"];
            setClientId(maybeClientId || null);
          } else {
            // Not admin => call them "Analyst" or "User"
            setRole("Analyst");
            setClientId(null);
          }
        } catch (err) {
          console.error("Error decoding access token:", err);
        }
      }
    };

    decodeAccessToken();
  }, [isLoading, user, getAccessTokenSilently]);

  // Check if the current path matches /cases/:id
  const isCaseDetailActive = matchPath(
    { path: "/cases/:id", end: false },
    location.pathname
  );

  // Shared styles for all list items
  const listItemStyles = {
    textDecoration: "none",
    color: "inherit",
    position: "relative",
    justifyContent: "center",
    fontSize: "0.875rem",
    marginBottom: "2.5rem",
    "&.active": {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
      "& img": {
        transform: "scale(1.2)",
        transition: "transform 0.3s ease",
      },
    },
  };

  // Icon size and color styling
  const iconStyles = {
    // By default, apply the same grayscale/brightness as images:
    "& img, & svg": {
      filter: "grayscale(100%) brightness(70%)",
      transition: "transform 0.3s ease",
    },
    // On hover, scale both <img> and <svg>:
    "&:hover img, &:hover svg": {
      transform: "scale(1.5)",
    },
    // If using NavLink’s `.active`, can remove the filter or change color:
    "&.active img, &.active svg": {
      filter: "none",
    },
    "&.active:hover img, &.active:hover svg": {
      transform: "scale(1.5)",
    },
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Open the Popover when the avatar is clicked
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the Popover
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "user-info-popover" : undefined;

  return (
    <Paper
      sx={{
        width: 80,
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "2rem",
        boxShadow: "2px 0 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      {/* Top - Embed Company Logo */}
      <Box>
        <img
          src={EmbedLogo}
          alt="Embed Company Logo"
          style={{ width: "40px", marginTop: "1em", marginBottom: "1em" }}
        />
      </Box>

      {/* Middle - Navigation Icons */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <List>
          {/* TODO: Reintegrate after dashboard is complete */}
          <ListItem
            component={NavLink}
            to="/dashboard"
            sx={listItemStyles}
            disablePadding
          >
            <ListItemIcon
              sx={{ justifyContent: "center", color: "inherit", ...iconStyles }}
            >
              <img src={DashboardIconSVG} alt="Dashboard Icon" />
            </ListItemIcon>
          </ListItem>

          {/* Cases List Item */}
          <ListItem
            component={NavLink}
            to="/cases"
            end
            sx={listItemStyles}
            disablePadding
          >
            <ListItemIcon
              sx={{ justifyContent: "center", color: "inherit", ...iconStyles }}
            >
              <img src={TableListSVG} alt="Table List Icon" />
            </ListItemIcon>
          </ListItem>

          {/* Case Detail List Item Non-Clickable */}
          <ListItem
            sx={{
              ...listItemStyles,
              ...(isCaseDetailActive && {
                borderLeft: `4px solid ${theme.palette.primary.main}`,
              }),
              pointerEvents: "none", // Makes this item non-clickable
              cursor: "default",
            }}
            disablePadding
          >
            <ListItemIcon
              sx={{ justifyContent: "center", color: "inherit", ...iconStyles }}
            >
              <img src={CaseDetailIconSVG} alt="Case Detail Icon" />
            </ListItemIcon>
          </ListItem>

          {/* Customers Link - Admin Only */}
          {role === "Admin" && (
            <ListItem
              component={NavLink}
              to="/customers"
              sx={listItemStyles}
              disablePadding
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  color: "grey",
                  ...iconStyles,
                }}
              >
                <IoSettingsSharp size={30} />
              </ListItemIcon>
            </ListItem>
          )}
        </List>
      </Box>

      {/* Bottom - User Avatar */}
      <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
        <Avatar
          src={user?.picture || ArdavanAvatar}
          alt="User Avatar"
          sx={{ width: 40, height: 40, cursor: "pointer" }}
          onClick={handleAvatarClick}
        />
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Box sx={{ p: 2, minWidth: 240 }}>
            {/* --- Top: Centered Avatar & Email --- */}
            <Stack spacing={1} alignItems="center" mb={2}>
              <Avatar
                src={user?.picture || ArdavanAvatar}
                alt="User Avatar"
                sx={{ width: 56, height: 56 }}
              />
              <Typography variant="body2" color="textSecondary">
                {user?.email}
              </Typography>
            </Stack>

            <Divider sx={{ mb: 2 }} />

            {/* --- Middle: Role & Client ID (left-aligned "label: value" style) --- */}
            <Stack spacing={1} mb={2}>
              {role && (
                <Box>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Role
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {role}
                  </Typography>
                </Box>
              )}
              {role === "Admin" && clientId && (
                <Box>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Client ID
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {clientId}
                  </Typography>
                </Box>
              )}
            </Stack>

            {/* --- Theme Settings --- */}
            <Stack spacing={1} mb={2}>
              <Typography variant="subtitle2" fontWeight="bold">
                Theme Settings
              </Typography>
              <ThemeToggle />
            </Stack>

            {/* --- Bottom: Logout Button --- */}
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  logout({
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  })
                }
              >
                Log Out
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </Paper>
  );
};

export default Sidebar;
