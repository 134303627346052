import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  TextField,
  Button,
  Chip,
  useTheme,
  Alert,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";

interface AddUsersStepProps {
  email: string;
  setEmail: (val: string) => void;
  onNext: () => void;
  onBack: () => void;
  invitedEmails?: string[];
  setInvitedEmails?: (val: string[]) => void;
  isLoading?: boolean;
  invitationErrors?: { email: string; error: string }[];
}

const AddUsersStep: React.FC<AddUsersStepProps> = ({
  email,
  setEmail,
  onNext,
  onBack,
  invitedEmails = [],
  setInvitedEmails = () => {},
  isLoading = false,
  invitationErrors = [],
}) => {
  const theme = useTheme();
  const [emailError, setEmailError] = useState<string | null>(null);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  };

  const handleAddEmail = () => {
    if (!email.trim()) {
      setEmailError(null);
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    if (!invitedEmails.includes(email)) {
      setInvitedEmails([...invitedEmails, email]);
      setEmail("");
      setEmailError(null);
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setInvitedEmails(invitedEmails.filter((e) => e !== emailToRemove));
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddEmail();
    }
  };

  // Check if there are any emails to be sent
  const hasEmails = invitedEmails.length > 0 || (email && validateEmail(email));

  return (
    <>
      <Box textAlign="center" mt={4}>
        <Typography
          variant="h4"
          style={{
            fontFamily: theme.typography.h3.fontFamily,
            fontWeight: theme.typography.h3.fontWeight,
            fontSize: theme.typography.h3.fontSize,
            color: theme.palette.text.primary,
          }}
        >
          Invite Team{" "}
          <span style={{ color: theme.palette.primary.main }}>Members</span>
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontSize: "14px",
            marginTop: "10px",
            color: theme.palette.grey[500],
            marginBottom: "20px",
          }}
        >
          Invite team members to collaborate with you. You can add more members
          later.
        </Typography>
      </Box>

      <Card sx={{ p: 3, mb: 3, borderRadius: "8px" }}>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{ mb: 1, color: theme.palette.text.primary }}
        >
          Invite Users by Email
        </Typography>

        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            placeholder="Email Address"
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (emailError) setEmailError(null);
            }}
            onKeyPress={handleKeyPress}
            error={!!emailError}
            helperText={emailError || " "}
            disabled={isLoading}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddEmail}
            sx={{ ml: 1, height: 56, minWidth: 120, mb: 2.5 }}
            disabled={isLoading || !email.trim() || !validateEmail(email)}
          >
            Add
          </Button>
        </Box>

        {invitedEmails.length > 0 && (
          <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
            {invitedEmails.map((invitedEmail) => (
              <Chip
                key={invitedEmail}
                label={invitedEmail}
                onDelete={() => handleRemoveEmail(invitedEmail)}
                deleteIcon={<Close />}
                sx={{
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                }}
                disabled={isLoading}
              />
            ))}
          </Box>
        )}

        {invitationErrors.length > 0 && (
          <Alert severity="error" sx={{ mt: 2, mb: 1 }}>
            <Typography variant="subtitle2">
              The following invitations failed:
            </Typography>
            <ul style={{ margin: 0, paddingLeft: 20 }}>
              {invitationErrors.map((error, index) => (
                <li key={index}>
                  {error.email}: {error.error}
                </li>
              ))}
            </ul>
          </Alert>
        )}
      </Card>

      <Box display="flex" justifyContent="space-between" width="100%">
        <Button variant="outlined" onClick={onBack} disabled={isLoading}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          disabled={Boolean(
            isLoading || (hasEmails && invitationErrors.length > 0)
          )}
        >
          {isLoading
            ? "Sending Invitations..."
            : invitationErrors.length > 0
            ? "Fix Invitation Errors"
            : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default AddUsersStep;
