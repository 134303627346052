import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  useTheme,
  Paper,
  Tooltip,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import EmailIcon from "@mui/icons-material/Email";
import DownloadIcon from "@mui/icons-material/Download";

import InvestigativeStepsTabs from "./InvestigativeStepsTabs";
import EmailImageSection from "./EmailImageSection";
import ReactFlowSection from "./ReactFlowSection";

import { useAuth0 } from "@auth0/auth0-react";
import LoadingAnimation from "../../../common/components/LoadingAnimation";

/**
 * Can define a more specific type if analyses have certain fields
 * but this at least ensures we have the same shape that InvestigativeStepsTabs expects
 */
interface Analysis {
  name: string;
  kind: string;
  conclusion: string;
  summary: string;
  data?: {
    total: number;
    malicious: string[];
    benign: string[];
    inconclusive: string[];
  };
  questions?: {
    q: string;
    a: string;
  }[];
}

interface EmailFlowAndInvestigativeStepsProps {
  emailImageUrl: string;
  caseDetail: any;
  caseId: string;
  analyses: any;
  hasNavigation: boolean;
  canGoPrevious: boolean;
  canGoNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

const EmailFlowAndInvestigativeSteps: React.FC<
  EmailFlowAndInvestigativeStepsProps
> = ({
  emailImageUrl,
  caseDetail,
  caseId,
  analyses = [], // default if analyses is undefined
  hasNavigation,
  canGoPrevious,
  canGoNext,
  onPrevious,
  onNext,
}) => {
  const [selectedView, setSelectedView] = useState<"image" | "graph" | "eml">(
    "image"
  );
  const [cachedImageData, setCachedImageData] = useState<string | null>(null);
  const [cachedEmlData, setCachedEmlData] = useState<string | null>(null);
  const [imageError, setImageError] = useState<string | null>(null);
  const [emlLoading, setEmlLoading] = useState<boolean>(false);
  const [emlError, setEmlError] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();

  // -------------- Fetch Email Image --------------
  const fetchEmailImage = useCallback(async () => {
    if (cachedImageData || !emailImageUrl) return; // Already fetched or no URL

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(emailImageUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch image. Status: ${response.status}`);
      }

      const blob = await response.blob();
      const imageObjectURL = URL.createObjectURL(blob);
      setCachedImageData(imageObjectURL);
    } catch (err: any) {
      console.error("Error fetching email image:", err);
      setImageError(
        err.message || "An error occurred while fetching the image"
      );
    }
  }, [cachedImageData, emailImageUrl, getAccessTokenSilently]);

  // -------------- Fetch EML --------------
  const fetchEmlData = useCallback(async () => {
    // If we already have the EML or there's no case ID, do nothing
    if (cachedEmlData || !caseDetail?.id) return;

    setEmlLoading(true);
    setEmlError(null);

    try {
      const token = await getAccessTokenSilently();
      const emlUrl = `${process.env.REACT_APP_CASE_DETAILS_URL}/${caseDetail.id}/files/eml`;
      const response = await fetch(emlUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          `Failed to fetch .eml file. Status: ${response.status}`
        );
      }

      const data = await response.text();
      setCachedEmlData(data);
    } catch (err: any) {
      console.error("Error fetching .eml file:", err);
      setEmlError(
        err.message || "An error occurred while fetching the .eml file"
      );
    } finally {
      setEmlLoading(false);
    }
  }, [cachedEmlData, caseDetail?.id, getAccessTokenSilently]);

  // -------------- Download EML --------------
  const handleDownloadEml = () => {
    if (!cachedEmlData) return;
    const blob = new Blob([cachedEmlData], { type: "message/rfc822" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${caseDetail?.id || "email"}.eml`;
    link.click();
    URL.revokeObjectURL(url);
  };

  // -------------- Use Effects --------------

  // If user selects "eml" tab, we attempt to fetch if not fetched yet
  useEffect(() => {
    if (selectedView === "eml" && !cachedEmlData && !emlLoading) {
      fetchEmlData();
    }
  }, [selectedView, cachedEmlData, emlLoading, fetchEmlData]);

  // on mount, attempt to fetch the image. Also cleanup
  useEffect(() => {
    fetchEmailImage();

    return () => {
      if (cachedImageData) {
        URL.revokeObjectURL(cachedImageData);
      }
    };
  }, [emailImageUrl, fetchEmailImage, cachedImageData]);

  // -------------- Render --------------
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(72vh - 64px)",
        gap: "1rem",
        flexGrow: 1,
        mb: 1,
      }}
    >
      {/* Button Row */}
      <Box sx={{ display: "flex", gap: "1rem", marginBottom: "0.01rem" }}>
        {/* View Email Image */}
        <Button
          onClick={() => setSelectedView("image")}
          variant={selectedView === "image" ? "contained" : "outlined"}
          startIcon={<ImageIcon />}
          sx={{
            color:
              selectedView === "image"
                ? theme.palette.common.white
                : theme.palette.grey[500],
            borderColor:
              selectedView === "image"
                ? theme.palette.info.main
                : theme.palette.grey[500],
            backgroundColor:
              selectedView === "image"
                ? theme.palette.info.main
                : "transparent",
            "&:hover": {
              backgroundColor:
                selectedView === "image"
                  ? theme.palette.info.dark
                  : theme.palette.info.light,
              borderColor: theme.palette.info.dark,
            },
          }}
        >
          View Email Image
        </Button>

        {/* View .EML */}
        <Button
          onClick={() => setSelectedView("eml")}
          variant={selectedView === "eml" ? "contained" : "outlined"}
          startIcon={<EmailIcon />}
          sx={{
            color:
              selectedView === "eml"
                ? theme.palette.common.white
                : theme.palette.grey[500],
            borderColor:
              selectedView === "eml"
                ? theme.palette.info.main
                : theme.palette.grey[500],
            backgroundColor:
              selectedView === "eml" ? theme.palette.info.main : "transparent",
            "&:hover": {
              backgroundColor:
                selectedView === "eml"
                  ? theme.palette.info.dark
                  : theme.palette.info.light,
              borderColor: theme.palette.info.dark,
            },
          }}
        >
          View .EML File
        </Button>

        {/* View Event Graph */}
        <Button
          onClick={() => setSelectedView("graph")}
          variant={selectedView === "graph" ? "contained" : "outlined"}
          startIcon={<AccountTreeIcon />}
          sx={{
            color:
              selectedView === "graph"
                ? theme.palette.common.white
                : theme.palette.grey[500],
            borderColor:
              selectedView === "graph"
                ? theme.palette.info.main
                : theme.palette.grey[500],
            backgroundColor:
              selectedView === "graph"
                ? theme.palette.info.main
                : "transparent",
            "&:hover": {
              backgroundColor:
                selectedView === "graph"
                  ? theme.palette.info.dark
                  : theme.palette.info.light,
              borderColor: theme.palette.info.dark,
            },
          }}
        >
          View Event Graph
        </Button>

        {/* Next/Previous navigation */}
        {hasNavigation && (
          <Box sx={{ marginLeft: "auto", display: "flex", gap: "0.5rem" }}>
            <Button
              variant="outlined"
              onClick={onPrevious}
              disabled={!canGoPrevious}
              sx={{
                minWidth: theme.spacing(12),
                color: theme.palette.text.secondary,
                borderColor: theme.palette.grey[500],
                "&:hover": { borderColor: theme.palette.info.dark },
              }}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              onClick={onNext}
              disabled={!canGoNext}
              sx={{
                minWidth: theme.spacing(12),
                color: theme.palette.text.secondary,
                borderColor: theme.palette.grey[500],
                "&:hover": { borderColor: theme.palette.info.dark },
              }}
            >
              Next
            </Button>
          </Box>
        )}
      </Box>

      {/* Main Content Row */}
      <Box sx={{ display: "flex", gap: "1rem", width: "100%", height: "100%" }}>
        {/* Selected View Section */}
        <Box
          sx={{
            flexBasis: "70%",
            flexGrow: 1,
            height: "95%",
            position: "relative",
          }}
        >
          {/* IMAGE */}
          {selectedView === "image" && (
            <EmailImageSection
              emailImageUrl={emailImageUrl}
              imageData={cachedImageData}
              error={imageError}
            />
          )}

          {/* EML */}
          {selectedView === "eml" && (
            <Paper sx={{ position: "relative", height: "100%" }}>
              {/* Download Button (top-right) */}
              <Tooltip
                title={emlLoading ? "Loading .eml file" : "Download .eml file"}
                placement="top"
              >
                <IconButton
                  onClick={handleDownloadEml}
                  disabled={emlLoading || !cachedEmlData}
                  sx={{
                    position: "absolute",
                    top: "-3rem",
                    right: 0,
                    color: theme.palette.info.main,
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows[2],
                    "&:hover": {
                      backgroundColor: theme.palette.grey[100],
                      boxShadow: theme.shadows[6],
                    },
                    borderRadius: "4px",
                    zIndex: 1,
                  }}
                  aria-label="Download .eml file"
                >
                  {emlLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <DownloadIcon />
                  )}
                </IconButton>
              </Tooltip>

              {/* Content */}
              <Paper
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: theme.palette.common.white,
                  boxShadow: theme.shadows[3],
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {emlLoading ? (
                  <Paper
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexGrow: 1,
                    }}
                  >
                    <LoadingAnimation />
                  </Paper>
                ) : emlError ? (
                  <Paper>Error: {emlError}</Paper>
                ) : (
                  <Paper sx={{ overflow: "auto", flexGrow: 1, p: "1rem" }}>
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-all",
                        margin: 0,
                        fontFamily: "'Inter', sans-serif",
                        fontSize: "0.85rem",
                        lineHeight: 1.5,
                      }}
                    >
                      {cachedEmlData}
                    </pre>
                  </Paper>
                )}
              </Paper>
            </Paper>
          )}

          {/* GRAPH */}
          {selectedView === "graph" && (
            <ReactFlowSection
              data={caseDetail.graph}
              artifacts={caseDetail.artifacts}
              caseId={caseId}
              emailBodyImageData={cachedImageData}
              analyses={analyses}
            />
          )}
        </Box>

        {/* Investigative Steps Section */}
        <Box
          sx={{ flexBasis: "30%", flexGrow: 1, maxWidth: "30%", height: "95%" }}
        >
          <InvestigativeStepsTabs
            analyses={analyses}
            artifacts={caseDetail.artifacts}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EmailFlowAndInvestigativeSteps;
