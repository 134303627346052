// src/pages/WaitForAdminPage.tsx
import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import embedLogo from "../assets/embed-logo-text.svg";
import onboardImage from "../assets/onboard-dashboard-img.svg";
import { useAuth0 } from "@auth0/auth0-react";

const WaitForAdminPage: React.FC = () => {
  const theme = useTheme();
  const { logout } = useAuth0();

  return (
    <Container
      maxWidth="md"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: "40px",
        marginTop: "80px",
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        padding={4}
        position="absolute"
        top={0}
      >
        <img
          src={embedLogo}
          alt="Embed Security Logo"
          style={{ height: "40px" }}
        />
        <Button
          variant="outlined"
          style={{
            color: theme.palette.grey[700],
            borderColor: theme.palette.grey[500],
          }}
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          }
        >
          Log Out
        </Button>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        mt={4}
      >
        <Typography
          variant="h4"
          style={{
            fontFamily: theme.typography.h3.fontFamily,
            fontWeight: theme.typography.h3.fontWeight,
            fontSize: theme.typography.h3.fontSize,
            color: theme.palette.text.primary,
          }}
        >
          You’re{" "}
          <span style={{ color: theme.palette.primary.main }}>Almost</span>{" "}
          There!
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontSize: "14px",
            marginTop: "10px",
            color: theme.palette.grey[500],
          }}
        >
          Reach out to your admin to add you to our application
        </Typography>
        {/* <img
          src={onboardImage}
          alt="Onboarding Dashboard"
          style={{ width: "100%", marginTop: "20px" }}
        /> */}
        {/* <Button
          variant="contained"
          color="primary"
          size="large"
          style={{
            marginTop: "-120px",
            padding: "10px 20px",
            fontSize: "16px",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
          onClick={() =>
            (window.location.href =
              "mailto:[email protected]?subject=Please%20Add%20Me%20to%20the%20Team&body=Hello%20Admin")
          }
        >
          Email Admin
        </Button> */}
        <Typography
          variant="body2"
          sx={{ mt: 2, color: theme.palette.grey[600] }}
        >
          Need more help? Contact our support team at{" "}
          <span style={{ color: theme.palette.primary.main }}>
            seth@embed.com
          </span>{" "}
          or{" "}
          <span style={{ color: theme.palette.primary.main }}>
            dustin@embed.com
          </span>{" "}
        </Typography>
      </Box>
    </Container>
  );
};

export default WaitForAdminPage;
