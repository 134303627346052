import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
  Controls,
  Background,
  Position,
  useReactFlow,
} from "@xyflow/react";
import dagre from "@dagrejs/dagre";
import "@xyflow/react/dist/style.css";
import { useTheme } from "@mui/system";
import {
  CircularProgress,
  IconButton,
  Typography,
  Button,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CustomNode from "./CustomNode";
import CustomEdge from "./CustomEdge";
import RadialMenu from "./RadialMenu";
import ArtifactDetails from "./ArtifactDetails";

import {
  fetchArtifactPreview,
  fetchAttachmentPreview,
} from "../../../api/caseDetailsApi";
import { RootState } from "../../../app/rootReducer";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useAuth0 } from "@auth0/auth0-react";

import { AdjacencyMap, buildAdjList, RawNode } from "../helpers/buildAdjList";
import CloudNodeDetails from "./CloudNodeDetails";
import {
  clearAttachmentPreview,
  clearURLPreview,
} from "../redux/caseDetailsSlice";

export interface VisibleResult {
  visibleIds: Set<string>;
  edges: Edge[];
  hiddenCounts: Record<string, number>;
}

////////////////////////////////////////////////////////////////////////////////
// Helpers
////////////////////////////////////////////////////////////////////////////////
const nodeWidth = 150;
const nodeHeight = 150;
const layoutDirection = "LR";

function extractDomain(url: string) {
  try {
    const parsed = new URL(url);
    return parsed.hostname.replace(/^www\./, "");
  } catch {
    return url.replace(/^www\./, "");
  }
}

/**
 * Identify root nodes dynamically: any node with zero parents.
 * (Requires that buildAdjList keeps track of each node's parents in adjacency.)
 */
function findAllRoots(adjacency: AdjacencyMap): string[] {
  const roots: string[] = [];
  for (const [id, entry] of Object.entries(adjacency)) {
    // If AdjacencyMap doesn't store "parents", you may need to
    // compute it or store it inside buildAdjList.
    // For now, we assume buildAdjList sets `parents: Set<string>`.
    if (entry.parents.size === 0) {
      roots.push(id);
    }
  }
  return roots;
}

export function transformNodes(
  rawNodes: RawNode[],
  expandedNodes: Record<string, boolean>,
  hiddenCounts: Record<string, number>
): Node[] {
  console.log("rawNodes: ", rawNodes);
  return rawNodes.map((raw) => {
    const isExpanded = expandedNodes[raw.id] || false;
    const isMalicious =
      raw.decision === "Malicious" || raw.kind === "CLOUD_ALERT";
    const hidden = hiddenCounts[raw.id] || 0;

    let label = raw.displayName || raw.id;
    if (raw.kind === "Email URL") {
      label = extractDomain(label);
    }

    return {
      id: raw.id,
      type: "custom",
      position: { x: 0, y: 0 },
      data: {
        nodeId: raw.id,
        label,
        searchKey: raw.displayName as string,
        kind: raw.kind,
        decision: raw.decision ?? "",
        isMalicious,
        isExpanded,
        hiddenCount: hidden,
        service: raw?.data?.service || undefined,
        data: raw.data || {},
      },
    };
  });
}

// Actually produce the edges
export function transformEdges(
  adj: AdjacencyMap,
  expandedNodes: Record<string, boolean>
): Edge[] {
  const edges: Edge[] = [];
  const MAX_COLLAPSED_TOTAL = 3;

  Object.values(adj).forEach(({ node, children }) => {
    if (!children.length) return;

    const expanded = !!expandedNodes[node.id];
    const malKids = children.filter((c) => c.decision === "Malicious");
    const benKids = children.filter((c) => c.decision !== "Malicious");

    let visibleChildren: RawNode[] = [];
    if (!expanded) {
      if (malKids.length > MAX_COLLAPSED_TOTAL) {
        visibleChildren = [...malKids];
      } else {
        const leftoverSlots = MAX_COLLAPSED_TOTAL - malKids.length;
        visibleChildren = [...malKids, ...benKids.slice(0, leftoverSlots)];
      }
    } else {
      visibleChildren = [...malKids, ...benKids];
    }

    visibleChildren.forEach((child) => {
      edges.push({
        id: `${node.id}--${child.id}`,
        source: node.id,
        target: child.id,
        type: "custom",
      });
    });
  });

  return edges;
}

function computeHiddenCounts(
  adjacency: AdjacencyMap,
  expandedNodes: Record<string, boolean>
): Record<string, number> {
  const hiddenCounts: Record<string, number> = {};
  for (const { node, children } of Object.values(adjacency)) {
    if (!children.length) {
      hiddenCounts[node.id] = 0;
      continue;
    }
    const expanded = !!expandedNodes[node.id];
    const malKids = children.filter((c) => c.decision === "Malicious");
    const benKids = children.filter((c) => c.decision !== "Malicious");

    let visibleCount = 0;
    if (expanded) {
      // all
      visibleCount = children.length;
    } else {
      // collapsed
      if (malKids.length > 3) {
        visibleCount = malKids.length;
      } else {
        visibleCount = malKids.length + Math.max(0, 3 - malKids.length);
      }
      // clamp to total if no more benign than we have
      visibleCount = Math.min(visibleCount, children.length);
    }
    const total = children.length;
    hiddenCounts[node.id] = Math.max(total - visibleCount, 0);
  }
  return hiddenCounts;
}

// BFS from root node(s) - but root nodes are found via `findAllRoots()`
function gatherVisibleNodesBFS(
  adjacency: AdjacencyMap,
  expandedNodes: Record<string, boolean>,
  rootIds: string[]
) {
  const visited = new Set<string>();
  const edges: Edge[] = [];
  const queue: string[] = [...rootIds];

  while (queue.length > 0) {
    const currentId = queue.shift()!;
    if (visited.has(currentId)) continue;
    visited.add(currentId);

    // If we have no adjacency info for current, skip
    const entry = adjacency[currentId];
    if (!entry) continue; // might be an orphan

    const { node, children } = entry;
    if (!children.length) continue;

    // Decide which children to show
    const expanded = !!expandedNodes[node.id];
    const malKids = children.filter((c) => c.decision === "Malicious");
    const benKids = children.filter((c) => c.decision !== "Malicious");

    let visibleChildren: RawNode[];
    if (expanded) {
      visibleChildren = [...malKids, ...benKids];
    } else {
      // collapsed => all malicious plus enough benign up to 3 total
      if (malKids.length > 3) {
        visibleChildren = malKids;
      } else {
        const leftover = 3 - malKids.length;
        visibleChildren = [...malKids, ...benKids.slice(0, leftover)];
      }
    }

    // Mark edges & add children to queue
    for (const child of visibleChildren) {
      edges.push({
        id: `${node.id}--${child.id}`,
        source: node.id,
        target: child.id,
        type: "custom",
      });
      // only enqueue child if not visited
      if (!visited.has(child.id)) {
        queue.push(child.id);
      }
    }
  }

  return { visited, edges };
}

function getLayoutedElements(nodes: Node[], edges: Edge[]) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: layoutDirection,
    align: "UL",
    nodesep: 80,
    ranksep: 100,
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodePos = dagreGraph.node(node.id);
    node.position = {
      x: nodePos.x - nodeWidth / 2,
      y: nodePos.y - nodeHeight / 2,
    };
    node.targetPosition = Position.Left;
    node.sourcePosition = Position.Right;
    return node;
  });

  return { nodes: layoutedNodes, edges };
}

////////////////////////////////////////////////////////////////////////////////
// Main Exports
////////////////////////////////////////////////////////////////////////////////
export interface ReactFlowSectionProps {
  data: {
    nodes: RawNode[];
    edges: { u: string; v: string }[];
  };
  artifacts: Record<string, any>;
  caseId: string;
  emailBodyImageData?: string | null;
  analyses?: any[];
}

/**
 * ReactFlowSection: sets up the <ReactFlowProvider> then <InnerFlowCanvas/>.
 * No changes except we dynamically find root nodes in <InnerFlowCanvas> now.
 */
const ReactFlowSection: React.FC<ReactFlowSectionProps> = ({
  data,
  artifacts,
  caseId,
  emailBodyImageData,
  analyses,
}) => {
  return (
    <ReactFlowProvider>
      <InnerFlowCanvas
        data={data}
        artifacts={artifacts}
        caseId={caseId}
        emailBodyImageData={emailBodyImageData || ""}
        analyses={analyses || []}
      />
    </ReactFlowProvider>
  );
};

export default ReactFlowSection;

////////////////////////////////////////////////////////////////////////////////
// InnerFlowCanvas
////////////////////////////////////////////////////////////////////////////////
interface InnerFlowCanvasProps extends ReactFlowSectionProps {
  emailBodyImageData?: string | null;
  analyses: any[];
}

const InnerFlowCanvas: React.FC<InnerFlowCanvasProps> = ({
  data,
  artifacts,
  caseId,
  emailBodyImageData,
  analyses,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const {
    previewLoading,
    previewError,
    previewUrl,
    attachmentPreviewLoading,
    attachmentPreviewError,
    attachmentPreviewUrl,
  } = useAppSelector((state: RootState) => state.caseDetails);

  // adjacency for expand/collapse
  const adjacency = useMemo(() => buildAdjList(data.nodes, data.edges), [data]);

  // track expanded nodes
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>(
    {}
  );

  // track main flow nodes/edges
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);

  useEffect(() => {
    // Find all root nodes dynamically
    const rootIds: string[] = Object.entries(adjacency)
      .filter(([_, entry]) => entry.parents.size === 0)
      .map(([id]) => id);

    //  BFS from these dynamic root nodes (instead of "phishingReport")
    const { visited, edges } = gatherVisibleNodesBFS(
      adjacency,
      expandedNodes,
      rootIds
    );

    // compute hidden counts
    const hiddenCounts = computeHiddenCounts(adjacency, expandedNodes);

    // Build flowNodes from visited
    const flowNodes: Node[] = data.nodes
      .filter((raw) => visited.has(raw.id))
      .map((raw) => {
        const isExpanded = expandedNodes[raw.id] ?? false;
        const isMalicious =
          raw.decision === "Malicious" || raw.kind === "CLOUD_ALERT";
        const count = hiddenCounts[raw.id] ?? 0;

        let label = raw.displayName || raw.id;
        if (raw.kind === "Email URL") label = extractDomain(label);

        return {
          id: raw.id,
          type: "custom",
          position: { x: 0, y: 0 },
          data: {
            nodeId: raw.id,
            label,
            searchKey: raw.displayName as string,
            kind: raw.kind,
            decision: raw.decision || "",
            isMalicious,
            isExpanded,
            hiddenCount: count,
            service: raw?.data?.service || undefined,
            data: raw.data || {}, // Ensures we pass the nested data
            errorCode: raw?.data?.error_code || undefined,
          },
        };
      });

    //  Layout + set
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      flowNodes,
      edges
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [data, adjacency, expandedNodes, setNodes, setEdges]);

  // Expand/collapse toggler
  const toggleNode = useCallback((nodeId: string) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [nodeId]: !prev[nodeId],
    }));
  }, []);

  // The rest: radial menu, etc. ...
  const flowContainerRef = useRef<HTMLDivElement | null>(null);
  const { setCenter, getZoom, flowToScreenPosition } = useReactFlow();

  const [expandedNode, setExpandedNode] = useState<Node | null>(null);
  const [menuPos, setMenuPos] = useState<{ x: number; y: number } | null>(null);
  const [flowZoom, setFlowZoom] = useState(1.0);

  const onMove = useCallback(() => {
    if (!expandedNode || !flowContainerRef.current) return;
    const nodeCenter = {
      x: expandedNode.position.x + nodeWidth / 2,
      y: expandedNode.position.y + nodeHeight / 2,
    };
    const screenPos = flowToScreenPosition(nodeCenter);
    const containerRect = flowContainerRef.current.getBoundingClientRect();
    setMenuPos({
      x: screenPos.x - containerRect.left,
      y: screenPos.y - containerRect.top,
    });
    setFlowZoom(getZoom());
  }, [expandedNode, flowToScreenPosition, getZoom]);

  useEffect(() => {
    if (expandedNode) onMove();
  }, [expandedNode, onMove]);

  const [activeSlice, setActiveSlice] = useState<
    "" | "Details" | "Preview" | "Questions" | "Generate Preview"
  >("");
  const [detailsArtifactKey, setDetailsArtifactKey] = useState<string | null>(
    null
  );

  // TODO: Check nodeKinds
  console.log("ExpandedNode data: ", expandedNode?.data);
  const nodeKind = expandedNode?.data?.kind;
  const mimeType = (expandedNode?.data?.data as { mimeType?: string })
    ?.mimeType;

  const isCloudNode =
    expandedNode &&
    [
      "CLOUD_SOURCE",
      "CLOUD_IDENTITY",
      "CLOUD_ALERT",
      "CLOUD_ACTION",
      "CLOUD_RESOURCE",
      "CLOUD_APICALL",
      "CLOUD_SECURITY_GROUP",
    ].includes(expandedNode.data.kind as string);

  const onNodeClick = useCallback(
    (evt: React.MouseEvent, node: Node) => {
      evt.stopPropagation();
      if (expandedNode?.id === node.id) {
        setExpandedNode(null);
        setActiveSlice("");
        setDetailsArtifactKey(null);
      } else {
        setExpandedNode(null);
        setActiveSlice("");
        setDetailsArtifactKey(null);

        setCenter(
          node.position.x + nodeWidth / 2,
          node.position.y + nodeHeight / 2,
          {
            zoom: 2.0,
            duration: 300,
          }
        );

        setTimeout(() => setExpandedNode(node), 310);
      }
    },
    [expandedNode, setCenter]
  );

  const onPaneClick = useCallback(() => {
    setExpandedNode(null);
    setActiveSlice("");
    setDetailsArtifactKey(null);
  }, []);

  const onMoveStart = useCallback(() => {
    setExpandedNode(null);
    setActiveSlice("");
    setDetailsArtifactKey(null);
  }, []);

  const handleSliceClick = useCallback(
    async (sliceLabel: string) => {
      if (!expandedNode) return;
      if (activeSlice === sliceLabel) {
        setActiveSlice("");
        setDetailsArtifactKey(null);
        return;
      }
      setActiveSlice(sliceLabel as any);
      setDetailsArtifactKey(null);

      if (sliceLabel === "Details") {
        if (isCloudNode) {
          // Cloud node: show its `data` key
          setDetailsArtifactKey(expandedNode.id);
        } else {
          // Normal behavior for artifacts
          const artifactKey = expandedNode.data.searchKey;
          if (artifactKey && artifacts[artifactKey as string]) {
            setDetailsArtifactKey(artifactKey as string);
          }
        }
      } else if (sliceLabel === "Preview") {
        if (expandedNode.data.kind === "Email Body") {
          // We do NOT need to fetch anything, we already have `emailBodyImageData`.
          // Just open the overlay.
          setActiveSlice("Preview");
          return;
        }

        // If it's an Email Attachment, use fetchAttachmentPreview
        if (expandedNode.data.kind === "Email Attachment") {
          let attachmentHash = (expandedNode.data.data as any)?.hash;
          if (!attachmentHash && expandedNode.id.startsWith("attachments/")) {
            attachmentHash = expandedNode.id.substring("attachments/".length);
          }
          if (!attachmentHash) {
            // No hash => side popup with "No preview available"
            setActiveSlice("Preview");
            return;
          }

          // We do have a hash => attempt to fetch preview
          try {
            const token = await getAccessTokenSilently();
            await dispatch(
              fetchAttachmentPreview({
                accessToken: token,
                caseId: caseId,
                filehash: attachmentHash,
              })
            );
          } catch (err) {
            console.error("Attachment preview fetch error:", err);
          }
        }
        // If it's not an Email Attachment, check if there's a previewId
        else {
          const artifactKey = expandedNode.data.searchKey;
          const maybeArtifact = artifactKey
            ? artifacts[artifactKey as string]
            : null;
          if (maybeArtifact?.previewId) {
            // The existing fetchArtifactPreview logic:
            try {
              const token = await getAccessTokenSilently();
              const normalizedPreviewId = maybeArtifact.previewId.replace(
                /^\/cases/,
                ""
              );
              dispatch(
                fetchArtifactPreview({
                  accessToken: token,
                  previewId: normalizedPreviewId,
                })
              );
            } catch (err) {
              console.error("Preview fetch error:", err);
            }
          } else {
            // No preview found => side popup with "No preview available"
            setActiveSlice("Preview");
          }
        }
      }
    },
    [activeSlice, expandedNode, artifacts, dispatch, getAccessTokenSilently]
  );

  const handleClosePopup = () => {
    setActiveSlice("");
    setDetailsArtifactKey(null);
    dispatch(clearAttachmentPreview());
    dispatch(clearURLPreview());
  };

  const nodeTypes = useMemo(
    () => ({
      custom: (nodeProps: any) => (
        <CustomNode
          {...nodeProps}
          data={{
            ...nodeProps.data,
            onToggleNode: toggleNode,
          }}
        />
      ),
    }),
    [toggleNode]
  );

  const edgeTypes = useMemo(() => ({ custom: CustomEdge }), []);

  // For popups
  let radialMenuStyle: React.CSSProperties = { display: "none" };
  let sidePopupStyle: React.CSSProperties = { display: "none" };
  let overlayStyle: React.CSSProperties = { display: "none" };

  if (expandedNode && menuPos) {
    radialMenuStyle = {
      position: "absolute",
      left: menuPos.x,
      top: menuPos.y,
      transform: "translate(-80%, -45%)",
      zIndex: 9999,
      display: "block",
      pointerEvents: "none",
    };
  }

  const artifactKey = expandedNode?.data.searchKey || "";
  const maybeArtifact = artifactKey ? artifacts[artifactKey as string] : null;
  const hasPreviewId = !!maybeArtifact?.previewId;

  if (
    activeSlice &&
    (activeSlice === "Details" ||
      activeSlice === "Questions" ||
      (activeSlice === "Preview" && !hasPreviewId))
  ) {
    sidePopupStyle = {
      position: "absolute",
      left: Math.min(menuPos ? menuPos.x + 160 : 100, window.innerWidth - 100),
      top: Math.min(menuPos ? menuPos.y - 120 : 100, window.innerHeight - 100),
      minWidth: "480px",
      maxHeight: "40vh",
      background: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 8,
      zIndex: 20000,
      padding: "8px",
      boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
      display: "block",
      overflowY: "auto",
    };
  }

  // Check if node is an Email Attachment
  const isAttachmentNode = expandedNode?.data.kind === "Email Attachment";

  // Check if node is an Email Body
  const isEmailBodyNode = expandedNode?.data.kind === "Email Body";

  // Only allow "Preview" if:
  //    1) It’s not a cloud node
  //    2) If it’s an Email Attachment, it must be PDF
  const canPreview =
    !isCloudNode && (!isAttachmentNode || mimeType === "application/pdf");

  const shouldUseOverlay =
    (activeSlice === "Preview" && hasPreviewId) || // URL with previewId
    (activeSlice === "Preview" && isAttachmentNode) || // Attachment node
    (activeSlice === "Preview" && isEmailBodyNode);

  if (activeSlice === "Preview" && shouldUseOverlay) {
    overlayStyle = {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "900px",
      height: "900px",
      maxWidth: "90vw",
      maxHeight: "90vh",
      background: theme.palette.background.paper,
      borderRadius: 8,
      zIndex: 30000,
      padding: "16px",
      boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
      display: "block",
      overflow: "auto",
    };
  }

  let sidePopupHeader = activeSlice;
  let sidePopupContent: React.ReactNode = null;

  if (activeSlice === "Details") {
    sidePopupHeader = "Details";

    // Check if the node is 'Email Body'
    if (expandedNode?.data.kind === "Email Body") {
      // Find the CTA analysis
      const ctaAnalysis = analyses.find((a) => a.id === "cta");
      if (!ctaAnalysis) {
        sidePopupContent = <Typography>No CTA analysis found.</Typography>;
      } else {
        // Convert summary lines to bullet points:
        // e.g. ctaAnalysis.summary might have multiple lines with "• " or similar
        const lines = ctaAnalysis.summary
          .split("\n")
          .map((line: string) => line.trim());
        sidePopupContent = (
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 600, mb: 1 }}>
              {ctaAnalysis.name} — Decision: {ctaAnalysis.conclusion}
            </Typography>
            <ul style={{ paddingLeft: "1.5rem" }}>
              {lines.map((item: string, idx: React.Key | null | undefined) => (
                <li key={idx}>{item.replace(/^•\s?/, "")}</li>
              ))}
            </ul>
          </Box>
        );
      }
    } else {
      if (isCloudNode) {
        sidePopupContent = (
          <CloudNodeDetails
            nodeId={expandedNode.id}
            data={expandedNode.data?.data || {}}
          />
        );
      } else if (detailsArtifactKey && artifacts[detailsArtifactKey]) {
        sidePopupContent = (
          <ArtifactDetails
            artifactKey={detailsArtifactKey}
            artifact={artifacts[detailsArtifactKey]}
          />
        );
      } else {
        sidePopupContent = (
          <Typography variant="body2">No artifact found.</Typography>
        );
      }
    }
  } else if (activeSlice === "Questions") {
    sidePopupHeader = "Questions";
    if (expandedNode?.data.kind === "Email Body") {
      const ctaAnalysis = analyses.find((a) => a.id === "cta");
      if (
        !ctaAnalysis ||
        !ctaAnalysis.questions ||
        ctaAnalysis.questions.length === 0
      ) {
        sidePopupContent = (
          <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
            No Q&A found for CTA analysis.
          </Typography>
        );
      } else {
        // Render each Q & A
        sidePopupContent = (
          <Box>
            <Typography variant="body1" sx={{ fontWeight: 600, mb: 1 }}>
              {ctaAnalysis.name} — Q&A
            </Typography>
            {ctaAnalysis.questions.map((qa: any, idx: number) => (
              <Box key={idx} mb={2}>
                <Typography variant="subtitle2" color="text.secondary">
                  Q: {qa.q}
                </Typography>
                <Typography variant="body2" sx={{ ml: 2 }}>
                  A: {qa.a}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      }
    } else {
      // Grab the artifact from earlier
      const artifactKey = expandedNode?.data.searchKey || "";
      const artifact = artifactKey ? artifacts[artifactKey as string] : null;

      if (!artifact || !artifact.qna || artifact.qna.length === 0) {
        sidePopupContent = (
          <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
            No Q&A found for this URL.
          </Typography>
        );
      } else {
        // Show them in a simple list or something more styled
        sidePopupContent = (
          <Box>
            {artifact.qna.map((qa: any, idx: number) => (
              <Box key={idx} mb={2}>
                <Typography variant="subtitle1" color="text.secondary">
                  Q: {qa.q}
                </Typography>
                <Typography variant="body1" sx={{ ml: 2 }}>
                  A: {qa.a}
                </Typography>
              </Box>
            ))}
          </Box>
        );
      }
    }
  } else if (
    activeSlice === "Preview" &&
    (!hasPreviewId || !isAttachmentNode)
  ) {
    sidePopupHeader = "Preview";
    sidePopupContent = (
      <Box textAlign="center">
        <Typography variant="body1" mb={2}>
          No existing preview found for this artifact.
        </Typography>
        <Box display="flex" justifyContent="center" gap={2}>
          <Button
            variant="outlined"
            color="info"
            onClick={handleClosePopup}
            sx={{ minWidth: 120 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    );
  }

  let overlayContent: React.ReactNode = null;

  // For URL Previews
  if (activeSlice === "Preview" && shouldUseOverlay) {
    if (previewLoading || attachmentPreviewLoading) {
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          p={1}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="h6">Preview</Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <hr style={{ margin: "6px 0 10px 0" }} />
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
            <Typography variant="body1" ml={2}>
              Loading preview...
            </Typography>
          </Box>
        </Box>
      );
    } else if (previewError || attachmentPreviewError) {
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          p={1}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="h6">Preview</Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <hr style={{ margin: "6px 0 10px 0" }} />
          <Typography color="error" variant="body1" mt={2}>
            Error: {previewError}
          </Typography>
        </Box>
      );
    } else if (previewUrl) {
      // success: show the URL image
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          p={1}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="h6">Preview</Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <hr style={{ margin: "6px 0 10px 0" }} />
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="auto"
          >
            <img
              src={previewUrl}
              alt="Artifact Preview"
              style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: 4 }}
            />
          </Box>
        </Box>
      );
    } else if (attachmentPreviewUrl) {
      // success: show the attachment image
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          p={1}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="h6">Preview</Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <hr style={{ margin: "6px 0 10px 0" }} />
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="auto"
          >
            <img
              src={attachmentPreviewUrl}
              alt="Artifact Preview"
              style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: 4 }}
            />
          </Box>
        </Box>
      );
    } else if (expandedNode?.data.kind === "Email Body" && emailBodyImageData) {
      // Show the already-fetched "Email Body" image from EmailFlowAndInvestigativeSteps
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          p={1}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="h6">Preview</Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <hr style={{ margin: "6px 0 10px 0" }} />
          <Box
            flexGrow={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="auto"
          >
            <img
              src={emailBodyImageData}
              alt="Email Body Preview"
              style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: 4 }}
            />
          </Box>
        </Box>
      );
    } else {
      // We have neither error nor previewUrl => "No existing preview"
      overlayContent = (
        <Box
          position="relative"
          width="100%"
          height="100%"
          p={1}
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography variant="h6">Preview</Typography>
            <IconButton size="small" onClick={handleClosePopup}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <hr style={{ margin: "6px 0 10px 0" }} />
          <Typography variant="body1">
            No existing preview found for this artifact.
          </Typography>
        </Box>
      );
    }
  }

  const proOptions = { hideAttribution: true };

  return (
    <div
      ref={flowContainerRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        proOptions={proOptions}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={onNodeClick}
        onPaneClick={onPaneClick}
        onMove={onMove}
        onMoveStart={onMoveStart}
        onInit={(rfInstance) => {
          rfInstance.fitView({ padding: 0.2 });
        }}
        fitView
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Controls
          position="top-left"
          style={{
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[900]
                : theme.palette.background.paper,
            color: theme.palette.mode === "dark" ? "#000000" : "#000000",
            border: `1px solid ${theme.palette.divider}`,
            padding: "4px",
            zIndex: 10,
          }}
        />
        <Background color={theme.palette.grey[300]} />
      </ReactFlow>

      {/* Radial Menu */}
      {expandedNode && (
        <div style={radialMenuStyle}>
          <RadialMenu
            isOpen
            isMalicious={Boolean(expandedNode.data.isMalicious)}
            isCloudNode={Boolean(isCloudNode)}
            canPreview={canPreview}
            onSliceClick={handleSliceClick}
            scaleFactor={flowZoom * 0.65}
          />
        </div>
      )}

      {/* Side popup */}
      {activeSlice &&
        (activeSlice === "Details" ||
          activeSlice === "Questions" ||
          (activeSlice === "Preview" && !hasPreviewId)) && (
          <div style={sidePopupStyle}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ m: 0 }}>
                {sidePopupHeader}
              </Typography>
              <IconButton size="small" onClick={handleClosePopup}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <hr style={{ margin: "6px 0 10px 0" }} />
            <div>{sidePopupContent}</div>
          </div>
        )}

      {/* Overlay if we have preview */}
      {activeSlice === "Preview" && shouldUseOverlay && (
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 30000,
            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <div style={overlayStyle}>{overlayContent}</div>
        </div>
      )}
    </div>
  );
};
