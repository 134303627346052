import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  useTheme,
  TextField,
  Chip,
  alpha, // <-- ADD THIS IMPORT
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import VirusTotalIcon from "../../../assets/virustotal-logo.svg";
import EmailRepIcon from "../../../assets/email-rep-logo.png";
import IPInfoIcon from "../../../assets/ipinfo-logo.svg";
import URLScanIOIcon from "../../../assets/urlscan-logo.svg";

interface EnricherData {
  enricher: string; // e.g. "EmailRep", "VirusTotal"
  data: any;
}

type EnrichmentsMap = {
  [enricherName: string]: EnricherData;
};

interface Artifact {
  enrichments: EnrichmentsMap;
}

interface ArtifactsTabProps {
  // Each artifact key (e.g. "david@embedsecurity.com") maps to an Artifact
  artifacts: Record<string, Artifact>;
}

// Helper to unify searching text:
function matchesSearch(haystack: string, needle: string): boolean {
  return haystack.toLowerCase().includes(needle.toLowerCase());
}

// Map enricher name to an icon image
const ENRICHER_ICON_MAP: Record<string, string> = {
  VirusTotal: VirusTotalIcon,
  EmailRep: EmailRepIcon,
  IPInfo: IPInfoIcon,
  URLScanIO: URLScanIOIcon,
};

const ArtifactsTab: React.FC<ArtifactsTabProps> = ({ artifacts }) => {
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");

  // Convert the artifacts object into an array for iteration:
  const artifactEntries = Object.entries(artifacts || {});

  // If no artifacts at all:
  if (artifactEntries.length === 0) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body2" fontWeight="bold">
          No artifacts found.
        </Typography>
      </Box>
    );
  }

  // Handle search change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Filter logic: For each artifact, keep only the enrichers that match the query,
  // unless the artifact key itself is a match (in which case we keep all).
  const filteredArtifacts = artifactEntries
    .map(([artifactKey, artifactObj]) => {
      const artifactMatches = matchesSearch(artifactKey, searchQuery);

      // Keep only those enrichment entries that match the search
      const allEnrichments = Object.entries(artifactObj.enrichments || {});
      let filteredEnrichments = allEnrichments;

      if (!artifactMatches && searchQuery.trim() !== "") {
        // Filter by enricher name or the JSON data
        filteredEnrichments = allEnrichments.filter(([_, enricherObj]) => {
          const enricherName = enricherObj.enricher || _;
          const dataString = JSON.stringify(enricherObj.data) || "";

          return (
            matchesSearch(enricherName, searchQuery) ||
            matchesSearch(dataString, searchQuery)
          );
        });
      }

      // If the artifact name matches, or if after filtering we have some enrichers, keep it
      if (artifactMatches || filteredEnrichments.length > 0) {
        return {
          artifactKey,
          enrichments: filteredEnrichments,
        };
      } else {
        return null;
      }
    })
    // Filter out any artifacts that ended up with no match
    .filter(Boolean) as {
    artifactKey: string;
    enrichments: [string, EnricherData][];
  }[];

  return (
    <Box
      sx={{
        height: "100%",
        overflowY: "auto",
        padding: theme.spacing(2),
      }}
    >
      {/* Show search bar artifacts */}
      {artifactEntries.length && (
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Search Artifacts / Enrichers"
            variant="outlined"
            size="small"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Box>
      )}

      {filteredArtifacts.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="body2" fontWeight="bold">
            No artifacts match your search.
          </Typography>
        </Box>
      ) : (
        filteredArtifacts.map(({ artifactKey, enrichments }) => (
          <Box
            key={artifactKey}
            sx={{
              mb: 3,
              mt: 3,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: "8px",
              overflow: "hidden",
              boxShadow: theme.shadows[1],
            }}
          >
            {/* Show artifact name */}
            <Box
              sx={{
                maxWidth: "100%",
                whiteSpace: "normal",
                wordBreak: "break-word",
                overflowWrap: "anywhere",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? alpha(theme.palette.grey[400], 0.2)
                    : theme.palette.grey[50],
                p: 1,
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                {artifactKey}
              </Typography>
            </Box>

            {/* Now list all enrichments in an accordion */}
            {enrichments.length === 0 ? (
              <Box sx={{ p: 1 }}>
                <Typography variant="body2">
                  No enrichments found for this artifact.
                </Typography>
              </Box>
            ) : (
              enrichments.map(([enricherKey, enricherObj]) => {
                const enricherName = enricherObj.enricher || enricherKey;
                const dataObj = enricherObj.data || {};

                // Resolve the icon if available:
                const iconSrc = ENRICHER_ICON_MAP[enricherName] || null;

                return (
                  <Accordion
                    key={enricherKey}
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? alpha(theme.palette.grey[100], 0.01)
                            : theme.palette.grey[50],
                      }}
                    >
                      {/* 
                        Replace the plain Typography with a Chip that has 
                        an icon on the left side.
                      */}
                      <Chip
                        variant="outlined"
                        size="small"
                        label={enricherName}
                        sx={{
                          fontWeight: "bold",
                          borderRadius: "16px",
                          // want a bigger chip or different font, tweak these:
                          fontSize: "0.75rem",
                          height: "2.0rem",
                          padding: "2px",
                        }}
                        icon={
                          iconSrc ? (
                            <Box
                              component="img"
                              sx={{ width: 16, height: 16 }}
                              src={iconSrc}
                              alt={enricherName}
                            />
                          ) : undefined
                        }
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Divider sx={{ mb: 1 }} />
                      {/* Show the raw JSON in a pretty-printed format */}
                      <Typography
                        variant="caption"
                        component="pre"
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          fontFamily: "monospace",
                        }}
                      >
                        {JSON.stringify(dataObj, null, 2)}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })
            )}
          </Box>
        ))
      )}
    </Box>
  );
};

export default ArtifactsTab;
