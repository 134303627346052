import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

interface ApprovalStepProps {
  onNext: () => void;
  onBack: () => void;
  // optionally, you can pass in any state for provisioning
}

const ApprovalStep: React.FC<ApprovalStepProps> = ({ onNext, onBack }) => {
  const theme = useTheme();
  return (
    <Box textAlign="center" mt={4}>
      <Typography
        variant="h4"
        style={{
          fontFamily: theme.typography.h3.fontFamily,
          fontWeight: theme.typography.h3.fontWeight,
          fontSize: theme.typography.h3.fontSize,
          color: theme.palette.text.primary,
        }}
      >
        Provisioning{" "}
        <span style={{ color: theme.palette.primary.main }}>Your Instance</span>
      </Typography>
      {/* Icon */}
      <CloudUpload
        sx={{
          fontSize: 200,
          color: theme.palette.primary.dark,
          mb: 2,
          mt: 2,
        }}
      />
      <Typography
        variant="body1"
        mb={2}
        sx={{ maxWidth: 600, margin: "0 auto" }}
      >
        We’re reviewing your request. You should hear from us shortly.
      </Typography>
      {/* <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginTop={4}
      >
        <Button variant="outlined" onClick={onBack}>
          Back
        </Button>
        <Button variant="contained" onClick={onNext}>
          Next
        </Button>
      </Box> */}
    </Box>
  );
};

export default ApprovalStep;
