import React, { useEffect, useState } from "react";
import {
  Portal,
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Resizable } from "re-resizable";
import Draggable from "react-draggable";
import { CaseDetails } from "../types/caseDetailsTypes";

// Extra constant for our “unset” decision string
const DECISION_UNSET = "DECISION_UNSET";

interface IstepItem {
  id: string;
  decision: string;
  notes: string;
}

interface IstepState {
  id: string;
  decision: string;
  summary: string;
  notes: string;
  urlFeedbackEnabled: boolean;
  items: IstepItem[];
  urlSearch: string;
}

interface OverallState {
  decision: string;
  keyIndicators: string;
  summary: string;
  notes: string;
}

// For the analyses in caseDetail
interface CaseDetailAnalysis {
  name: string; // e.g. "URLs"
  summary?: string; // The default summary we might want to prepopulate
  [key: string]: any; // or more fields
}

// The shape of existing SME feedback
interface ExistingFeedback {
  decision?: string;
  keyIndicators?: string;
  summary?: string;
  notes?: string;
  analyses?: {
    id: string; // e.g. "URLs"
    decision?: string;
    summary?: string;
    notes?: string;
    items?: { id: string; decision?: string; notes?: string }[];
  }[];
}

interface SmeFeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  iStepOptions: string[];
  availableUrls?: string[];
  existingFeedback?: ExistingFeedback;
  version?: string;
  onSubmitSmeFeedback?: (payload: any) => void;
  caseDetail?: CaseDetails;
}

const SmeFeedbackDialog: React.FC<SmeFeedbackDialogProps> = ({
  open,
  onClose,
  iStepOptions,
  availableUrls,
  existingFeedback,
  version,
  onSubmitSmeFeedback,
  caseDetail,
}) => {
  // 1) Basic local states
  const [feedbackType, setFeedbackType] = useState<"" | "Case" | "iStep">("");

  // Overall feedback
  const [overall, setOverall] = useState<OverallState>({
    decision: DECISION_UNSET,
    keyIndicators: "",
    summary: "",
    notes: "",
  });

  /**
   * Backup for overall so we can restore after a prepopulate if needed
   */
  const [overallBackup, setOverallBackup] = useState<OverallState | null>(null);

  // We'll keep a dictionary of iStep states
  const [istepStates, setIstepStates] = useState<Record<string, IstepState>>(
    {}
  );
  /**
   * For each iStep, store a backup to allow restore
   */
  const [istepStatesBackup, setIstepStatesBackup] = useState<
    Record<string, IstepState | null>
  >({});

  const [selectedIStep, setSelectedIStep] = useState("");

  // For live resizing
  const [size, setSize] = useState({ width: 800, height: 600 });

  // On open, parse existing feedback
  useEffect(() => {
    if (!open) return;

    if (!existingFeedback) {
      resetAll();
      return;
    }

    // Overall from existing feedback
    const { decision, keyIndicators, summary, notes } = existingFeedback;
    const hasOverall = decision || keyIndicators || summary || notes;
    if (hasOverall) {
      setFeedbackType("Case");
      setOverall({
        decision: decision ?? DECISION_UNSET,
        keyIndicators: keyIndicators ?? "",
        summary: summary ?? "",
        notes: notes ?? "",
      });
    }

    // iSteps from existing feedback
    if (existingFeedback.analyses && existingFeedback.analyses.length > 0) {
      setFeedbackType("iStep");

      const out: Record<string, IstepState> = {};
      existingFeedback.analyses.forEach((a) => {
        const items =
          a.items?.map((itm) => ({
            id: itm.id,
            decision: itm.decision ?? DECISION_UNSET,
            notes: itm.notes ?? "",
          })) || [];

        out[a.id] = {
          id: a.id,
          decision: a.decision ?? DECISION_UNSET,
          summary: a.summary ?? "",
          notes: a.notes ?? "",
          // For “URLs,” we enable URL feedback if there are any items
          urlFeedbackEnabled: a.id.toLowerCase() === "urls" && items.length > 0,
          items,
          urlSearch: "",
        };
      });

      setIstepStates(out);
      // Optionally set the first one in the drop-down
      setSelectedIStep(existingFeedback.analyses[0].id);
    }
  }, [open, existingFeedback]);

  /** Helper to reset everything */
  function resetAll() {
    setFeedbackType("");
    setOverall({
      decision: DECISION_UNSET,
      keyIndicators: "",
      summary: "",
      notes: "",
    });
    setOverallBackup(null);
    setSelectedIStep("");
    setIstepStates({});
    setIstepStatesBackup({});
  }

  // 3) Helpers to manage iStep dictionary
  function getIstep(id: string): IstepState {
    return (
      istepStates[id] || {
        id,
        decision: DECISION_UNSET,
        summary: "",
        notes: "",
        urlFeedbackEnabled: false,
        items: [],
        urlSearch: "",
      }
    );
  }

  function setIstep(id: string, data: Partial<IstepState>) {
    setIstepStates((prev) => ({
      ...prev,
      [id]: { ...getIstep(id), ...data },
    }));
  }

  function addIstepItem(id: string) {
    const st = getIstep(id);
    setIstep(id, {
      items: [...st.items, { id: "", decision: DECISION_UNSET, notes: "" }],
    });
  }

  function removeIstepItem(id: string, index: number) {
    const st = getIstep(id);
    const items = st.items.filter((_, i) => i !== index);
    setIstep(id, { items });
  }

  function updateIstepItem(
    id: string,
    index: number,
    field: keyof IstepItem,
    value: string
  ) {
    const st = getIstep(id);
    const newItems = st.items.map((itm, i) =>
      i === index ? { ...itm, [field]: value } : itm
    );
    setIstep(id, { items: newItems });
  }

  // 4) Cancel
  function handleClose() {
    resetAll();
    onClose();
  }

  // 5) Submit => build final payload
  function handleSubmit() {
    const updatedAt = new Date().toISOString();

    // Build analyses array
    const analyses = Object.values(istepStates).map((st) => {
      const finalItems =
        st.id.toLowerCase() === "urls" && st.urlFeedbackEnabled ? st.items : [];

      return {
        id: st.id,
        decision: st.decision,
        summary: st.summary,
        notes: st.notes,
        items: finalItems,
      };
    });

    const payload = {
      updatedAt,
      version: version ?? "",
      feedback: {
        decision: overall.decision,
        keyIndicators: overall.keyIndicators,
        summary: overall.summary,
        notes: overall.notes,
        analyses,
      },
    };

    onSubmitSmeFeedback?.(payload);
    handleClose();
  }

  // If not open => render nothing
  if (!open) return null;

  const isOverall = feedbackType === "Case";
  const isIstep = feedbackType === "iStep";
  const curIstep = selectedIStep ? getIstep(selectedIStep) : null;
  const isUrls = !!curIstep && curIstep.id.toLowerCase() === "urls";

  /**
   * A small helper to figure out which URLs are “in use”
   * by this iStep’s items (besides the current item).
   */
  function usedUrlsForIstepItem(index: number): string[] {
    if (!curIstep) return [];
    return curIstep.items
      .filter((_, i) => i !== index)
      .map((it) => it.id)
      .filter(Boolean);
  }

  /**
   * Return the filtered list for the current row
   * (exclude duplicates and apply text search).
   */
  function getFilteredUrlsFor(index: number) {
    if (!curIstep) return [];
    const search = curIstep.urlSearch.trim().toLowerCase();
    const alreadyUsed = usedUrlsForIstepItem(index);
    let all = (availableUrls ?? []).filter((u) => !alreadyUsed.includes(u));
    if (search) {
      all = all.filter((u) => u.toLowerCase().includes(search));
    }
    return all;
  }

  /**
   * Don’t allow user to “Add Another URL” if we’ve used all
   * possible ones already.
   */
  function canAddMoreUrls(): boolean {
    if (!availableUrls || !curIstep) return true; // if no list is provided, allow
    const used = curIstep.items.map((i) => i.id).filter(Boolean);
    return used.length < availableUrls.length;
  }

  // ----------------------------------------------------------------
  // Prepopulate / Restore for Overall "Case" fields
  // ----------------------------------------------------------------
  function handlePrepopulateCaseDetail() {
    if (!caseDetail) return; // no data to prepopulate from

    // Backup current overall state so we can restore
    setOverallBackup({ ...overall });

    setOverall((prev) => ({
      ...prev,
      summary: caseDetail.summary || "",
      keyIndicators: Array.isArray(caseDetail.keyIndicators)
        ? caseDetail.keyIndicators.join(", ")
        : caseDetail.keyIndicators || "",
    }));
  }

  function handleRestoreCaseDetail() {
    if (!overallBackup) return;
    setOverall(overallBackup);
    setOverallBackup(null);
  }

  // ----------------------------------------------------------------
  // Prepopulate / Restore for iStep summary
  // ----------------------------------------------------------------
  function handlePrepopulateIstep(istepId: string) {
    if (!caseDetail || !caseDetail.details?.analyses) return;
    const analysis = caseDetail.details.analyses.find(
      (a) => a.name === istepId
    );
    if (!analysis) return;

    // Backup the existing iStep
    setIstepStatesBackup((prev) => ({
      ...prev,
      [istepId]: { ...getIstep(istepId) },
    }));

    // Overwrite with the caseDetail's summary (and/or notes)
    setIstep(istepId, {
      summary: analysis.summary || "",
      // TODO: If  want to override notes, we could do:
      // notes: analysis.notes || "",
    });
  }

  function handleRestoreIstep(istepId: string) {
    const backup = istepStatesBackup[istepId];
    if (!backup) return;
    setIstep(istepId, {
      summary: backup.summary,
      notes: backup.notes,
      decision: backup.decision,
      urlFeedbackEnabled: backup.urlFeedbackEnabled,
      items: backup.items,
      urlSearch: backup.urlSearch,
    });
    // Clear the backup
    setIstepStatesBackup((prev) => ({
      ...prev,
      [istepId]: null,
    }));
  }

  // Render the dialog
  return (
    <Portal>
      {/* Fullscreen box that doesn't block clicks behind it */}
      <Box
        sx={{
          position: "fixed",
          inset: 0,
          zIndex: 3000,
          pointerEvents: "none",
        }}
      >
        <Draggable
          handle=".sme-drag-handle"
          bounds="parent"
          cancel="input,textarea,select,.MuiSelect-root,.MuiSelect-icon"
        >
          <Resizable
            size={size}
            onResize={(e, direction, ref, d) => {
              const newWidth = parseInt(ref.style.width, 10);
              const newHeight = parseInt(ref.style.height, 10);
              setSize({ width: newWidth, height: newHeight });
            }}
            minWidth={500}
            minHeight={400}
            enable={{
              top: true,
              right: true,
              bottom: true,
              left: true,
              topRight: true,
              bottomRight: true,
              bottomLeft: true,
              topLeft: true,
            }}
            style={{
              position: "absolute",
              pointerEvents: "auto",
            }}
          >
            <Paper
              elevation={6}
              sx={{
                zIndex: 3100,
                width: size.width,
                height: size.height,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              {/* Draggable header */}
              <Box
                className="sme-drag-handle"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "primary.main",
                  color: "#fff",
                  p: 1,
                  cursor: "move",
                }}
              >
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                  SME Feedback
                </Typography>
                <IconButton onClick={handleClose} sx={{ color: "#fff" }}>
                  <CloseIcon />
                </IconButton>
              </Box>

              {/* Main content area */}
              <Box sx={{ p: 2, overflow: "auto", flexGrow: 1 }}>
                {/* A) Overall or iStep */}
                <FormControl fullWidth size="small" sx={{ mt: 1 }}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    value={feedbackType}
                    onChange={(e) =>
                      setFeedbackType(e.target.value as "Case" | "iStep")
                    }
                    MenuProps={{ sx: { zIndex: 9999 } }}
                  >
                    <MenuItem value="Case">Case</MenuItem>
                    <MenuItem value="iStep">iStep</MenuItem>
                  </Select>
                </FormControl>

                {/* B) if "iStep" => show iStep dropdown */}
                {isIstep && (
                  <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                    <InputLabel>iStep</InputLabel>
                    <Select
                      label="iStep"
                      value={selectedIStep}
                      onChange={(e) => setSelectedIStep(e.target.value)}
                      MenuProps={{ sx: { zIndex: 9999 } }}
                    >
                      {iStepOptions.map((step) => (
                        <MenuItem key={step} value={step}>
                          {step}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {/* C) Decision */}
                <FormControl fullWidth size="small" sx={{ mt: 2 }}>
                  <InputLabel>Decision</InputLabel>
                  <Select
                    label="Decision"
                    value={
                      isOverall
                        ? overall.decision
                        : curIstep?.decision || DECISION_UNSET
                    }
                    onChange={(e) => {
                      if (isOverall) {
                        setOverall((prev) => ({
                          ...prev,
                          decision: e.target.value,
                        }));
                      } else if (curIstep) {
                        setIstep(curIstep.id, { decision: e.target.value });
                      }
                    }}
                    MenuProps={{ sx: { zIndex: 9999 } }}
                  >
                    <MenuItem value={DECISION_UNSET}>
                      No Decision Change
                    </MenuItem>
                    <MenuItem value="Malicious">Malicious</MenuItem>
                    <MenuItem value="Benign">Benign</MenuItem>
                    <MenuItem value="Malicious_NoAction">
                      Malicious (No Action)
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* D) Overall fields if "Case" */}
                {isOverall && (
                  <>
                    {/* Prepopulate / Restore buttons */}
                    <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                      <Button
                        variant="outlined"
                        onClick={handlePrepopulateCaseDetail}
                        disabled={!caseDetail}
                      >
                        Prepopulate
                      </Button>
                      {overallBackup && (
                        <Button
                          variant="outlined"
                          onClick={handleRestoreCaseDetail}
                        >
                          Restore
                        </Button>
                      )}
                    </Box>

                    <TextField
                      label="Key Indicators"
                      fullWidth
                      multiline
                      rows={5}
                      sx={{ mt: 2 }}
                      value={overall.keyIndicators}
                      onChange={(e) =>
                        setOverall((p) => ({
                          ...p,
                          keyIndicators: e.target.value,
                        }))
                      }
                    />
                    <TextField
                      label="Summary"
                      fullWidth
                      multiline
                      rows={5}
                      sx={{ mt: 2 }}
                      value={overall.summary}
                      onChange={(e) =>
                        setOverall((p) => ({ ...p, summary: e.target.value }))
                      }
                    />
                    <TextField
                      label="Notes"
                      fullWidth
                      multiline
                      rows={5}
                      sx={{ mt: 2 }}
                      value={overall.notes}
                      onChange={(e) =>
                        setOverall((p) => ({ ...p, notes: e.target.value }))
                      }
                    />
                  </>
                )}

                {/* E) iStep fields if user picks "iStep" */}
                {isIstep && curIstep && (
                  <>
                    {/* Prepopulate / Restore buttons for the iStep summary */}
                    <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                      <Button
                        variant="outlined"
                        onClick={() => handlePrepopulateIstep(curIstep.id)}
                        disabled={!caseDetail}
                      >
                        Prepopulate
                      </Button>
                      {istepStatesBackup[curIstep.id] && (
                        <Button
                          variant="outlined"
                          onClick={() => handleRestoreIstep(curIstep.id)}
                        >
                          Restore
                        </Button>
                      )}
                    </Box>

                    <TextField
                      label="Summary"
                      fullWidth
                      multiline
                      rows={5}
                      sx={{ mt: 2 }}
                      value={curIstep.summary}
                      onChange={(e) => {
                        setIstep(curIstep.id, { summary: e.target.value });
                      }}
                    />
                    <TextField
                      label="Notes"
                      fullWidth
                      multiline
                      rows={5}
                      sx={{ mt: 2 }}
                      value={curIstep.notes}
                      onChange={(e) => {
                        setIstep(curIstep.id, { notes: e.target.value });
                      }}
                    />

                    {/* If it's "urls," show checkbox + items */}
                    {isUrls && (
                      <Box sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={curIstep.urlFeedbackEnabled}
                              onChange={(e) =>
                                setIstep(curIstep.id, {
                                  urlFeedbackEnabled: e.target.checked,
                                })
                              }
                            />
                          }
                          label="Also provide URL-level feedback?"
                        />
                      </Box>
                    )}

                    {isUrls && curIstep.urlFeedbackEnabled && (
                      <Box
                        sx={{
                          mt: 2,
                          p: 2,
                          border: "1px solid",
                          borderColor: "divider",
                          borderRadius: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          fontWeight="bold"
                          sx={{ mb: 1 }}
                        >
                          URL Feedback
                        </Typography>

                        <TextField
                          label="Filter URLs"
                          size="small"
                          fullWidth
                          sx={{ mb: 2 }}
                          value={curIstep.urlSearch}
                          onChange={(e) =>
                            setIstep(curIstep.id, { urlSearch: e.target.value })
                          }
                        />

                        {curIstep.items.map((itm, idx) => {
                          const filteredOptions = getFilteredUrlsFor(idx);
                          return (
                            <Box key={idx} sx={{ mb: 2 }}>
                              <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
                                <FormControl sx={{ flex: 2 }} size="small">
                                  <InputLabel>URL</InputLabel>
                                  <Select
                                    value={itm.id}
                                    label="URL"
                                    onChange={(e) =>
                                      updateIstepItem(
                                        curIstep.id,
                                        idx,
                                        "id",
                                        e.target.value
                                      )
                                    }
                                    MenuProps={{ sx: { zIndex: 9999 } }}
                                  >
                                    {filteredOptions.map((u) => (
                                      <MenuItem key={u} value={u}>
                                        {u}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                <FormControl sx={{ flex: 1 }} size="small">
                                  <InputLabel>Decision</InputLabel>
                                  <Select
                                    value={itm.decision || DECISION_UNSET}
                                    label="Decision"
                                    onChange={(e) =>
                                      updateIstepItem(
                                        curIstep.id,
                                        idx,
                                        "decision",
                                        e.target.value
                                      )
                                    }
                                    MenuProps={{ sx: { zIndex: 9999 } }}
                                  >
                                    <MenuItem value={DECISION_UNSET}>
                                      No Decision Change
                                    </MenuItem>
                                    <MenuItem value="Malicious">
                                      Malicious
                                    </MenuItem>
                                    <MenuItem value="Benign">Benign</MenuItem>
                                    <MenuItem value="Malicious_NoAction">
                                      Malicious (No Action)
                                    </MenuItem>
                                  </Select>
                                </FormControl>

                                <Button
                                  color="error"
                                  variant="outlined"
                                  onClick={() =>
                                    removeIstepItem(curIstep.id, idx)
                                  }
                                >
                                  Remove
                                </Button>
                              </Box>

                              <TextField
                                label="Notes"
                                multiline
                                rows={3}
                                fullWidth
                                value={itm.notes}
                                onChange={(e) =>
                                  updateIstepItem(
                                    curIstep.id,
                                    idx,
                                    "notes",
                                    e.target.value
                                  )
                                }
                              />
                            </Box>
                          );
                        })}

                        <Button
                          variant="outlined"
                          onClick={() => addIstepItem(curIstep.id)}
                          disabled={!canAddMoreUrls()}
                        >
                          + Add Another URL
                        </Button>
                        {!canAddMoreUrls() && (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mt: 1 }}
                          >
                            All URLs are already selected.
                          </Typography>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </Box>

              {/* Action buttons */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  borderTop: "1px solid",
                  borderColor: "divider",
                  p: 2,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="inherit"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={
                    !feedbackType ||
                    (isOverall
                      ? !overall.decision
                      : !!curIstep && !curIstep.decision === undefined)
                  }
                >
                  Submit
                </Button>
              </Box>
            </Paper>
          </Resizable>
        </Draggable>
      </Box>
    </Portal>
  );
};

export default SmeFeedbackDialog;
