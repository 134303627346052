import React from "react";
import {
  Box,
  Button,
  Paper,
  InputBase,
  IconButton,
  Badge,
  ClickAwayListener,
  Popper,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { Case } from "../types/types";
import FilterPopup from "./FilterPopup";

interface CustomQuickFilter {
  name: string;
  filters: any;
}

interface FiltersSectionProps {
  cases: Case[];
  setFilteredCases: React.Dispatch<React.SetStateAction<Case[]>>;
  activeFilter: string;
  setActiveFilter: React.Dispatch<React.SetStateAction<string>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  uniqueConclusions: string[];
  uniqueStatuses: string[];
  uniqueSeverities: string[];
  uniqueAttackSurfaces: string[];
  uniqueMitreStages: string[];
  uniqueCampaignIds: string[];
  applyFilters: (filters: any) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  advancedFilters: any; // The currently applied filters
  setAdvancedFilters: React.Dispatch<React.SetStateAction<any>>;
  customFilters: CustomQuickFilter[];
  onSaveCustomFilter: (builtFilters: any) => void;
  onApplyCustomFilter: (name: string) => void;
  onEditCustomFilter: (index: number) => void;
  onDeleteCustomFilter: (index: number) => void;
  setOrder: React.Dispatch<React.SetStateAction<"asc" | "desc" | undefined>>;
  setOrderBy: React.Dispatch<React.SetStateAction<keyof Case | undefined>>;
}

const countActiveFilters = (filters: any): number => {
  if (!filters) return 0;
  let count = 0;
  if (filters.conclusions && filters.conclusions.length)
    count += filters.conclusions.length;
  if (filters.statuses && filters.statuses.length)
    count += filters.statuses.length;
  if (filters.severities && filters.severities.length)
    count += filters.severities.length;
  if (filters.attackSurfaces && filters.attackSurfaces.length)
    count += filters.attackSurfaces.length;
  if (filters.mitreStages && filters.mitreStages.length)
    count += filters.mitreStages.length;
  if (filters.feedback && filters.feedback.length)
    count += filters.feedback.length;
  if (filters.identities && filters.identities.length)
    count += filters.identities.length;
  if (filters.createdAt && (filters.createdAt[0] || filters.createdAt[1]))
    count += 1;
  if (
    filters.alertTimestamp &&
    (filters.alertTimestamp[0] || filters.alertTimestamp[1])
  )
    count += 1;
  if (filters.smeFeedback && filters.smeFeedback.length)
    count += filters.smeFeedback.length;
  if (filters.campaignIds && filters.campaignIds.length)
    count += filters.campaignIds.length;

  return count;
};

const FiltersSection: React.FC<FiltersSectionProps> = ({
  cases,
  setFilteredCases,
  activeFilter,
  setActiveFilter,
  searchTerm,
  setSearchTerm,
  uniqueConclusions,
  uniqueStatuses,
  uniqueSeverities,
  uniqueAttackSurfaces,
  uniqueMitreStages,
  uniqueCampaignIds,
  applyFilters,
  setPage,
  advancedFilters,
  setAdvancedFilters,
  customFilters,
  onSaveCustomFilter,
  onApplyCustomFilter,
  onEditCustomFilter,
  onDeleteCustomFilter,
  setOrder,
  setOrderBy,
}) => {
  const theme = useTheme();
  const [isFilterPopupOpen, setIsFilterPopupOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const filterCount = countActiveFilters(advancedFilters);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsFilterPopupOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsFilterPopupOpen(false);
  };

  // Example counters
  const benignCount = cases.filter((c) => c.conclusion !== "Malicious").length;
  const escalatedCount = cases.filter(
    (c) => c.conclusion === "Malicious"
  ).length;

  const filterButtonStyles = (status: string) => ({
    color:
      activeFilter === status
        ? theme.palette.text.primary
        : theme.palette.grey[600],
    fontWeight: activeFilter === status ? "bold" : "normal",
    borderBottom:
      activeFilter === status ? `2px solid ${theme.palette.info.main}` : "none",
    textTransform: "none",
    paddingBottom: theme.spacing(1),
    borderRadius: 0,
    minWidth: 0,
    lineHeight: 1.2,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      outline: "none",
    },
  });

  // Quick filters
  const filterByConclusion = (conclusion: string) => {
    setAdvancedFilters(null);
    if (["all", "Escalated", "Benign"].includes(conclusion)) {
      setOrder("desc");
      setOrderBy("createdAt");
    } else {
      setOrder(undefined);
      setOrderBy(undefined);
    }

    let filteredData: Case[] = [];
    if (conclusion === "all") {
      filteredData = cases;
    } else if (conclusion === "Benign") {
      filteredData = cases.filter(
        (caseItem) =>
          caseItem.conclusion === "Benign" ||
          caseItem.conclusion === "Malicious_Benign"
      );
    } else {
      filteredData = cases.filter(
        (caseItem) => caseItem.conclusion === "Malicious"
      );
    }

    setFilteredCases(filteredData);
    setActiveFilter(conclusion);
    setPage(1);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("xl")]: {
          gap: theme.spacing(1.5),
          alignItems: "flex-start",
        },
      }}
    >
      {/* Quick Filters Row */}
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(2) }}
      >
        <Button
          onClick={() => filterByConclusion("all")}
          sx={filterButtonStyles("all")}
        >
          All
        </Button>
        <Button
          onClick={() => filterByConclusion("Escalated")}
          sx={filterButtonStyles("Escalated")}
        >
          Escalated
          <Badge badgeContent={escalatedCount} color="primary" />
        </Button>
        <Button
          onClick={() => filterByConclusion("Benign")}
          sx={filterButtonStyles("Benign")}
        >
          Denoised
          <Badge badgeContent={benignCount} color="primary" />
        </Button>

        {customFilters.length > 0 && (
          <Divider orientation="vertical" flexItem sx={{ ml: 4, mr: 2 }} />
        )}

        {/* Custom Filters */}
        {customFilters.map((cf, idx) => (
          <Box
            key={cf.name}
            sx={{
              position: "relative",
              display: "inline-block",
              "&:hover .filter-icons": {
                opacity: 1,
                pointerEvents: "auto",
              },
            }}
          >
            <Button
              onClick={() => onApplyCustomFilter(cf.name)}
              sx={{
                ...filterButtonStyles(cf.name),
                color:
                  activeFilter === cf.name
                    ? theme.palette.text.primary
                    : theme.palette.grey[600],
                borderBottom:
                  activeFilter === cf.name
                    ? `2px solid ${theme.palette.info.main}`
                    : "none",
              }}
            >
              {cf.name}
            </Button>

            {/* Hover Icons */}
            <Box
              className="filter-icons"
              sx={{
                position: "absolute",
                bottom: "100%", // sit above the button
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                gap: 1,
                opacity: 0,
                pointerEvents: "none",
                padding: theme.spacing(0.25),
                borderRadius: 1,
                transition: "opacity 0.15s ease-in-out",
                // no background or boxShadow for "no weird background"
              }}
            >
              <IconButton
                size="small"
                onClick={() => onEditCustomFilter(idx)}
                sx={{ color: theme.palette.grey[600] }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => onDeleteCustomFilter(idx)}
                sx={{ color: theme.palette.grey[600] }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Box>

      {/* Search + Filter Icon */}
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(2) }}
      >
        <Paper
          component="form"
          onSubmit={(e) => e.preventDefault()}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
            boxShadow: "none",
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: "16px",
          }}
        >
          <SearchIcon
            sx={{ color: theme.palette.grey[500], mr: 1, ml: 1, fontSize: 18 }}
          />
          <InputBase
            sx={{ flex: 1, fontSize: "0.875rem" }}
            placeholder="Search Case..."
            inputProps={{ "aria-label": "search case" }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </Paper>

        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <IconButton aria-label="filter" onClick={handleFilterClick}>
              <Badge badgeContent={filterCount} color="primary">
                <FilterListIcon />
              </Badge>
            </IconButton>
            <Popper
              open={isFilterPopupOpen}
              anchorEl={anchorEl}
              placement="bottom-start"
              sx={{ zIndex: theme.zIndex.modal }}
            >
              <FilterPopup
                asDialog={false}
                open={true}
                onClose={handleClose}
                currentFilters={advancedFilters}
                applyFilters={applyFilters}
                onSaveCustomFilter={onSaveCustomFilter}
                onUpdateCustomFilter={undefined}
                editingIndex={null}
                conclusions={uniqueConclusions}
                statuses={uniqueStatuses}
                severities={uniqueSeverities}
                attackSurfaces={uniqueAttackSurfaces}
                mitreStages={uniqueMitreStages}
                campaignIds={uniqueCampaignIds}
              />
            </Popper>
          </div>
        </ClickAwayListener>
      </Box>
    </Box>
  );
};

export default FiltersSection;
