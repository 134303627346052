import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { fetchCases } from "../api/casesApi";
import { Case } from "../features/cases/types/types";
import dayjs from "dayjs";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, useTheme } from "@mui/system";
import { Button, Divider, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setCaseListAndIndex } from "../features/case-navigation/redux/caseNavigationSlice";
import DashboardHeaderSkeleton from "../features/dashboard/components/DashboardHeaderSkeleton";
import DashboardHeader from "../features/dashboard/components/DashboardHeader";
import EmbedCircleSkeleton from "../features/dashboard/components/EmbedCircleSkeleton";
import { get_ui_global_settings, get_ui_shapes } from "./ui_settings";

// Time filter options
type TimeFilter = "Last 24 Hrs" | "Last Week" | "Last Month" | "All";
// Hotspot filter options
type HotspotFilter = "Reviewed" | "Unreviewed" | "Show All";

// Helper to retrieve initial filter states from localStorage
function getInitialFilters() {
  const savedState = JSON.parse(
    localStorage.getItem("DashboardPageState") || "{}"
  );
  return {
    timeFilter: savedState.timeFilter || "Last 24 Hrs",
    hotspotFilter: savedState.hotspotFilter || "Show All",
  };
}

// Helper function to filter by time
function filterCasesByTime(cases: Case[], timeFilter: TimeFilter): Case[] {
  if (timeFilter === "All") return cases;

  const now = dayjs();
  let days = 1;
  if (timeFilter === "Last Week") days = 7;
  else if (timeFilter === "Last Month") days = 30;

  return cases.filter((c) => {
    const created = dayjs(c.createdAt);
    return now.diff(created, "day") <= days;
  });
}

// Helper to get hotspot size from conclusion
function getHotspotSize(conclusion: string): "small" | "medium" | "large" {
  if (conclusion === "Malicious") return "large";
  if (conclusion === "Malicious_Benign") return "medium";
  return "small";
}

// Helper to filter by hotspot (reviewed/unreviewed)
function filterCasesByHotspot(
  cases: Case[],
  hotspotFilter: HotspotFilter
): Case[] {
  if (hotspotFilter === "Show All") return cases;
  if (hotspotFilter === "Reviewed")
    return cases.filter((c) => c.feedback !== null);
  if (hotspotFilter === "Unreviewed")
    return cases.filter((c) => c.feedback === null);
  return cases;
}

// Determine which segment the case belongs to
function getSegment(attackSurface?: string) {
  if (!attackSurface) return null;
  const s = attackSurface.toLowerCase();
  if (s === "email") return "Email";
  if (s === "cloud") return "Cloud";
  if (s === "endpoint") return "Endpoint";
  return null;
}

// Generate time series data for a given segment's cases
function generateTimeSeriesData(cases: Case[]): { [date: string]: number } {
  const dateCounts: { [date: string]: number } = {};
  cases.forEach((item) => {
    const date = new Date(item.createdAt);
    const dateString = date.toISOString().split("T")[0]; // YYYY-MM-DD
    if (dateCounts[dateString]) {
      dateCounts[dateString]++;
    } else {
      dateCounts[dateString] = 1;
    }
  });
  return dateCounts;
}

// Convert dateCounts to an array of {x,y} points based on days since first date
function generateGraphPoints(dateCounts: { [date: string]: number }) {
  const dates = Object.keys(dateCounts).sort();
  if (dates.length === 0) return [];

  const startDate = new Date(dates[0]);
  return dates.map((date) => {
    const currentDate = new Date(date);
    const x = Math.floor(
      (currentDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const y = dateCounts[date];
    return { x, y, date };
  });
}

// -------------------- HOTSPOT POPUP ---------------------
interface HotspotPopupProps {
  hotspotCases: string[] | null;
  selectedCaseId: string | null;
  onClose: () => void;
  onInvestigate: (caseId: string) => void;
  onSelectCaseId: (caseId: string) => void;
  onBackToList: () => void;
  allCases: Case[];
}

// Styles for popup container and header
const popupContainerStyle = (theme: any) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.background.paper,
  // >>> WIDEN THE POPUP <<<
  width: "400px",
  minHeight: "360px",
  minWidth: "400px",
  boxShadow: "2px 0 10px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  zIndex: 1000,
});

const popupHeaderStyle = (theme: any) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.grey[300]}`,
  p: 1,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
});

const iconButtonStyle = (theme: any) => ({
  minWidth: "auto",
  fontSize: "1.3rem",
  lineHeight: 1,
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.text.primary,
  },
});

// Popup component
const HotspotPopup: React.FC<HotspotPopupProps> = ({
  hotspotCases,
  selectedCaseId,
  onClose,
  onInvestigate,
  onSelectCaseId,
  onBackToList,
  allCases,
}) => {
  const theme = useTheme();

  if (!hotspotCases) return null;

  // -------------------- MULTIPLE CASES, NO SELECTED CASE --------------------
  if (!selectedCaseId && hotspotCases.length > 1) {
    return (
      <Box sx={popupContainerStyle(theme)}>
        <Box sx={popupHeaderStyle(theme)}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Select a Case
          </Typography>
          <Button onClick={onClose} sx={iconButtonStyle(theme)}>
            ×
          </Button>
        </Box>
        <Box
          sx={{
            maxHeight: "350px",
            overflowY: "auto",
            flexGrow: 1,
            p: 1,
            pt: 0,
          }}
        >
          {hotspotCases.map((id) => {
            const c = allCases.find((cc) => cc.caseId === id);
            if (!c) return null;

            // 1) If "Malicious_Benign", display "Malicious-NoAction"
            const displayConclusion =
              c.conclusion === "Malicious_Benign"
                ? "Malicious-NoAction"
                : c.conclusion;

            const isReviewed = c.feedback !== null;
            const reviewedStatus = isReviewed ? "(Reviewed)" : "(Unreviewed)";
            const displayText = `${displayConclusion} ${reviewedStatus}`;

            // 2) If it's Email, prepend "Subject: "
            const fullTitle =
              c.attackSurface === "Email" ? `Subject: ${c.title}` : c.title;

            // 3) Truncate the title (for example, 25 chars)
            const maxLength = 30;
            const truncatedTitle =
              fullTitle.length > maxLength
                ? fullTitle.substring(0, maxLength) + "..."
                : fullTitle;

            return (
              <Box
                key={id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                  p: 1,
                  borderRadius: "4px",
                }}
                onClick={() => onSelectCaseId(id)}
              >
                {/* 4) Add a tooltip with the full title */}
                <Tooltip title={fullTitle}>
                  <Typography
                    variant="body2"
                    sx={{ maxWidth: "50%", overflow: "hidden" }}
                  >
                    {truncatedTitle}
                  </Typography>
                </Tooltip>

                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {displayText}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  }

  // -------------------- SINGLE CASE OR SELECTED CASE SCENARIO --------------------
  const caseIdToShow =
    selectedCaseId || (hotspotCases.length === 1 ? hotspotCases[0] : null);
  if (!caseIdToShow) return null;

  const caseData = allCases.find((c) => c.caseId === caseIdToShow);
  if (!caseData) return null;

  const multipleCases = hotspotCases.length > 1;

  return (
    <Box sx={popupContainerStyle(theme)}>
      <Box sx={popupHeaderStyle(theme)}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {multipleCases && selectedCaseId && (
            <Button
              onClick={onBackToList}
              sx={{
                ...iconButtonStyle(theme),
                mr: 1,
              }}
            >
              {"<"}
            </Button>
          )}
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Case Details
          </Typography>
        </Box>
        <Button onClick={onClose} sx={iconButtonStyle(theme)}>
          ×
        </Button>
      </Box>

      <Box sx={{ padding: theme.spacing(2), flexGrow: 1 }}>
        <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 2 }}>
          {caseData.title || "Case Title"}
        </Typography>

        {[
          { label: "Created At", value: caseData.createdAt || "N/A" },
          {
            label: "Conclusion",
            value:
              caseData.conclusion === "Malicious_Benign"
                ? "Malicious-NoAction"
                : caseData.conclusion || "N/A",
          },
          { label: "Feedback", value: caseData.feedback || "N/A" },
          { label: "Severity", value: caseData.severity || "N/A" },
        ].map((item, index) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: theme.spacing(1, 0),
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: theme.palette.grey[600] }}
              >
                {item.label}:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, textAlign: "right" }}
              >
                {item.value}
              </Typography>
            </Box>
            {index < 3 && <Divider />}
          </React.Fragment>
        ))}
      </Box>

      <Box sx={{ padding: theme.spacing(2) }}>
        <Button
          variant="contained"
          onClick={() => onInvestigate(caseIdToShow)}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.info.main,
            "&:hover": {
              backgroundColor: theme.palette.info.dark,
            },
          }}
        >
          Investigate
        </Button>
      </Box>
    </Box>
  );
};
// ------------------ END HOTSPOT POPUP -------------------

const DashboardPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const cases = useAppSelector((state) => state.cases.cases);
  const loading = useAppSelector((state) => state.cases.loading);

  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dashboardRef = useRef<any>(null); // 'any' because accessing window.Dashboard
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  // Retrieve initial filters from localStorage
  const initialFilters = getInitialFilters();
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(
    initialFilters.timeFilter as TimeFilter
  );
  const [hotspotFilter, setHotspotFilter] = useState<HotspotFilter>(
    initialFilters.hotspotFilter as HotspotFilter
  );

  // Save filters to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem(
      "DashboardPageState",
      JSON.stringify({ timeFilter, hotspotFilter })
    );
  }, [timeFilter, hotspotFilter]);

  // State for popup
  const [hotspotCases, setHotspotCases] = useState<string[] | null>(null);
  const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null);

  // State for metrics
  const [metrics, setMetrics] = useState({
    timeSaved: "0 hours",
    yearlyDollarsSaved: "$0",
    systemHealth: "16/16", // Placeholder
    evidenceCollected: 0,
    denoisedAlerts: 0,
    unreviewedMaliciousAlerts: 0,
  });

  const navigate = useNavigate();

  // Investigate handler
  const handleInvestigate = (caseId: string) => {
    const filteredCases = cases.filter((c) => true); // customize if needed
    const index = filteredCases.findIndex((c) => c.caseId === caseId);
    dispatch(
      setCaseListAndIndex({ source: "dashboard", cases: filteredCases, index })
    );
    navigate(`/cases/${caseId}`, { state: { from: "/dashboard" } });
  };

  // Adjust container size on layout
  useLayoutEffect(() => {
    if (containerRef.current && loading) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setContainerSize({ width, height });
    }
  }, [loading]);

  // Fetch cases on mount if not present
  useEffect(() => {
    if (cases.length === 0) {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          await dispatch(fetchCases(token)).unwrap();
        } catch (error: any) {
          console.error("Error fetching access token or cases:", error);
        }
      };
      fetchData();
    }
  }, [dispatch, getAccessTokenSilently, cases.length]);

  // Filter & prepare data for the dashboard whenever cases/filters change
  useEffect(() => {
    if (cases.length === 0) return;

    // 1) Filter by time
    const timeFiltered = filterCasesByTime(cases, timeFilter);
    // 2) Filter by hotspot
    const finalFiltered = filterCasesByHotspot(timeFiltered, hotspotFilter);

    // ----- Metrics: e.g., last 30 days, current year, etc. -----
    const now = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(now.getDate() - 30);
    const last30DayCases = cases.filter((c) => {
      const createdAtDate = new Date(c.createdAt);
      return createdAtDate >= thirtyDaysAgo;
    });

    const currentYear = now.getFullYear();
    const currentYearCases = cases.filter((c) => {
      const createdAtYear = new Date(c.createdAt).getFullYear();
      return createdAtYear === currentYear;
    });

    // TIME SAVED (assuming 18 mins per case, round to hours)
    const timeSavedInMinutes = last30DayCases.length * 18;
    const timeSavedInHours = Math.round(timeSavedInMinutes / 60);
    const timeSaved = `${timeSavedInHours} hours`;

    // YEARLY DOLLARS SAVED (example calculation)
    const yearlyDollarsSaved = `$${(
      timeSavedInHours *
      12 *
      (125000 / 2080)
    ).toLocaleString(undefined, { maximumFractionDigits: 0 })}`;

    // Denoised vs. Malicious
    const benignCount30 = finalFiltered.filter(
      (c) => c.conclusion !== "Malicious"
    ).length;
    const maliciousCount30 = finalFiltered.filter(
      (c) => c.conclusion === "Malicious"
    ).length;
    let denoisedAlerts = 0;
    if (benignCount30 + maliciousCount30 > 0) {
      denoisedAlerts = Math.ceil(
        (benignCount30 / (benignCount30 + maliciousCount30)) * 100
      );
    }

    // Unreviewed Malicious Alerts
    const unreviewedMaliciousAlerts = finalFiltered.filter(
      (c) => c.conclusion === "Malicious" && c.feedback == null
    ).length;

    // Evidence Collected = total finalFiltered
    const evidenceCollected = finalFiltered.length;

    setMetrics({
      timeSaved,
      yearlyDollarsSaved,
      systemHealth: "16/16", // Placeholder
      evidenceCollected,
      denoisedAlerts,
      unreviewedMaliciousAlerts,
    });

    // Group finalFiltered by segment
    const segmentData: { [key: string]: Case[] } = {
      Email: [],
      Cloud: [],
      Endpoint: [],
    };
    finalFiltered.forEach((c) => {
      const segmentName = getSegment(c.attackSurface);
      if (segmentName) segmentData[segmentName].push(c);
    });

    // We’ll assume a fixed num_per_band or fetch from `dashboardRef`
    const num_per_band = 10;

    // Build the segments object
    const segments: {
      [key: string]: {
        points: number[][];
        hotspots: any[];
      };
    } = {
      Email: { points: [], hotspots: [] },
      Cloud: { points: [], hotspots: [] },
      Endpoint: { points: [], hotspots: [] },
    };

    // Helper to chunk arrays
    function chunkIntoGroups(arr: Case[], maxGroups: number): Case[][] {
      if (arr.length === 0) return [];
      const groupSize = Math.ceil(arr.length / maxGroups);
      const groups: Case[][] = [];
      let start = 0;
      while (start < arr.length) {
        groups.push(arr.slice(start, start + groupSize));
        start += groupSize;
      }
      return groups;
    }

    // Build up each segment
    Object.keys(segmentData).forEach((seg) => {
      const segCases = segmentData[seg];

      const largeCases = segCases.filter(
        (c) => getHotspotSize(c.conclusion) === "large"
      );
      const mediumCases = segCases.filter(
        (c) => getHotspotSize(c.conclusion) === "medium"
      );
      const smallCases = segCases.filter(
        (c) => getHotspotSize(c.conclusion) === "small"
      );

      // Create hotspots from groups
      function createHotspotsFromGroups(
        groups: Case[],
        size: "large" | "medium" | "small"
      ) {
        const chunked = chunkIntoGroups(groups, num_per_band);
        return chunked.map((group) => {
          const allReviewed = group.every((g) => g.feedback !== null);
          const allUnreviewed = group.every((g) => g.feedback === null);
          const isMalicious = group.some((g) => g.conclusion === "Malicious");

          let shape_name: string;
          if (allReviewed) {
            shape_name = isMalicious ? "Malicious Reviewed" : "Reviewed";
          } else if (allUnreviewed) {
            shape_name = isMalicious ? "Malicious Unreviewed" : "Unreviewed";
          } else {
            shape_name = isMalicious ? "Malicious Mixed" : "Mixed";
          }

          return {
            shape_name,
            callback_fnc: "caseClick",
            params: group.map((g) => g.caseId),
            size,
            createdAt: group[0].createdAt,
          };
        });
      }

      const largeHotspots = createHotspotsFromGroups(largeCases, "large");
      const mediumHotspots = createHotspotsFromGroups(mediumCases, "medium");
      const smallHotspots = createHotspotsFromGroups(smallCases, "small");

      const allHotspots = [
        ...largeHotspots,
        ...mediumHotspots,
        ...smallHotspots,
      ];

      // Generate time series
      const dateCounts = generateTimeSeriesData(segCases);
      const graphPoints = generateGraphPoints(dateCounts);

      if (graphPoints.length <= 2) {
        segments[seg] = {
          points: [],
          hotspots: allHotspots,
        };
      } else {
        segments[seg] = {
          points: graphPoints.map((p) => [p.x, p.y]),
          hotspots: allHotspots,
        };
      }
    });

    // Escalated vs Denoised
    const escalated = finalFiltered.filter(
      (c) => c.conclusion === "Malicious"
    ).length;
    const denoised = finalFiltered.filter(
      (c) => c.conclusion !== "Malicious"
    ).length;

    // Prepare radio button config
    const timeButtons = [
      { label: "Last 24 Hrs" },
      { label: "Last Week" },
      { label: "Last Month" },
      { label: "All" },
    ];
    const selectedTimeId = timeButtons.findIndex((b) => b.label === timeFilter);

    const hotspotButtons = [
      { label: "Reviewed" },
      { label: "Unreviewed" },
      { label: "Show All" },
    ];
    const selectedHotspotId = hotspotButtons.findIndex(
      (b) => b.label === hotspotFilter
    );

    // Build update data
    const updateData = {
      CN_1: escalated,
      CN_2: denoised,
      Segments: segments,
      Radio_Buttons: {
        time_filters: {
          center_angle: 30,
          angle_length: 40,
          angle_margin: 5,
          label_size: 0.55,
          label_offset: 0.35,
          callback_fnc: "filter_click",
          selected_id: selectedTimeId >= 0 ? selectedTimeId : 0,
          selected_label_color: "#303f9f",
          buttons: timeButtons.map((b) => ({
            selected_shape_name: "Selected Radio",
            unselected_shape_name: "Unselected Radio",
            label: b.label,
          })),
        },
        hotspot_filters: {
          center_angle: 330,
          angle_length: 22,
          angle_margin: 2,
          label_size: 0.65,
          label_offset: 0.25,
          label_y_offset: -0.25,
          callback_fnc: "hotspot_filter_click",
          selected_id: selectedHotspotId >= 0 ? selectedHotspotId : 2,
          buttons: hotspotButtons.map((b) => ({
            selected_shape_name:
              b.label === "Reviewed"
                ? "Reviewed Selected"
                : b.label === "Unreviewed"
                ? "Unreviewed Selected"
                : "Show All Selected",
            unselected_shape_name:
              b.label === "Reviewed"
                ? "Reviewed"
                : b.label === "Unreviewed"
                ? "Unreviewed"
                : "Show All",
            label: b.label,
          })),
        },
      },
    };

    // Now get theme-based shapes and globals
    const shapes = get_ui_shapes(theme);
    const globals = get_ui_global_settings(theme);

    // Override the window globals used by ui_classes.js
    window.ui_shapes = shapes;
    window.ui_global_settings = globals;

    const globalSettingsStr = JSON.stringify(globals);

    // Initialize or update the dashboard
    if (dashboardRef.current) {
      // Update globals first
      dashboardRef.current.update_globals(globalSettingsStr, false);
      // Now update data
      dashboardRef.current.update(JSON.stringify(updateData), false);
    } else {
      if (canvasRef.current && window.Dashboard) {
        const canvas = canvasRef.current;
        dashboardRef.current = new window.Dashboard(canvas);
        // Update globals first
        dashboardRef.current.update_globals(globalSettingsStr, true);
        // Now update the main data
        dashboardRef.current.update(JSON.stringify(updateData), true);
      }
    }
  }, [
    cases,
    timeFilter,
    hotspotFilter,
    theme,
    dispatch,
    getAccessTokenSilently,
  ]);

  // Setup callbacks
  useEffect(() => {
    window.filter_click = (label: string) => {
      setTimeFilter(label as TimeFilter);
    };

    window.hotspot_filter_click = (label: string) => {
      setHotspotFilter(label as HotspotFilter);
    };

    window.caseClick = (x: number, y: number, ...caseIds: string[]) => {
      console.log("Hotspot clicked at:", x, y, "with case IDs:", caseIds);
      setHotspotCases(caseIds);
      if (caseIds.length === 1) {
        setSelectedCaseId(caseIds[0]);
      } else {
        setSelectedCaseId(null);
      }
    };
  }, []);

  // Handle resizing
  useEffect(() => {
    const handleResize = () => {
      if (!dashboardRef.current || !containerRef.current) return;
      const containerWidth = containerRef.current.offsetWidth;
      dashboardRef.current.resize(Math.floor(containerWidth));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [cases]);

  const closePopup = () => {
    setHotspotCases(null);
    setSelectedCaseId(null);
  };

  const handleSelectCaseId = (id: string) => {
    setSelectedCaseId(id);
  };

  const handleBackToList = () => {
    setSelectedCaseId(null);
  };

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.default,
        height: "100vh",
      }}
    >
      {loading ? (
        <>
          <DashboardHeaderSkeleton />
          <EmbedCircleSkeleton
            width={containerSize.width > 0 ? containerSize.width : 500}
            height={containerSize.height > 0 ? containerSize.height : 500}
          />
        </>
      ) : (
        <>
          <DashboardHeader metrics={metrics} />
          <div
            ref={containerRef}
            style={{
              width: "100%",
              maxWidth: "1600px",
              margin: "auto auto",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <canvas
              id="canvas"
              ref={canvasRef}
              style={{
                display: "block",
                width: "100%",
                height: "auto",
              }}
            />
          </div>
          {hotspotCases && (
            <>
              {/* Overlay that prevents clicking on dashboard when popup is active */}
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "rgba(0,0,0,0)", // fully transparent
                  zIndex: 999,
                  pointerEvents: "auto",
                }}
                onClick={closePopup}
              />
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1000,
                  pointerEvents: "auto",
                }}
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
              >
                <HotspotPopup
                  hotspotCases={hotspotCases}
                  selectedCaseId={selectedCaseId}
                  onClose={closePopup}
                  onInvestigate={handleInvestigate}
                  onSelectCaseId={handleSelectCaseId}
                  onBackToList={handleBackToList}
                  allCases={cases}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DashboardPage;
