import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, CircularProgress, Typography } from "@mui/material";
import { jwtDecode } from "jwt-decode";

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [checking, setChecking] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasClientId, setHasClientId] = useState(false);
  const [domainRecognized, setDomainRecognized] = useState(false);
  const [shouldRedirectToStep3, setShouldRedirectToStep3] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Clear the redirection flag if we're not on the onboarding page anymore
    if (!location.pathname.includes("/onboard")) {
      sessionStorage.removeItem("redirectedToOnboarding");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      // If Auth0 is still loading or user isn't authenticated,
      // we skip until isLoading = false AND isAuthenticated = true.
      return;
    }

    const doCheck = async () => {
      try {
        const token = await getAccessTokenSilently();
        // Decode the token
        const decoded: any = jwtDecode(token);

        // Check if user has both clientId and is in new_user state
        if (
          decoded["https://app.embedsecurity.com/client_id"] &&
          decoded["https://app.embedsecurity.com/application_state"] ===
            "new_user"
        ) {
          setShouldRedirectToStep3(true);
          setChecking(false);
          return;
        }

        // Check if user has clientId (but not in new_user state)
        if (decoded["https://app.embedsecurity.com/client_id"]) {
          // user has clientId => skip domain check
          setHasClientId(true);
          setChecking(false);
          return;
        }

        // If NO clientId => do domain check
        const resp = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1alpha/users/verify`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (!resp.ok) {
          setError(`Domain check failed: HTTP ${resp.status}`);
        } else {
          const data = await resp.json();
          setDomainRecognized(data.user_has_known_domain);
        }
      } catch (err: any) {
        setError(err.message || "Error verifying domain");
      } finally {
        setChecking(false);
      }
    };

    doCheck();
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  // 2) If Auth0 is still loading => spinner
  if (isLoading) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 10 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Checking login status...
        </Typography>
      </Container>
    );
  }

  // If not authenticated => /login
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // 3) If we're still "checking" token or domain => spinner
  if (checking) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 10 }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Checking domain / client ID...
        </Typography>
      </Container>
    );
  }

  // 4) If there's an error => show an error UI
  if (error) {
    return (
      <Container sx={{ mt: 10, textAlign: "center" }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
        <Typography>Please try refreshing or contact support.</Typography>
      </Container>
    );
  }

  // 5) Decide final route logic
  //  - if user has clientId => proceed
  //  - else if domain recognized => wait-for-admin
  //  - else => /onboard
  // Check for special case: has clientId AND is new_user - go to step 3
  // Check for special case: has clientId AND is new_user - go to step 3
  if (shouldRedirectToStep3 && location.pathname !== "/onboard") {
    return <Navigate to="/onboard" state={{ initialStep: 3 }} replace />;
  }

  // No clientId but domain recognized - redirect to onboard flow
  if (!hasClientId && !domainRecognized) {
    return <Navigate to="/onboard" replace />;
  }

  // No clientId and domain not recognized - redirect to request access flow
  if (!hasClientId && domainRecognized) {
    return <Navigate to="/wait-for-admin" replace />;
  }

  // All checks passed, render the protected content
  return <>{children}</>;
};

export default PrivateRoute;
