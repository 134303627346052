import React, { useEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";

interface AnimatedCounterProps {
  value: number;
  prefix?: string;
  suffix?: string;
  duration?: number;
  formatFunction?: (val: number) => string;
}

const AnimatedCounter: React.FC<AnimatedCounterProps> = ({
  value,
  prefix = "",
  suffix = "",
  duration = 200,
  formatFunction = (val) => Math.round(val).toString(),
}) => {
  const [prevValue, setPrevValue] = useState(value);

  const { number } = useSpring({
    from: { number: prevValue },
    to: { number: value },
    config: { duration },
    onRest: () => setPrevValue(value),
  });

  useEffect(() => {
    setPrevValue(value);
  }, []);

  return (
    <div style={{ display: "inline" }}>
      {prefix}
      <animated.span>{number.to((val) => formatFunction(val))}</animated.span>
      {suffix}
    </div>
  );
};

export default AnimatedCounter;
