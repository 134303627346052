import React from "react";
import { Box, Typography, Pagination } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface PaginationControlsProps {
  // Rename this for clarity since it really is "group count" now:
  totalCases: number;
  page: number;
  rowsPerPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  totalCases,
  page,
  rowsPerPage,
  setPage,
}) => {
  const theme = useTheme();

  // Figure out how many pages we really have
  const totalPages = Math.max(1, Math.ceil(totalCases / rowsPerPage));

  // Clamp page so it never exceeds totalPages (and never goes < 1).
  const safePage = Math.min(page, totalPages);

  // Optionally, also sync it back if the parent’s `page` is out of range.
  if (safePage !== page) {
    setPage(safePage);
  }

  // Start + end of current group range
  const start = (safePage - 1) * rowsPerPage + 1;
  const end = Math.min(safePage * rowsPerPage, totalCases);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: theme.spacing(2),
      }}
    >
      <Typography variant="body2" color={theme.palette.text.secondary}>
        Showing {start}-{end} of {totalCases} results
      </Typography>

      <Pagination
        count={totalPages}
        page={safePage}
        onChange={(event, value) => setPage(value)}
        sx={{
          "& .MuiPaginationItem-root": {
            "&.Mui-selected": {
              backgroundColor: theme.palette.info.main,
              color: theme.palette.common.white,
            },
            "&.Mui-selected:hover": {
              backgroundColor: theme.palette.info.dark,
            },
          },
        }}
      />
    </Box>
  );
};

export default PaginationControls;
