import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

interface CompletionStepProps {
  onFinish: () => void;
  loading?: boolean;
  error?: string | null;
}

const CompletionStep: React.FC<CompletionStepProps> = ({
  onFinish,
  loading = false,
  error = null,
}) => {
  const theme = useTheme();
  const { width, height } = useWindowSize();
  const [showConfetti, setShowConfetti] = useState(true);

  // Stop confetti after 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box textAlign="center" mt={4}>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={300}
          recycle={false}
          colors={[
            theme.palette.primary.main,
            theme.palette.primary.light,
            theme.palette.secondary.main,
            theme.palette.success.main,
            "#FFC107", // amber
            "#FF9800", // orange
          ]}
        />
      )}
      <Typography
        variant="h4"
        style={{
          fontFamily: theme.typography.h3.fontFamily,
          fontWeight: theme.typography.h3.fontWeight,
          fontSize: theme.typography.h3.fontSize,
          color: theme.palette.text.primary,
        }}
      >
        <span style={{ color: theme.palette.primary.main }}>
          You're All Set!
        </span>
      </Typography>

      <Typography
        variant="body1"
        style={{
          fontFamily: theme.typography.fontFamily,
          fontWeight: 500,
          fontSize: "14px",
          marginTop: "10px",
          color: theme.palette.grey[500],
          marginBottom: "20px",
        }}
      >
        We're excited to have you set up and ready to go!
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center", my: 6 }}>
        <CheckCircleIcon
          sx={{
            fontSize: 180,
            color: theme.palette.success.main,
          }}
        />
      </Box>

      {error && (
        <Alert
          severity="error"
          sx={{ mt: 2, mb: 2, mx: "auto", maxWidth: 500 }}
        >
          {error}
        </Alert>
      )}

      <Box mt={4} display="flex" justifyContent="center">
        <Button
          variant="contained"
          onClick={onFinish}
          disabled={loading}
          sx={{
            py: 1.5,
            px: 4,
            fontSize: "16px",
            fontWeight: 600,
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : null
          }
        >
          {loading ? "Setting up account..." : "Go to Dashboard"}
        </Button>
      </Box>
    </Box>
  );
};

export default CompletionStep;
