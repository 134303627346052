import { createAsyncThunk } from "@reduxjs/toolkit";

/**
 * Verify if user has customerId / known domain
 */
export const verifyUser = createAsyncThunk(
  "onboarding/verifyUser",
  async ({ accessToken }: { accessToken: string }, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/users/verify`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (!response.ok) {
        const text = await response.text().catch(() => "Unknown error");
        return rejectWithValue(text);
      }
      return await response.json();
      // e.g. { has_customer_id: false, user_has_known_domain: true }
    } catch (err: any) {
      return rejectWithValue(err.message || "Failed to verify user");
    }
  }
);

/**
 * Provision a new customer (team) if the domain is unknown or has_customer_id is false
 */
export const provisionUser = createAsyncThunk(
  "onboarding/provisionUser",
  async (
    {
      accessToken,
      teamName,
      selectedOption,
      selectedSources,
      invitedEmails,
    }: {
      accessToken: string;
      teamName: string;
      selectedOption: string; // "Demo" or "Get Started"
      selectedSources: string[];
      invitedEmails: string[];
    },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      // Example call to backend
      const response = await fetch("/api/v1alpha/users/provision", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          teamName,
          selectedOption,
          selectedSources,
          invitedEmails,
        }),
      });
      if (!response.ok) {
        const text = await response.text().catch(() => "Error provisioning");
        return rejectWithValue(text);
      }
      return await response.json();
      // Possibly returns { success: true, new_customer_id: "abc123" } or similar
    } catch (err: any) {
      return rejectWithValue(err.message || "Failed to provision user");
    }
  }
);

/**
 * Request application approval after the ApprovalStep
 */
export const requestApplicationApproval = createAsyncThunk(
  "onboarding/requestApplicationApproval",
  async (
    {
      accessToken,
      selectedOption,
    }: {
      accessToken: string;
      selectedOption: string | null;
    },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    const isDemoInstance = selectedOption === "Demo";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/application?wants_demo_instance=${isDemoInstance}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        const text = await response
          .text()
          .catch(() => "Application approval request failed");
        return rejectWithValue(text);
      }
      return await response.json();
    } catch (err: any) {
      return rejectWithValue(
        err.message || "Failed to request application approval"
      );
    }
  }
);

/**
 * Invite a user to the application
 */
export const inviteUser = createAsyncThunk(
  "onboarding/inviteUser",
  async (
    {
      accessToken,
      email,
    }: {
      accessToken: string;
      email: string;
    },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1alpha/users?invitee_email=${encodeURIComponent(email)}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response
          .text()
          .catch(() => `Failed to invite user: ${email}`);
        return rejectWithValue(text);
      }

      return { email, success: true };
    } catch (err: any) {
      return rejectWithValue(err.message || `Failed to invite user: ${email}`);
    }
  }
);

/**
 * Update user application status on completion
 */
export const updateApplicationStatus = createAsyncThunk(
  "onboarding/updateApplicationStatus",
  async ({ accessToken }: { accessToken: string }, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/application:done`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      // We don't need to block user if response is not OK, just log
      if (!response.ok) {
        console.warn("Failed to update application status:", response.status);
      }

      return { success: true };
    } catch (err: any) {
      console.warn("Failed to update application status:", err.message);
      return { success: false, error: err.message };
    }
  }
);

/** We can integrate both email-imap and email-m365. */
export interface IntegrationConfig {
  integration_type: "email-imap" | "email-m365" | "edr-sentinelone";
  username?: string;
  password?: string;
  imapServer?: string;
  tenantId?: string;
  clientId?: string;
  clientSecret?: string;
  inbox?: string;
  [key: string]: any;
}

/**
 * Verify an integration configuration
 */
export const verifyIntegration = createAsyncThunk(
  "onboarding/verifyIntegration",
  async (
    {
      accessToken,
      integrationConfig,
    }: {
      accessToken: string;
      integrationConfig: IntegrationConfig;
    },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/integrations/verifications`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(integrationConfig),
        }
      );

      if (!response.ok) {
        const text = await response
          .text()
          .catch(() => "Failed to verify integration");
        return rejectWithValue(text);
      }

      const data = await response.json();
      // e.g. data = { verification_id: "xyz123" }
      return data; // The thunk returns that entire object
    } catch (err: any) {
      return rejectWithValue(err.message || "Failed to verify integration");
    }
  }
);

/**
 * Check integration verification status
 */
export const checkVerificationStatus = createAsyncThunk(
  "onboarding/checkVerificationStatus",
  async (
    {
      accessToken,
      verificationId,
    }: {
      accessToken: string;
      verificationId: string;
    },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/integrations/verifications/${verificationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response
          .text()
          .catch(() => "Failed to check verification status");
        return rejectWithValue(text);
      }

      const data = await response.json();
      // e.g. { verification_status: "completed", results: {...} }
      return data;
    } catch (err: any) {
      return rejectWithValue(
        err.message || "Failed to check verification status"
      );
    }
  }
);

/**
 * Save (POST) a new integration
 */
export const saveIntegration = createAsyncThunk(
  "onboarding/saveIntegration",
  async (
    {
      accessToken,
      integrationConfig,
      createPullConnector = true,
      subClientId = null,
    }: {
      accessToken: string;
      integrationConfig: IntegrationConfig;
      createPullConnector?: boolean;
      subClientId?: string | null;
    },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const requestBody = {
        ...integrationConfig,
        createPullConnector,
        subClientId,
      };

      // This calls POST /integrations
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/integrations`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const text = await response
          .text()
          .catch(() => "Failed to save integration");
        return rejectWithValue(text);
      }

      const data = await response.json();
      // e.g. { integration: {...}, integration_metadata: {...} }
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message || "Failed to save integration");
    }
  }
);

/**
 * Update (PUT) an existing integration
 *  => @router.put('/integrations/{integration_id}')
 */
export const updateIntegration = createAsyncThunk(
  "onboarding/updateIntegration",
  async (
    {
      accessToken,
      integrationId,
      integrationConfig,
      createPullConnector = true,
      subClientId = null,
    }: {
      accessToken: string;
      integrationId: string; // passed as a param => "/integrations/:integration_id"
      integrationConfig: IntegrationConfig;
      createPullConnector?: boolean;
      subClientId?: string | null;
    },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const requestBody = {
        ...integrationConfig,
        createPullConnector,
        subClientId,
      };

      // We'll do PUT /integrations/{integration_id}
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/integrations/${integrationId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const text = await response
          .text()
          .catch(() => "Failed to update integration");
        return rejectWithValue(text);
      }

      const data = await response.json();
      // e.g. { integration: {...}, integration_metadata: {...} }
      return data;
    } catch (err: any) {
      return rejectWithValue(err.message || "Failed to update integration");
    }
  }
);

export const deleteIntegration = createAsyncThunk(
  "onboarding/deleteIntegration",
  async (
    {
      accessToken,
      integrationId,
    }: {
      accessToken: string;
      integrationId: string;
    },
    { rejectWithValue }
  ) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/integrations/${integrationId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response
          .text()
          .catch(() => "Failed to delete integration");
        return rejectWithValue(text);
      }

      // If the delete was successful, the backend might return a 204 (No Content) or some JSON
      // For example, you can return the integrationId to remove it from the store
      return { integrationId };
    } catch (err: any) {
      return rejectWithValue(err.message || "Failed to delete integration");
    }
  }
);
