import React from "react";
import { Box, Typography, Tooltip /* Avatar */ } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { toShortDate } from "../../../utils/dateFormatter";

interface CaseDetailsSectionProps {
  createdOn: string; // e.g. "2025-01-17T03:22:28Z"
  alertTime: string; // e.g. "2024-12-23T17:03:42Z"
  analyst?: string;
  analystAvatar?: string;
  attackSurface: string;
}

const CaseDetailsSection: React.FC<CaseDetailsSectionProps> = ({
  createdOn,
  alertTime,
  analyst,
  analystAvatar,
  attackSurface,
}) => {
  const theme = useTheme();

  // Determine label text based on attackSurface
  // const createdOnLabel =
  //   attackSurface.toLowerCase() === "email" ? "Email Rcvd" : "Alert Rcvd";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(4),
        marginBottom: theme.spacing(1),
        fontSize: "0.875rem",
      }}
    >
      {/* Verndor / Alert Time */}
      <Typography variant="body2" color={theme.palette.text.secondary}>
        Vendor{" "}
        <Tooltip title={alertTime}>
          <Typography
            variant="body2"
            component="span"
            fontWeight="bold"
            sx={{ cursor: "pointer" }}
          >
            {toShortDate(alertTime)}
          </Typography>
        </Tooltip>
      </Typography>

      {/* Embed Rcvd / Created Time */}
      <Typography variant="body2" color={theme.palette.text.secondary}>
        Embed Rcvd{" "}
        <Tooltip title={createdOn}>
          <Typography
            variant="body2"
            component="span"
            fontWeight="bold"
            sx={{ cursor: "pointer" }}
          >
            {toShortDate(createdOn)}
          </Typography>
        </Tooltip>
      </Typography>

      {/*
       If decide to bring back the analyst:
       <Box sx={{ display: "inline-flex", alignItems: "center" }}>
         <Typography variant="body2" color={theme.palette.text.secondary}>
           Analyst
         </Typography>
         <Avatar .../>
         <Typography variant="body2" component="span" fontWeight="bold">
           {analyst}
         </Typography>
       </Box>
      */}
    </Box>
  );
};

export default CaseDetailsSection;
