import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";

interface KeyEvidenceSectionProps {
  keyIndicators: string[];
  impact: string[];
  description: string;
  remediation: string[];
  next_steps: string[];
}

const KeyEvidenceSection: React.FC<KeyEvidenceSectionProps> = ({
  keyIndicators,
  impact,
  description,
  remediation,
  next_steps,
}) => {
  const theme = useTheme();

  // Left toggle: "Key Indicators" or "Full Description"
  const [infoView, setInfoView] = useState<"indicators" | "description">(
    "indicators"
  );
  // Right toggle: "Fix It" or "Investigate More"
  const [actionView, setActionView] = useState<"fix" | "investigate">("fix");

  // Helper to render bullet lists as tightly as possible.
  // We'll set `component="ul"` so MUI renders an actual <ul> with bullets,
  // and override the spacing.
  const renderBullets = (items: string[], bold?: boolean) => {
    return (
      <List
        component="ul"
        disablePadding
        sx={{
          // Indent a bit so the bullets aren’t flush against the left
          pl: 2,
          // Make sure list style is “disc” bullets
          listStyleType: "disc",
          // Force child <li> elements to display the bullet
          "& li": {
            display: "list-item",
            listStyleType: "inherit",
          },
        }}
      >
        {items.map((item, i) => (
          <ListItem
            key={i}
            component="li"
            disableGutters
            sx={{
              p: 0,
              m: 0,
              // No extra padding or margin
              lineHeight: 1.3,
            }}
          >
            <ListItemText
              sx={{ m: 0 }}
              primary={
                bold ? <span style={{ fontWeight: "bold" }}>{item}</span> : item
              }
            />
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", gap: 4 }}>
      {/* Left Column: Key Indicators vs Full Description */}
      <Box sx={{ flex: 1 }}>
        {/* The toggle “tabs” row */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            userSelect: "none",
          }}
        >
          <Typography
            onClick={() => setInfoView("indicators")}
            variant="body1"
            sx={{
              cursor: "pointer",
              fontWeight: infoView === "indicators" ? "bold" : "normal",
              color:
                infoView === "indicators"
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            Key Indicators
          </Typography>
          <Typography
            variant="body1"
            sx={{ mx: 1, color: theme.palette.text.disabled }}
          >
            |
          </Typography>
          <Typography
            onClick={() => setInfoView("description")}
            variant="body1"
            sx={{
              cursor: "pointer",
              fontWeight: infoView === "description" ? "bold" : "normal",
              color:
                infoView === "description"
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            Full Description
          </Typography>
        </Box>

        {/* Content for the left column */}
        {infoView === "indicators" ? (
          <>
            {renderBullets(keyIndicators)}
            {/* Then show “impact” bullets in bold */}
            {renderBullets(impact, true)}
          </>
        ) : (
          <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
            {description}
          </Typography>
        )}
      </Box>

      {/* Right Column: Fix It vs Investigate More */}
      <Box sx={{ flex: 1 }}>
        {/* The toggle “tabs” row */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            userSelect: "none",
          }}
        >
          <Typography
            onClick={() => setActionView("fix")}
            variant="body1"
            sx={{
              cursor: "pointer",
              fontWeight: actionView === "fix" ? "bold" : "normal",
              color:
                actionView === "fix"
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            Fix It
          </Typography>
          <Typography
            variant="body1"
            sx={{ mx: 1, color: theme.palette.text.disabled }}
          >
            |
          </Typography>
          <Typography
            onClick={() => setActionView("investigate")}
            variant="body1"
            sx={{
              cursor: "pointer",
              fontWeight: actionView === "investigate" ? "bold" : "normal",
              color:
                actionView === "investigate"
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }}
          >
            Investigate More
          </Typography>
        </Box>

        {/* Content for the right column */}
        {actionView === "fix"
          ? renderBullets(remediation)
          : renderBullets(next_steps)}
      </Box>
    </Box>
  );
};

export default KeyEvidenceSection;
