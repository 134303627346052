import React from "react";
import { Handle, Position } from "@xyflow/react";
import { useTheme, Box, Typography, Button, Tooltip } from "@mui/material";

// MUI Icons
import ReportIcon from "@mui/icons-material/Report";
import EmailIcon from "@mui/icons-material/Email";
import TitleIcon from "@mui/icons-material/Title";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import { AttachEmail, Dns, Http, Phishing, Send } from "@mui/icons-material";

// Local SVG assets
import { ReactComponent as AWSEmbedFinding } from "../../../assets/aws-embed-finding.svg";
import { ReactComponent as AWSApi } from "../../../assets/aws-api.svg";
import { ReactComponent as AWSec2 } from "../../../assets/aws-ec2.svg";
import { ReactComponent as AWSS3 } from "../../../assets/aws-s3.svg";
import { ReactComponent as AWSSts } from "../../../assets/aws-sts.svg";
import { ReactComponent as AWSSsm } from "../../../assets/aws-ssm.svg";
import { ReactComponent as AWSIdentity } from "../../../assets/aws-identity.svg";
import { ReactComponent as AWSDefault } from "../../../assets/aws-svgrepo-com.svg";
import { ReactComponent as IPAddress } from "../../../assets/ip-address.svg";

const ICON_SIZE = 40;

// ------------------------------------
// Map from email-based node kinds to icons
// ------------------------------------
const kindToIconMap: { [key: string]: JSX.Element } = {
  UserPhishingReport: <ReportIcon />,
  "User Phishing Report": <Phishing />,
  IPAddress: <Dns />,
  "IP Address": <Dns />,
  emailAddress: <EmailIcon />,
  "Email Address": <EmailIcon />,
  Title: <TitleIcon />,
  Attachment: <AttachFileIcon />,
  "Email Attachment": <AttachEmail />,
  URL: <Http />,
  "Email URL": <Http />,
  "Sender IP Address": <Dns />,
  "To Email Addresses": <Send />,
  "From Email Address": <EmailIcon />,
  "Email Body": <TextFieldsIcon />,

  // Cloud node kinds
  CLOUD_SOURCE: <IPAddress />,
  CLOUD_IDENTITY: <AWSIdentity />,
  CLOUD_ALERT: <AWSEmbedFinding />,
  CLOUD_ACTION: <AWSApi />,
  CLOUD_RESOURCE: <AWSDefault />,

  Default: <HelpOutlineIcon />,
};

const serviceIcons: { [key: string]: JSX.Element } = {
  ec2: <AWSec2 />,
  "ec2-instance-connect": <AWSec2 />,
  ssm: <AWSSsm />,
  sts: <AWSSts />,
  s3: <AWSS3 />,
  default: <AWSDefault />,
};

interface CustomNodeProps {
  id: string;
  data: {
    data: any;
    hiddenCount: number;
    onToggleNode: (nodeId: string) => void;
    isExpanded: boolean;
    decision: string;
    label: string;
    kind: string;
    timestamp?: string;
    isTrigger?: boolean;

    // if kind === "CLOUD_RESOURCE"
    service?: string;
    errorCode?: string;
  };
}

const CustomNode: React.FC<CustomNodeProps> = React.memo(({ id, data }) => {
  const theme = useTheme();

  const {
    hiddenCount,
    onToggleNode,
    isExpanded,
    decision,
    label,
    kind,
    timestamp = "",
    isTrigger = false,
    service,
    errorCode,
  } = data;

  // Identify states
  const isMalicious = decision === "Malicious";
  const isFailed = !!errorCode;
  const isCloudAlert = kind === "CLOUD_ALERT";
  const doShimmer = isMalicious || isTrigger || isCloudAlert;

  // Choose icon
  let DefaultIcon = kindToIconMap[kind] || kindToIconMap["Default"];
  if ((kind === "CLOUD_APICALL" || kind === "CLOUD_RESOURCE") && service) {
    DefaultIcon = serviceIcons[service] ?? serviceIcons["default"];
  }

  // Shimmer keyframes
  const shimmerKeyframes = `
    @keyframes shimmer {
      0%   { background-position: -200% 0; }
      100% { background-position: 200% 0; }
    }
  `;

  const containerOpacity = data?.data?.unimportant ? 0.3 : 1;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        opacity: containerOpacity,
        filter: data?.data?.unimportant ? "grayscale(80%)" : "none",
      }}
    >
      {/* Shimmer keyframes */}
      <style>{shimmerKeyframes}</style>

      {/* Node Title */}
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          top: -20,
          fontSize: 14,
          fontWeight: "bold",
          textAlign: "center",
          whiteSpace: "nowrap",
          color: theme.palette.text.primary,
        }}
      >
        {isTrigger ? "Root Alert" : label}
      </Typography>

      {/* Node Kind */}
      <Typography
        variant="caption"
        sx={{
          fontSize: 10,
          color: theme.palette.text.secondary,
          mt: 1,
          mb: 1,
        }}
      >
        {kind}
      </Typography>

      {/* Main circle */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // Shimmer if malicious, root alert, or cloud alert
          backgroundColor: doShimmer
            ? theme.palette.error.main
            : theme.palette.background.default,
          border: `2px solid ${
            doShimmer ? theme.palette.error.dark : theme.palette.divider
          }`,
          borderRadius: "50%",
          width: 120,
          height: 120,
          boxShadow: theme.shadows[3],
          position: "relative",
          overflow: "hidden",
          "&::before": {
            ...(doShimmer && {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `linear-gradient(
                90deg,
                transparent,
                rgba(255,255,255,0.3),
                transparent
              )`,
              backgroundSize: "200% 100%",
              backgroundPosition: "-200% 0",
              animation: `shimmer 2s linear 1 forwards`,
              zIndex: 1,
            }),
          },
        }}
      >
        {/* Inner circle */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: doShimmer
              ? theme.palette.error.main
              : theme.palette.background.default,
            border: `2px solid ${
              doShimmer ? theme.palette.error.dark : theme.palette.divider
            }`,
            borderRadius: "50%",
            width: 120,
            height: 120,
            boxShadow: theme.shadows[3],
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* The base icon is always rendered underneath */}
          <Box
            sx={{
              width: ICON_SIZE,
              height: ICON_SIZE,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {React.cloneElement(DefaultIcon, {
              width: ICON_SIZE,
              height: ICON_SIZE,
            })}
          </Box>

          {/* 
          If isFailed, place a small red circle with an X at bottom-left.
          Use `title` or a Tooltip for the hover text.
        */}
          {isFailed && (
            <Tooltip title="Event was denied execution">
              <Box
                sx={{
                  position: "absolute",
                  bottom: 25,
                  left: 30,
                  width: 15,
                  height: 15,
                  borderRadius: "50%",
                  backgroundColor: theme.palette.error.dark,
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  fontWeight: "bold",
                  cursor: "default",
                }}
              >
                X
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>

      {/* Timestamp below node (if any) */}
      {timestamp && (
        <Typography
          variant="caption"
          sx={{
            mt: 1,
            fontSize: 10,
            color: theme.palette.text.secondary,
          }}
        >
          {timestamp}
        </Typography>
      )}

      {/* React Flow handles */}
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: theme.palette.primary.main,
          border: `1px solid ${theme.palette.divider}`,
        }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{
          background: theme.palette.primary.main,
          border: `1px solid ${theme.palette.divider}`,
        }}
      />

      {/* Expand/Collapse button if hidden > 0 or isExpanded */}
      {(hiddenCount > 0 || isExpanded) && (
        <Button
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onToggleNode(id);
          }}
          sx={{
            fontSize: "0.65rem",
            minWidth: "auto",
            p: "2px 4px",
            mt: "4px",
          }}
        >
          {isExpanded
            ? `Collapse (-${hiddenCount})`
            : `Expand (+${hiddenCount})`}
        </Button>
      )}
    </Box>
  );
});

export default CustomNode;
