import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  useTheme,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../src/app/rootReducer";
import { Case } from "../features/cases/types/types";
import { useAppDispatch } from "../app/hooks";
import { fetchCases } from "../../src/api/casesApi";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSkeleton from "../features/cases/components/LoadingSkeleton";
import ErrorDisplay from "../features/cases/components/ErrorDisplay";
import TopBar from "../features/cases/components/TopBar";
import FiltersSection from "../features/cases/components/FiltersSection";
import PaginationControls from "../features/cases/components/PaginationControls";
import dayjs from "dayjs";
import UploadEmailModal from "../features/cases/components/UploadEmailModal";
import { Link as RouterLink } from "react-router-dom";
import {
  UploadItem,
  useUploadManager,
} from "../features/upload/components/UploadManager";
import EnhancedCasesTable from "../features/cases/components/EnhancedCasesTable";
import FilterPopup, {
  FilterPopupRef,
} from "../features/cases/components/FilterPopup";

interface CustomQuickFilter {
  name: string;
  filters: any;
}

function getInitialCasesListState() {
  const savedState = JSON.parse(
    localStorage.getItem("CasesListPageState") || "{}"
  );

  const areAdvancedFiltersEmpty =
    !savedState.advancedFilters ||
    Object.values(savedState.advancedFilters).every((val: any) =>
      Array.isArray(val) ? val.length === 0 : !val
    );

  return {
    activeFilter: savedState.activeFilter || "Escalated",
    searchTerm: savedState.searchTerm || "",
    advancedFilters: areAdvancedFiltersEmpty
      ? null
      : savedState.advancedFilters,
    page: savedState.page || 1,
    order: savedState.order || undefined,
    orderBy: savedState.orderBy || undefined,
  };
}

function areAdvancedFiltersEmpty(filters: any): boolean {
  if (!filters) return true;

  const {
    conclusions = [],
    statuses = [],
    severities = [],
    createdAt = [],
    alertTimestamp = [],
    attackers = [],
    targets = [],
    attackSurfaces = [],
    mitreStages = [],
    feedback = [],
    smeFeedback = [],
    campaignIds = [],
  } = filters;

  const isEmptyArray = (arr: any[]) => !arr || arr.length === 0;
  const noDateSelected = (dates: [any, any]) =>
    !dates || (!dates[0] && !dates[1]);

  return (
    isEmptyArray(conclusions) &&
    isEmptyArray(statuses) &&
    isEmptyArray(severities) &&
    isEmptyArray(attackSurfaces) &&
    isEmptyArray(mitreStages) &&
    noDateSelected(createdAt) &&
    noDateSelected(alertTimestamp) &&
    isEmptyArray(attackers) &&
    isEmptyArray(targets) &&
    isEmptyArray(feedback) &&
    isEmptyArray(smeFeedback) &&
    isEmptyArray(campaignIds)
  );
}

const CasesListPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const filterPopupRef = useRef<FilterPopupRef>(null);

  const cases = useSelector((state: RootState) => state.cases.cases);
  const loading = useSelector((state: RootState) => state.cases.loading);
  const error = useSelector((state: RootState) => state.cases.error);
  const [totalGroups, setTotalGroups] = useState(0); // new state to hold group count

  const {
    uploads,
    addUploadItem,
    markUploadReady,
    markUploadError,
    pollCaseStatus,
  } = useUploadManager();

  const [showCheck, setShowCheck] = useState(false);

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  // Track previously-known "ready" IDs
  const previousUploadsRef = useRef<UploadItem[]>(uploads);

  useEffect(() => {
    const previousUploads = previousUploadsRef.current;
    const newlyReadyItems = uploads.filter((currentItem) => {
      if (currentItem.status !== "ready") return false;
      const oldVersion = previousUploads.find((p) => p.id === currentItem.id);
      return oldVersion && oldVersion.status !== "ready";
    });

    if (newlyReadyItems.length > 0) {
      setShowCheck(true);
      setTimeout(() => setShowCheck(false), 5000);

      const lastReady = newlyReadyItems[newlyReadyItems.length - 1];
      setSnackbarMessage(`Analysis complete for "${lastReady.fileName}" - `);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    }
    previousUploadsRef.current = uploads;
  }, [uploads]);

  // Restore saved filter state
  const {
    activeFilter: initialActiveFilter,
    searchTerm: initialSearchTerm,
    advancedFilters: initialAdvancedFilters,
    page: initialPage,
    order: initialOrder,
    orderBy: initialOrderBy,
  } = getInitialCasesListState();

  // Local states
  const [activeFilter, setActiveFilter] = useState(initialActiveFilter);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [advancedFilters, setAdvancedFilters] = useState<any>(
    initialAdvancedFilters
  );
  const [page, setPage] = useState(initialPage);
  const rowsPerPage = 15;

  const [order, setOrder] = useState<"asc" | "desc" | undefined>(initialOrder);
  const [orderBy, setOrderBy] = useState<keyof Case | undefined>(
    initialOrderBy
  );

  const [filteredCases, setFilteredCases] = useState<Case[]>([]);

  // ============ Upload Email Modal ============
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const handleOpenUploadModal = () => setIsUploadModalOpen(true);
  const handleCloseUploadModal = () => setIsUploadModalOpen(false);

  // ============ Custom Filters ============
  const [customFilters, setCustomFilters] = useState<CustomQuickFilter[]>([]);

  function loadCustomFilters(): CustomQuickFilter[] {
    try {
      const stored = localStorage.getItem("CustomQuickFilters");
      return stored ? JSON.parse(stored) : [];
    } catch {
      return [];
    }
  }

  function saveCustomFilters(filtersToSave: CustomQuickFilter[]) {
    localStorage.setItem("CustomQuickFilters", JSON.stringify(filtersToSave));
  }

  useEffect(() => {
    const loaded = loadCustomFilters();
    setCustomFilters(loaded);
  }, []);

  // ============ Save Filter Dialog (Add new) ============
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [saveDialogFilterData, setSaveDialogFilterData] = useState<any>(null);

  // We track the new filter name with 8-char validation
  const [newFilterName, setNewFilterName] = useState("");

  // Called by FilterPopup "Save" button => we open the naming dialog
  const handleOpenSaveDialog = (builtFilter: any) => {
    setSaveDialogFilterData(builtFilter);
    setNewFilterName("");
    setSaveDialogOpen(true);
  };

  const handleCloseSaveDialog = () => {
    setSaveDialogOpen(false);
    setSaveDialogFilterData(null);
  };

  const handleConfirmSaveDialog = () => {
    if (!saveDialogFilterData) return;

    const finalName = newFilterName.trim().slice(0, 8);
    const newFilter: CustomQuickFilter = {
      name: finalName,
      filters: saveDialogFilterData,
    };

    const updated = [...customFilters];
    updated.unshift(newFilter);
    if (updated.length > 3) {
      updated.pop();
    }
    setCustomFilters(updated);
    saveCustomFilters(updated);

    setSaveDialogOpen(false);
    setSaveDialogFilterData(null);
  };

  // ============ Edit / Delete ============
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editingFilter, setEditingFilter] = useState<CustomQuickFilter | null>(
    null
  );

  // We track the edit filter name with 8-char validation
  const [editFilterName, setEditFilterName] = useState("");

  const handleEditCustomFilter = (index: number) => {
    setEditingIndex(index);
    setEditingFilter(customFilters[index]);
    setEditFilterName(customFilters[index].name);
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setEditingIndex(null);
    setEditingFilter(null);
    setEditFilterName("");
  };

  function handleConfirmEdit() {
    if (filterPopupRef.current) {
      // Pull the advanced filter data from the child
      const updatedFilters = filterPopupRef.current.getFinalFilters();
      // also use the local "editFilterName" for the new name
      handleUpdateCustomFilter(editingIndex!, editFilterName, updatedFilters);
    }
  }

  const handleUpdateCustomFilter = (
    index: number,
    newName: string,
    newFilters: any
  ) => {
    const finalName = newName.trim().slice(0, 8);

    const updated = [...customFilters];
    updated[index] = { name: finalName, filters: newFilters };
    setCustomFilters(updated);
    saveCustomFilters(updated);

    // Immediately apply
    setActiveFilter(finalName);
    setAdvancedFilters(newFilters);
    setSearchTerm("");

    handleCloseEditDialog();
  };

  // Delete
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const handleDeleteCustomFilter = (index: number) => {
    setDeleteDialogOpen(true);
    setDeleteIndex(index);
  };

  const handleConfirmDelete = () => {
    if (deleteIndex === null) return;
    const updated = [...customFilters];
    updated.splice(deleteIndex, 1);
    setCustomFilters(updated);
    saveCustomFilters(updated);

    setDeleteDialogOpen(false);
    setDeleteIndex(null);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setDeleteIndex(null);
  };

  // ============ Persist filter state ============
  useEffect(() => {
    const stateToSave = {
      activeFilter,
      searchTerm,
      advancedFilters,
      page,
      order,
      orderBy,
    };
    localStorage.setItem("CasesListPageState", JSON.stringify(stateToSave));
  }, [activeFilter, searchTerm, advancedFilters, page, order, orderBy]);

  // Fetch cases if none
  useEffect(() => {
    if (cases.length === 0) {
      const fetchData = async () => {
        try {
          const token = await getAccessTokenSilently();
          await dispatch(fetchCases(token)).unwrap();
          setSnackbarMessage("Cases loaded successfully!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } catch (error: any) {
          console.error("Error fetching cases:", error);
          setSnackbarMessage("Failed to load cases.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      };
      fetchData();
    }
  }, [cases.length, dispatch, getAccessTokenSilently]);

  // ============ Filter Logic ============
  const applyAdvancedFilters = useCallback(
    (casesToFilter: Case[], filters: any): Case[] => {
      const {
        conclusions = [],
        statuses = [],
        severities = [],
        createdAt = [],
        alertTimestamp = [],
        attackSurfaces = [],
        mitreStages = [],
        feedback = [],
        attackers = [],
        targets = [],
        smeFeedback = [],
        campaignIds = [],
      } = filters;

      let filtered = [...casesToFilter];

      if (conclusions.length > 0) {
        filtered = filtered.filter((caseItem) => {
          const itemConclusion =
            caseItem.conclusion === "Malicious_Benign"
              ? "Malicious-NoAction"
              : caseItem.conclusion;
          return conclusions.includes(itemConclusion);
        });
      }
      if (statuses.length > 0) {
        filtered = filtered.filter((caseItem) =>
          statuses.includes(caseItem.status)
        );
      }
      if (severities.length > 0) {
        filtered = filtered.filter((caseItem) =>
          severities.includes(caseItem.severity)
        );
      }
      if (attackSurfaces.length > 0) {
        filtered = filtered.filter((caseItem) =>
          attackSurfaces.includes(caseItem.attackSurface)
        );
      }
      if (mitreStages.length > 0) {
        filtered = filtered.filter((caseItem) =>
          mitreStages.includes(caseItem.mitreStage)
        );
      }
      if (createdAt[0] || createdAt[1]) {
        filtered = filtered.filter((caseItem) => {
          const caseDate = dayjs(caseItem.createdAt);
          const [start, end] = createdAt;
          if (start && caseDate.isBefore(dayjs(start), "day")) return false;
          if (end && caseDate.isAfter(dayjs(end), "day")) return false;
          return true;
        });
      }
      if (alertTimestamp[0] || alertTimestamp[1]) {
        filtered = filtered.filter((caseItem) => {
          if (!caseItem.alertTimestamp) return false;
          const alertDate = dayjs(caseItem.alertTimestamp);
          const [alertStart, alertEnd] = alertTimestamp;
          if (alertStart && alertDate.isBefore(dayjs(alertStart), "day")) {
            return false;
          }
          if (alertEnd && alertDate.isAfter(dayjs(alertEnd), "day")) {
            return false;
          }
          return true;
        });
      }
      // attacker filter
      if (attackers.length > 0) {
        filtered = filtered.filter((caseItem) => {
          const attackerString = caseItem.attacker?.toLowerCase() || "";
          return attackers.some((id: string) =>
            attackerString.includes(id.toLowerCase())
          );
        });
      }

      // target filter
      if (targets.length > 0) {
        filtered = filtered.filter((caseItem) => {
          const targetString = caseItem.target?.toLowerCase() || "";
          return targets.some((id: string) =>
            targetString.includes(id.toLowerCase())
          );
        });
      }
      if (feedback.length > 0) {
        filtered = filtered.filter((caseItem) => {
          const fb = caseItem.feedback?.toLowerCase().trim() || "";
          const hasFeedback = fb.length > 0;
          return feedback.some((selected: string) => {
            if (selected === "agree") return fb === "agree";
            if (selected === "disagree") return fb === "disagree";
            if (selected === "has_feedback") return hasFeedback;
            if (selected === "no_feedback") return !hasFeedback;
            return false;
          });
        });
      }
      // SME Feedback filter
      if (smeFeedback.length > 0) {
        filtered = filtered.filter((caseItem) => {
          const hasSme = !!caseItem.internal?.hasSmeFeedback;
          // If user selected "has_sme" => pass only if hasSme === true
          // If user selected "no_sme" => pass only if hasSme === false
          // If the user selected both "has_sme" and "no_sme," we should display both sets.
          // So we check if *any* item in 'smeFeedback' matches the current case’s condition:
          return smeFeedback.some((selection: string) => {
            if (selection === "has_sme") return hasSme;
            if (selection === "no_sme") return !hasSme;
            return false;
          });
        });
      }

      // CampaignIds Filter
      if (campaignIds.length > 0) {
        filtered = filtered.filter((caseItem) => {
          const hasCampaign =
            !!caseItem.campaignId && caseItem.campaignId.trim() !== "";

          // If the user selected both has_campaign + no_campaign, we'd show all
          // If only one is selected, we only show that set
          return campaignIds.some((selected: string) => {
            if (selected === "has_campaign") return hasCampaign;
            if (selected === "no_campaign") return !hasCampaign;
            return false;
          });
        });
      }
      return filtered;
    },
    []
  );

  const filterCases = useCallback(() => {
    let filteredData = [...cases];

    if (advancedFilters && !areAdvancedFiltersEmpty(advancedFilters)) {
      filteredData = applyAdvancedFilters(filteredData, advancedFilters);
    } else {
      if (activeFilter === "Escalated") {
        filteredData = filteredData.filter(
          (caseItem) => caseItem.conclusion === "Malicious"
        );
      } else if (activeFilter === "Benign") {
        filteredData = filteredData.filter(
          (caseItem) =>
            caseItem.conclusion === "Benign" ||
            caseItem.conclusion === "Malicious_Benign"
        );
      }
    }

    if (searchTerm.trim() !== "") {
      const term = searchTerm.toLowerCase();
      filteredData = filteredData.filter((caseItem) =>
        Object.values(caseItem).some((val) =>
          val?.toString().toLowerCase().includes(term)
        )
      );
    }
    setFilteredCases(filteredData);
    setPage(1);
  }, [cases, activeFilter, searchTerm, advancedFilters, applyAdvancedFilters]);

  useEffect(() => {
    filterCases();
  }, [filterCases]);

  // Unique filter values
  const uniqueConclusions = Array.from(
    new Set(
      cases
        .map((c) =>
          c.conclusion === "Malicious_Benign"
            ? "Malicious-NoAction"
            : c.conclusion
        )
        .filter(Boolean)
    )
  );
  const uniqueStatuses = Array.from(
    new Set(cases.map((c) => c.status).filter(Boolean))
  );
  const uniqueSeverities = Array.from(
    new Set(cases.map((c) => c.severity).filter(Boolean))
  );
  const uniqueAttackSurfaces = Array.from(
    new Set(cases.map((c) => c.attackSurface).filter(Boolean))
  );
  const uniqueMitreStages = Array.from(
    new Set(cases.map((c) => c.mitreStage).filter(Boolean))
  );
  const uniqueCampaignIds = Array.from(
    new Set(cases.map((c) => c.campaignId).filter(Boolean))
  );

  const handleApplyFilters = (filters: any) => {
    setSearchTerm("");
    setActiveFilter("all");
    setAdvancedFilters(filters);
    setPage(1);
  };

  const handleRefresh = async () => {
    try {
      const token = await getAccessTokenSilently();
      await dispatch(fetchCases(token)).unwrap();
      setSnackbarMessage("Cases refreshed successfully!");
      setSnackbarSeverity("success");
    } catch (error: any) {
      console.error("Error refreshing cases:", error);
      setSnackbarMessage("Failed to refresh cases.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  // Re-check "processing" uploads
  useEffect(() => {
    const recheckProcessing = async () => {
      try {
        const token = await getAccessTokenSilently();
        for (const item of uploads) {
          if (item.status === "processing") {
            await pollCaseStatus(item.id, token);
          }
        }
      } catch (error) {
        console.error("Error rechecking items:", error);
      }
    };
    recheckProcessing();
  }, [getAccessTokenSilently, pollCaseStatus, uploads]);

  if (loading && cases.length === 0) return <LoadingSkeleton />;
  if (error) return <ErrorDisplay error={error} />;

  // Helper checks for disabling the save/update button if empty
  const isNewFilterNameEmpty = newFilterName.trim().length === 0;
  const isEditFilterNameEmpty = editFilterName.trim().length === 0;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        width: "100%",
        [theme.breakpoints.down("xl")]: {
          padding: theme.spacing(1),
        },
      }}
    >
      <TopBar
        totalCases={cases.length}
        onRefresh={handleRefresh}
        loading={loading}
        isAnyProcessing={uploads.some((u) => u.status === "processing")}
        showCheck={showCheck}
        uploads={uploads}
        onUploadEmailClick={handleOpenUploadModal}
      />

      <FiltersSection
        cases={cases}
        setFilteredCases={setFilteredCases}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        uniqueConclusions={uniqueConclusions}
        uniqueStatuses={uniqueStatuses}
        uniqueSeverities={uniqueSeverities}
        uniqueAttackSurfaces={uniqueAttackSurfaces}
        uniqueMitreStages={uniqueMitreStages}
        uniqueCampaignIds={uniqueCampaignIds}
        applyFilters={handleApplyFilters}
        setPage={setPage}
        advancedFilters={advancedFilters}
        setAdvancedFilters={setAdvancedFilters}
        customFilters={customFilters}
        onSaveCustomFilter={handleOpenSaveDialog}
        onApplyCustomFilter={(name) => {
          const found = customFilters.find((f) => f.name === name);
          if (!found) return;
          setActiveFilter(name);
          setAdvancedFilters(found.filters);
          setSearchTerm("");
          setPage(1);
        }}
        onEditCustomFilter={handleEditCustomFilter}
        onDeleteCustomFilter={handleDeleteCustomFilter}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
      />

      <EnhancedCasesTable
        cases={filteredCases}
        page={page}
        rowsPerPage={rowsPerPage}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        onTotalGroupCountChange={setTotalGroups}
      />

      <PaginationControls
        totalCases={totalGroups}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
      />

      <UploadEmailModal
        open={isUploadModalOpen}
        onClose={handleCloseUploadModal}
        addUploadItem={addUploadItem}
        markUploadReady={markUploadReady}
        markUploadError={markUploadError}
        pollCaseStatus={pollCaseStatus}
      />

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        maxWidth={false}
        PaperProps={{ sx: { minWidth: 400 } }}
      >
        {editingFilter && editingIndex !== null && (
          <>
            <DialogTitle
              sx={{ display: "flex", flexDirection: "column", gap: 1 }}
            >
              <Typography variant="h6">Edit Filter</Typography>
              <TextField
                label="Filter Name (max 8 chars)"
                size="small"
                value={editFilterName}
                onChange={(e) => setEditFilterName(e.target.value)}
                inputProps={{ maxLength: 8 }}
              />
            </DialogTitle>
            <DialogContent>
              <FilterPopup
                ref={filterPopupRef} // <--- pass the ref
                asDialog
                open
                onClose={handleCloseEditDialog}
                currentFilters={editingFilter.filters}
                editingIndex={editingIndex}
                defaultName={editFilterName}
                // We hide the internal update/clear in edit mode
                onUpdateCustomFilter={() => {}}
                onSaveCustomFilter={() => {}}
                applyFilters={() => {}}
                conclusions={uniqueConclusions}
                statuses={uniqueStatuses}
                severities={uniqueSeverities}
                attackSurfaces={uniqueAttackSurfaces}
                mitreStages={uniqueMitreStages}
                campaignIds={uniqueCampaignIds}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEditDialog}>Cancel</Button>
              <Button
                variant="contained"
                onClick={handleConfirmEdit} // <--- calls getFinalFilters + updates
                disabled={editFilterName.trim().length === 0}
              >
                Update
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* Save Filter Dialog */}
      <Dialog
        open={saveDialogOpen}
        onClose={handleCloseSaveDialog}
        maxWidth={false} // We'll manually size it
        PaperProps={{ sx: { width: 400 } }}
      >
        <DialogTitle>Save Current Filter</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <TextField
              label="Filter Name (max 8 chars)"
              value={newFilterName}
              onChange={(e) => setNewFilterName(e.target.value)}
              inputProps={{ maxLength: 8 }}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSaveDialog}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleConfirmSaveDialog}
            disabled={isNewFilterNameEmpty}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Delete Filter</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this custom filter?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDelete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
          {snackbarMessage.includes("Analysis complete for") && (
            <RouterLink
              to={`/cases/${
                uploads.filter((u) => u.status === "ready").slice(-1)[0]?.id
              }`}
              style={{ marginLeft: 8, textDecoration: "underline" }}
            >
              View Case
            </RouterLink>
          )}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CasesListPage;
