import React from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import onboardImage from "../../../assets/onboard-dashboard-img.svg";
import onboardImage2 from "../../../assets/onboard_dashboard.png";
interface WelcomeStepProps {
  onNext: () => void;
}

const WelcomeStep: React.FC<WelcomeStepProps> = ({ onNext }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      mt={4}
    >
      <Typography
        variant="h4"
        style={{
          fontFamily: theme.typography.h3.fontFamily,
          fontWeight: theme.typography.h3.fontWeight,
          fontSize: theme.typography.h3.fontSize,
          color: theme.palette.text.primary,
        }}
      >
        Welcome to{" "}
        <span style={{ color: theme.palette.primary.main }}>
          Embed Security!
        </span>
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontFamily: theme.typography.fontFamily,
          fontWeight: 500,
          fontSize: "14px",
          marginTop: "10px",
          color: theme.palette.grey[500],
        }}
      >
        Welcome to Embed Security, we're excited you're here. Using our Security
        Noise Cancellation(tm), you'll autonomously spotlight what's real and
        enable your team respond faster. Let's make alert fatigue a thing of the
        past!
      </Typography>
      <img
        src={onboardImage2}
        alt="Onboarding Dashboard"
        style={{ width: "130%", marginTop: "20px" }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onNext}
        sx={{ mt: 5, px: 3, py: 1 }}
      >
        Get Started
      </Button>
    </Box>
  );
};

export default WelcomeStep;
