import { combineReducers } from "@reduxjs/toolkit";
import casesReducer from "../features/cases/redux/casesSlice";
import authReducer from "../features/auth/redux/userSlice"; // for authentication state
import caseDetailsReducer from "../features/case-details/redux/caseDetailsSlice";
import caseNavigationReducer from "../features/case-navigation/redux/caseNavigationSlice";
import onboardingReducer from "../features/onboarding/redux/onboardingSlice";
import userManagementReducer from "../features/customer-admin/redux/userManagementSlice"; // for user-management slice

const rootReducer = combineReducers({
  cases: casesReducer, // Manages the case-related state
  auth: authReducer, // Manages the authentication state
  caseDetails: caseDetailsReducer, // Manages the case details state
  caseNavigation: caseNavigationReducer, // Manages case navigation on details page
  onboarding: onboardingReducer, // Manages onboarding experience with user
  users: userManagementReducer, // Manages user-related & admin control state
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
