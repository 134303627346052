import React, { useState, useEffect, useCallback } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Typography,
  Box,
  IconButton,
  Checkbox,
  Menu,
  MenuItem,
  Tooltip,
  SxProps,
  Theme,
  Collapse,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";

// dnd-kit
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  arrayMove,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Case } from "../types/types";
import ConclusionIndicator from "./ConclusionIndicator";
import IStepsDisplay from "./IStepsDisplay";
import FeedbackDisplay from "./FeedbackDisplay";
import SeverityIndicatorDisplay from "./SeverityIndicatorDisplay";
import AlertTimeDisplay from "./AlertTimeDisplay";
import AttackSurfaceDisplay from "./AttackSurfaceDisplay";
import CaseTitleTooltip from "./CasesTitleTooltip";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// -- Text truncation w/ tooltip
const TruncatedWithTooltip: React.FC<{
  text?: string;
  maxLength?: number;
  textSx?: SxProps<Theme>;
}> = ({ text = "", maxLength = 10, textSx }) => {
  if (text.length <= maxLength) {
    return (
      <Typography variant="body2" sx={textSx}>
        {text}
      </Typography>
    );
  }
  const truncated = text.slice(0, maxLength) + "...";
  return (
    <Tooltip title={text}>
      <Typography variant="body2" sx={{ cursor: "normal", ...textSx }}>
        {truncated}
      </Typography>
    </Tooltip>
  );
};

// For full text w/ tooltip
const FullTextWithTooltip: React.FC<{
  text?: string;
  textSx?: SxProps<Theme>;
}> = ({ text = "", textSx }) => {
  return (
    <Tooltip title={text}>
      <Typography
        variant="body2"
        sx={{
          cursor: "normal",
          wordBreak: "break-word",
          whiteSpace: "normal",
          maxWidth: "100%",
          ...textSx,
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

interface ColumnConfig {
  key: keyof Case | "analyses";
  label: string;
  visible: boolean;
  sortable: boolean;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
}

interface Group {
  parent: Case; // newest item for that campaign
  children: Case[]; // the rest
  size: number; // parent + children length
  sortValue: string | number;
}

interface EnhancedCasesTableProps {
  cases: Case[];
  // Now we do *group-based* pagination:
  page: number;
  rowsPerPage: number;
  order: "asc" | "desc" | undefined;
  orderBy: keyof Case | undefined;
  setOrder: React.Dispatch<React.SetStateAction<"asc" | "desc" | undefined>>;
  setOrderBy: React.Dispatch<React.SetStateAction<keyof Case | undefined>>;
  onTotalGroupCountChange?: (count: number) => void; // <-- add this
}

const DEFAULT_COLUMNS: ColumnConfig[] = [
  { key: "caseId", label: "Name", visible: true, sortable: true },
  { key: "conclusion", label: "Conclusion", visible: true, sortable: true },
  { key: "analyses", label: "iSteps", visible: true, sortable: false },
  { key: "feedback", label: "Feedback", visible: true, sortable: true },
  { key: "severity", label: "Severity", visible: true, sortable: true },
  { key: "attacker", label: "Attacker", visible: true, sortable: true },
  { key: "target", label: "Target", visible: true, sortable: true },
  // { key: "campaignId", label: "Campaign", visible: true, sortable: true },
  { key: "createdAt", label: "Embed Rcvd", visible: true, sortable: true },
  { key: "alertTimestamp", label: "Alert Time", visible: true, sortable: true },
  {
    key: "attackSurface",
    label: "Attack Surface",
    visible: true,
    sortable: true,
  },
  { key: "mitreStage", label: "MITRE", visible: true, sortable: false },
];

// ====== Persist columns in localStorage
function usePersistedColumns(storageKey: string, defaultCols: ColumnConfig[]) {
  const [columns, setColumns] = useState<ColumnConfig[]>(() => {
    const saved = localStorage.getItem(storageKey);
    if (!saved) return defaultCols;

    let loaded: ColumnConfig[] = [];
    try {
      loaded = JSON.parse(saved) as ColumnConfig[];
    } catch {}
    // Filter out invalid keys
    loaded = loaded.filter((c) => defaultCols.some((d) => d.key === c.key));
    // Merge newly added
    const merged: ColumnConfig[] = [];
    for (const col of loaded) {
      const def = defaultCols.find((d) => d.key === col.key);
      if (def) merged.push({ ...def, ...col });
    }
    // Add any missing defaults
    for (const def of defaultCols) {
      if (!merged.some((m) => m.key === def.key)) merged.push(def);
    }
    return merged;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(columns));
  }, [columns, storageKey]);

  return [columns, setColumns] as const;
}

// 1) Expand/collapse storage
function loadExpandedCampaigns(): Set<string> {
  try {
    const data = localStorage.getItem("expandedCampaigns");
    if (!data) return new Set();
    const arr = JSON.parse(data);
    return new Set<string>(arr);
  } catch {
    return new Set();
  }
}

function saveExpandedCampaigns(setOfIds: Set<string>) {
  localStorage.setItem(
    "expandedCampaigns",
    JSON.stringify(Array.from(setOfIds))
  );
}

// ====== Sort Helpers
function getConclusionOrder(c: string) {
  switch (c) {
    case "Malicious":
      return 3;
    case "Suspicious":
      return 2;
    case "Benign":
    case "Malicious_Benign":
      return 1;
    default:
      return 0;
  }
}

function getSeverityOrder(s: string) {
  switch (s) {
    case "Critical":
      return 4;
    case "High":
      return 3;
    case "Medium":
      return 2;
    case "Low":
      return 1;
    default:
      return 0;
  }
}

/**
 * Return a single numeric/string value from parent's "orderBy"
 * so we can sort the group by that.
 */
function getSortValue(parent: Case, orderBy?: keyof Case): string | number {
  if (!orderBy) return "";
  const val = (parent as any)[orderBy];
  if (val == null) return "";

  switch (orderBy) {
    case "caseId":
      // Force a consistent string sort for the parent’s title
      return parent.title?.toLowerCase();
    case "conclusion":
      return getConclusionOrder(parent.conclusion);
    case "severity":
      return getSeverityOrder(parent.severity);
    case "createdAt":
    case "alertTimestamp":
      return new Date(val).getTime();
    default:
      if (typeof val === "number") return val;
      if (typeof val === "string") return val.toLowerCase();
      return "";
  }
}

/**
 * Build groups from all cases:
 *   - group by campaignId
 *   - newest item is "parent"
 *   - children are the rest
 *   - compute a sortValue from the parent's "orderBy"
 */
function buildGroups(
  cases: Case[],
  orderBy?: keyof Case,
  order?: "asc" | "desc"
): Group[] {
  const campaignMap = new Map<string, Case[]>();
  const noCampaign: Case[] = [];

  // Partition into campaign or none
  for (const c of cases) {
    const cid = c.campaignId?.trim();
    if (!cid) {
      noCampaign.push(c);
    } else {
      if (!campaignMap.has(cid)) campaignMap.set(cid, []);
      campaignMap.get(cid)!.push(c);
    }
  }

  // Sort each campaign group newest->oldest by createdAt
  for (const [cid, arr] of campaignMap) {
    arr.sort(
      (a, b) =>
        new Date(b.createdAt ?? 0).getTime() -
        new Date(a.createdAt ?? 0).getTime()
    );
  }
  // Also sort noCampaign the same way
  noCampaign.sort(
    (a, b) =>
      new Date(b.createdAt ?? 0).getTime() -
      new Date(a.createdAt ?? 0).getTime()
  );

  // Build an array of "groups"
  const groups: Group[] = [];
  // singletons for noCampaign
  for (const single of noCampaign) {
    groups.push({
      parent: single,
      children: [],
      size: 1,
      sortValue: getSortValue(single, orderBy),
    });
  }

  // For each campaign
  for (const [cid, arr] of campaignMap) {
    if (arr.length === 1) {
      groups.push({
        parent: arr[0],
        children: [],
        size: 1,
        sortValue: getSortValue(arr[0], orderBy),
      });
    } else {
      const parent = arr[0];
      const children = arr.slice(1);
      groups.push({
        parent,
        children,
        size: arr.length,
        sortValue: getSortValue(parent, orderBy),
      });
    }
  }

  // Now sort the groups by "sortValue" asc/desc
  groups.sort((a, b) => {
    if (a.sortValue < b.sortValue) return order === "asc" ? -1 : 1;
    if (a.sortValue > b.sortValue) return order === "asc" ? 1 : -1;
    // fallback => newest parent first
    const aTime = new Date(a.parent.createdAt ?? 0).getTime();
    const bTime = new Date(b.parent.createdAt ?? 0).getTime();
    return bTime - aTime;
  });

  return groups;
}

/**
 * Group-based pagination that counts each group as ONE visible row,
 * regardless of how many children it contains.
 * This ensures pagination shows the expected number of rows per page.
 */
function paginateGroups(allGroups: Group[], rowsPerPage: number): Group[][] {
  const pages: Group[][] = [];
  let currentPage: Group[] = [];
  let parentCount = 0;

  for (const g of allGroups) {
    if (parentCount >= rowsPerPage) {
      pages.push(currentPage);
      currentPage = [];
      parentCount = 0;
    }
    currentPage.push(g);
    parentCount++;
  }

  if (currentPage.length > 0) pages.push(currentPage);

  return pages;
}

// Single draggable/sortable header cell
function SortableHeaderCell({
  col,
  order,
  orderBy,
  onSort,
  visibleIndex,
  onResize,
}: {
  col: ColumnConfig;
  order: "asc" | "desc" | undefined;
  orderBy: keyof Case | undefined;
  onSort: (colKey: keyof Case | "analyses") => void;
  visibleIndex: number;
  onResize: (e: React.MouseEvent<HTMLDivElement>, visibleIdx: number) => void;
}) {
  const theme = useTheme();
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: String(col.key) });
  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 999 : "auto",
    cursor: "grab",
  };

  const isSorted = orderBy === col.key;
  const showSortArrow = col.sortable && isSorted;

  const isLightMode = theme.palette.mode === "light";
  const headerCellStyles: React.CSSProperties = {
    padding: "10px 6px",
    backgroundColor: isLightMode
      ? theme.palette.common.white
      : theme.palette.background.default,
    color: theme.palette.grey[500],
    fontWeight: "bold",
    borderBottom: isLightMode
      ? `2px solid ${theme.palette.grey[200]}`
      : `2px solid ${theme.palette.grey[400]}`,
    width: col.width ?? 120,
    whiteSpace: "nowrap",
  };

  const tableTextSx = {
    fontSize: "0.875rem",
    [theme.breakpoints.down("xl")]: {
      lineHeight: 1.3,
      fontSize: "0.65rem",
    },
  };

  const handleClick = () => {
    if (col.sortable) {
      onSort(col.key);
    }
  };

  return (
    <TableCell
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{ ...headerCellStyles, ...style }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {col.sortable ? (
          <TableSortLabel
            active={showSortArrow}
            direction={isSorted ? order || "asc" : "asc"}
            onClick={handleClick}
          >
            {col.label}
          </TableSortLabel>
        ) : (
          <Typography
            onClick={handleClick}
            component="span"
            sx={{ fontSize: tableTextSx, fontWeight: "bold" }}
          >
            {col.label}
          </Typography>
        )}
        <Box
          onMouseDown={(e) => onResize(e, visibleIndex)}
          sx={{
            width: "4px",
            cursor: "col-resize",
            height: "100%",
            ml: 0.5,
          }}
        />
      </Box>
    </TableCell>
  );
}

const EnhancedCasesTable: React.FC<EnhancedCasesTableProps> = ({
  cases,
  page,
  rowsPerPage,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  onTotalGroupCountChange,
}) => {
  const theme = useTheme();
  const isLightMode = theme.palette.mode === "light";

  // 1) build groups in sorted order
  const allGroups = React.useMemo(() => {
    return buildGroups(cases, orderBy, order);
  }, [cases, orderBy, order]);

  // 2) group-based pagination => array of pages => each page is array of Groups
  const pagedGroups = React.useMemo(() => {
    return paginateGroups(allGroups, rowsPerPage);
  }, [allGroups, rowsPerPage]);

  // If "page" exceeds the number of available pages, we clamp
  const totalPages = pagedGroups.length;
  const safePage = Math.max(1, Math.min(page, totalPages || 1));
  // The groups for our current page
  const currentGroups = React.useMemo(() => {
    return totalPages > 0 ? pagedGroups[safePage - 1] : [];
  }, [totalPages, pagedGroups, safePage]);

  // 4) Flatten them (decide if you want the entire table or just the page) (nav purposes)
  const flattenedCurrentCases = React.useMemo(() => {
    const result: Case[] = [];
    for (const grp of currentGroups) {
      result.push(grp.parent);
      // Add children if you want them in the nav
      result.push(...grp.children);
    }
    return result;
  }, [currentGroups]);

  useEffect(() => {
    // Notify the parent how many total grouped rows exist
    if (onTotalGroupCountChange) {
      onTotalGroupCountChange(allGroups.length);
    }
  }, [allGroups, onTotalGroupCountChange]);

  // For the table body, we flatten the current page’s groups =>
  //   each group => parent row => optional children
  // We won't do normal item-based pagination. We do entire group.

  // Manage columns
  const [columns, setColumns] = usePersistedColumns(
    "MyTableColumns",
    DEFAULT_COLUMNS
  );

  // Expand state for each campaign
  const [expandedCampaigns, setExpandedCampaigns] = useState<Set<string>>(() =>
    loadExpandedCampaigns()
  );

  useEffect(() => {
    saveExpandedCampaigns(expandedCampaigns);
  }, [expandedCampaigns]);

  // Column menu
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) =>
    setMenuAnchor(e.currentTarget);
  const handleCloseMenu = () => setMenuAnchor(null);

  // "Show All" / "Hide All"
  const allVisible = columns.every((c) => c.visible);
  const handleToggleVisibility = (key: string) => {
    setColumns((prev) =>
      prev.map((col) =>
        col.key === key ? { ...col, visible: !col.visible } : col
      )
    );
  };

  // Drag sensors
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 15 },
  });
  const sensors = useSensors(pointerSensor);

  // On drag end => reorder columns
  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    if (!over || active.id === over.id) return;

    setColumns((prev) => {
      const visibleCols = prev.filter((c) => c.visible);
      const oldIdx = visibleCols.findIndex((c) => String(c.key) === active.id);
      const newIdx = visibleCols.findIndex((c) => String(c.key) === over.id);
      if (oldIdx < 0 || newIdx < 0) return prev;
      const reordered = arrayMove(visibleCols, oldIdx, newIdx);

      const finalArr: ColumnConfig[] = [];
      let i = 0;
      for (const col of prev) {
        if (!col.visible) finalArr.push(col);
        else {
          finalArr.push(reordered[i]);
          i++;
        }
      }
      return finalArr;
    });
  };

  // Resizing
  const handleMouseDownResize = (
    e: React.MouseEvent<HTMLDivElement>,
    visibleIndex: number
  ) => {
    e.preventDefault();
    const startX = e.clientX;
    const visibleCols = columns.filter((c) => c.visible);
    const startWidth = visibleCols[visibleIndex].width ?? 120;

    const onMouseMove = (moveEvt: MouseEvent) => {
      const deltaX = moveEvt.clientX - startX;
      setColumns((prev) => {
        const vCols = prev.filter((c) => c.visible);
        const updated = [...vCols];
        updated[visibleIndex] = {
          ...updated[visibleIndex],
          width: Math.max(50, startWidth + deltaX),
        };
        const finalArr: ColumnConfig[] = [];
        let i = 0;
        for (const col of prev) {
          if (!col.visible) finalArr.push(col);
          else {
            finalArr.push(updated[i]);
            i++;
          }
        }
        return finalArr;
      });
    };
    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  // Column sorting
  const handleSort = (colKey: keyof Case | "analyses") => {
    if (colKey === "analyses") return;
    const isAsc = orderBy === colKey && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(colKey);
  };

  // Rendering cell content
  const tableTextSx = {
    fontSize: "0.875rem",
    [theme.breakpoints.down("xl")]: {
      lineHeight: 1.3,
      fontSize: "0.65rem",
    },
  };
  const bodyCellStyles: React.CSSProperties = {
    padding: "20px 6px",
    borderBottom: "none",
    color: theme.palette.text.primary,
  };

  const renderCell = useCallback(
    (caseItem: Case, col: ColumnConfig) => {
      switch (col.key) {
        case "caseId":
          return (
            <CaseTitleTooltip
              caseItem={caseItem}
              // We only have the current page’s groups => flatten them so
              // it still works w/ the "bulk nav"?
              // pass an array of parent+children or just pass the entire set if you want
              filteredCases={flattenedCurrentCases}
              handleCopyId={(id) => navigator.clipboard.writeText(id)}
              textSx={tableTextSx}
            />
          );
        case "conclusion": {
          const display =
            caseItem.conclusion === "Malicious_Benign"
              ? "Malicious-NoAction"
              : caseItem.conclusion;
          return (
            <ConclusionIndicator conclusion={display} textSx={tableTextSx} />
          );
        }
        case "analyses":
          return <IStepsDisplay analyses={caseItem.analyses} />;
        case "feedback": {
          const fb = caseItem.feedback?.toLowerCase();
          if (fb === "agree" || fb === "disagree") {
            return (
              <FeedbackDisplay
                feedback={caseItem.feedback}
                reason={caseItem.feedbackReason}
                textSx={tableTextSx}
              />
            );
          }
          return <Typography variant="body2">{caseItem.feedback}</Typography>;
        }
        case "severity":
          return (
            <SeverityIndicatorDisplay
              severity={caseItem.severity}
              textSx={tableTextSx}
            />
          );
        case "campaignId":
          return (
            <Typography variant="body2" sx={tableTextSx}>
              {caseItem.campaignId}
            </Typography>
          );
        case "createdAt":
          return (
            <AlertTimeDisplay
              timestamp={caseItem.createdAt}
              textSx={tableTextSx}
            />
          );
        case "alertTimestamp":
          return (
            <AlertTimeDisplay
              timestamp={caseItem.alertTimestamp}
              textSx={tableTextSx}
            />
          );
        case "attacker":
          return (
            <FullTextWithTooltip
              text={caseItem.attacker ?? ""}
              textSx={tableTextSx}
            />
          );
        case "target":
          return (
            <FullTextWithTooltip
              text={caseItem.target ?? ""}
              textSx={tableTextSx}
            />
          );
        case "attackSurface":
          return (
            <AttackSurfaceDisplay
              caseType={caseItem.attackSurface}
              textSx={tableTextSx}
            />
          );
        case "mitreStage":
          return (
            <Typography variant="body2" sx={tableTextSx}>
              {caseItem.mitreStage}
            </Typography>
          );

        default:
          return (caseItem as any)[col.key];
      }
    },
    [cases, tableTextSx]
  );

  return (
    <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={columns.filter((c) => c.visible).map((c) => String(c.key))}
          strategy={horizontalListSortingStrategy}
        >
          <Table
            stickyHeader
            sx={{
              "& thead th": {
                fontSize: "0.8rem",
                fontWeight: "bold",
                [theme.breakpoints.down("xl")]: {
                  fontSize: "0.55rem",
                  lineHeight: 1.3,
                },
              },
              "& tbody td": {
                fontSize: "0.875rem",
                [theme.breakpoints.down("xl")]: {
                  fontSize: "0.55rem",
                  lineHeight: 1.3,
                },
              },
            }}
          >
            {/* TABLE HEAD */}
            <TableHead>
              <TableRow>
                {/* Expand arrow column */}
                <TableCell
                  sx={{
                    width: 28,
                    minWidth: 28,
                    maxWidth: 28,
                    backgroundColor: isLightMode
                      ? theme.palette.common.white
                      : theme.palette.background.default,
                    borderBottom: `2px solid ${
                      isLightMode
                        ? theme.palette.grey[200]
                        : theme.palette.grey[400]
                    }`,
                  }}
                />
                {/* Draggable columns */}
                {columns
                  .filter((c) => c.visible)
                  .map((col, index) => (
                    <SortableHeaderCell
                      key={col.key}
                      col={col}
                      order={order}
                      orderBy={orderBy}
                      visibleIndex={index}
                      onSort={handleSort}
                      onResize={handleMouseDownResize}
                    />
                  ))}
                {/* Menu col */}
                <TableCell
                  sx={{
                    width: 32,
                    minWidth: 32,
                    maxWidth: 32,
                    padding: "0px 0px",
                    backgroundColor: isLightMode
                      ? theme.palette.common.white
                      : theme.palette.background.default,
                    color: theme.palette.grey[500],
                    fontWeight: "bold",
                    borderBottom: `2px solid ${
                      isLightMode
                        ? theme.palette.grey[200]
                        : theme.palette.grey[400]
                    }`,
                    textAlign: "center",
                  }}
                >
                  <IconButton onClick={handleOpenMenu} size="small">
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    onClose={handleCloseMenu}
                  >
                    {/* Show/Hide all */}
                    <MenuItem
                      onClick={() => {
                        const allVis = columns.every((c) => c.visible);
                        setColumns((prev) =>
                          prev.map((col) => ({ ...col, visible: !allVis }))
                        );
                        handleCloseMenu();
                      }}
                    >
                      <Checkbox
                        checked={allVisible}
                        indeterminate={
                          !columns.every((c) => c.visible) &&
                          !columns.every((c) => !c.visible)
                        }
                        color="secondary"
                      />
                      <Typography>
                        {allVisible ? "Hide All" : "Show All"}
                      </Typography>
                    </MenuItem>
                    <MenuItem divider sx={{ py: 0.5 }} />
                    {columns.map((col) => (
                      <MenuItem key={String(col.key)}>
                        <Checkbox
                          checked={col.visible}
                          onChange={() =>
                            handleToggleVisibility(String(col.key))
                          }
                        />
                        <Typography>{col.label}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            </TableHead>

            {/* TABLE BODY */}
            <TableBody>
              {currentGroups.map((grp) => {
                if (grp.children.length === 0) {
                  // single row => no arrow
                  return (
                    <TableRow key={grp.parent.caseId}>
                      <TableCell sx={{ width: 28, borderBottom: "none" }} />
                      {columns
                        .filter((c) => c.visible)
                        .map((col) => (
                          <TableCell
                            key={col.key}
                            style={{
                              ...bodyCellStyles,
                              width: col.width ?? 120,
                            }}
                          >
                            {renderCell(grp.parent, col)}
                          </TableCell>
                        ))}
                      <TableCell sx={{ width: 32, borderBottom: "none" }} />
                    </TableRow>
                  );
                } else {
                  // group row => 1 parent + n children
                  const cid = grp.parent.campaignId?.trim() || "";
                  const expanded = expandedCampaigns.has(cid);

                  return (
                    <React.Fragment key={grp.parent.caseId}>
                      {/* Parent row */}
                      <TableRow
                        sx={{
                          backgroundColor: isLightMode
                            ? theme.palette.grey[50]
                            : theme.palette.grey[800],
                          "& td": {
                            borderBottom: expanded
                              ? "none"
                              : `1px solid ${theme.palette.divider}`,
                            "&:first-of-type": {
                              borderLeft: `3px solid ${theme.palette.primary.light}`,
                            },
                          },
                          fontWeight: 500,
                          "&:hover": {
                            backgroundColor: isLightMode
                              ? theme.palette.grey[100]
                              : theme.palette.grey[800],
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            width: 28,
                            borderBottom: "none",
                            padding: "2px",
                          }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              setExpandedCampaigns((prev) => {
                                const copy = new Set(prev);
                                if (copy.has(cid)) copy.delete(cid);
                                else copy.add(cid);
                                return copy;
                              });
                            }}
                          >
                            {expanded ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        {columns
                          .filter((c) => c.visible)
                          .map((col) => (
                            <TableCell
                              key={col.key}
                              style={{
                                ...bodyCellStyles,
                                width: col.width ?? 120,
                              }}
                            >
                              {renderCell(grp.parent, col)}
                            </TableCell>
                          ))}
                        <TableCell sx={{ width: 32, borderBottom: "none" }} />
                      </TableRow>

                      {expanded && (
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={
                              columns.filter((c) => c.visible).length + 2
                            }
                          >
                            <Collapse
                              in={expanded}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box
                                sx={{
                                  maxHeight: 200,
                                  overflowY: "auto",
                                  mt: 1,
                                  ml: 5,
                                  borderLeft: `2px solid ${theme.palette.divider}`,
                                }}
                              >
                                <Table size="small">
                                  <TableBody>
                                    {grp.children.map((child) => (
                                      <TableRow key={child.caseId}>
                                        {columns
                                          .filter((c) => c.visible)
                                          .map((col) => (
                                            <TableCell
                                              key={col.key}
                                              sx={{
                                                ...bodyCellStyles,
                                                borderBottom: "1px solid",
                                                borderColor:
                                                  theme.palette.divider,
                                              }}
                                              style={{
                                                width: col.width ?? 120,
                                              }}
                                            >
                                              {renderCell(child, col)}
                                            </TableCell>
                                          ))}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </SortableContext>
      </DndContext>
    </TableContainer>
  );
};

export default EnhancedCasesTable;
