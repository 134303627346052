import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchUsers,
  inviteUser,
  deleteUser,
  addAdminRole,
  removeAdminRole,
} from "../../../api/userManagementApi";
import { Auth0OrganizationUser, Auth0Role } from "../../auth/types/user-types";

interface UsersState {
  users: Auth0OrganizationUser[];
  initialLoading: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: UsersState = {
  users: [],
  initialLoading: false,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // 1) FETCH USERS
    builder.addCase(fetchUsers.pending, (state) => {
      state.initialLoading = true;
      state.error = null;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.initialLoading = false;
      state.users = action.payload; // array of Auth0OrganizationUser
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.initialLoading = false;
      state.error = action.payload || "Failed to fetch users";
    });

    // 2) INVITE USER
    builder.addCase(inviteUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(inviteUser.fulfilled, (state, action) => {
      state.loading = false;
      // The API returned an Invitation object. We might not have user info yet.
      // Typically you'd re-fetch, or store some "invite success" message.
      // For example:
      // state.lastInvitation = action.payload; // if you want to store it
    });
    builder.addCase(inviteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Failed to invite user";
    });

    // 3) DELETE USER
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.loading = false;
      // action.payload should be userId
      const userIdToRemove = action.payload;
      state.users = state.users.filter((u) => u.user_id !== userIdToRemove);
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Failed to delete user";
    });

    // 4) ADD ADMIN ROLE
    builder.addCase(addAdminRole.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addAdminRole.fulfilled, (state, action) => {
      state.loading = false;
      // action.payload = { userId }
      const { userId } = action.payload;
      // We can update the user's roles in state:
      const user = state.users.find((u) => u.user_id === userId);
      if (user) {
        // If the user object doesn't have "roles" array yet, ensure it does
        if (!user.roles) {
          user.roles = [];
        }
        // If not already admin, push an admin role
        const isAlreadyAdmin = user.roles.some((r) => r.name === "admin");
        if (!isAlreadyAdmin) {
          user.roles.push({ id: "admin", name: "admin" } as Auth0Role);
        }
      }
    });
    builder.addCase(addAdminRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Failed to add admin role";
    });

    // 5) REMOVE ADMIN ROLE
    builder.addCase(removeAdminRole.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(removeAdminRole.fulfilled, (state, action) => {
      state.loading = false;
      // action.payload = { userId }
      const { userId } = action.payload;
      const user = state.users.find((u) => u.user_id === userId);
      if (user && user.roles) {
        user.roles = user.roles.filter((r) => r.name !== "admin");
      }
    });
    builder.addCase(removeAdminRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Failed to remove admin role";
    });
  },
});

export default userSlice.reducer;
