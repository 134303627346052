import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  alpha,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  IconButton,
  Avatar,
  DialogContentText,
  CircularProgress,
  Snackbar,
  Alert,
  TableSortLabel,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fetchUsers,
  inviteUser,
  deleteUser,
  addAdminRole,
  removeAdminRole,
} from "../../../api/userManagementApi";

import { Auth0OrganizationUser, Auth0Role } from "../../auth/types/user-types";

type Order = "asc" | "desc";
type OrderByField = "name" | "email";

const UserManagement: React.FC = () => {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();

  const dispatch = useAppDispatch();
  const { users, initialLoading, error } = useAppSelector(
    (state) => state.users
  );

  // Local state for invite, delete, admin modals
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [inviteLoading, setInviteLoading] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [adminDialogOpen, setAdminDialogOpen] = useState(false);
  const [adminRemoveLoading, setAdminRemoveLoading] = useState(false);

  const [selectedUser, setSelectedUser] =
    useState<Auth0OrganizationUser | null>(null);

  // Snackbar for success/error messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("info");

  // ---- Sorting state ----
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<OrderByField>("name");

  // --------------------------------------------------
  // Fetch all users on mount
  // --------------------------------------------------
  useEffect(() => {
    const doFetchUsers = async () => {
      try {
        const token = await getAccessTokenSilently();
        await dispatch(fetchUsers({ accessToken: token })).unwrap();
      } catch (err: any) {
        console.error("Error retrieving token or fetching users:", err);
        showSnackbar(err.message || "Failed to fetch users", "error");
      }
    };
    doFetchUsers();
  }, [dispatch, getAccessTokenSilently]);

  // --------------------------------------------------
  // Helpers
  // --------------------------------------------------
  const showSnackbar = (
    message: string,
    severity: "success" | "error" | "warning" | "info"
  ) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+\-']+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // ** Sorting utility functions **

  /**
   * Compare two users by name or email in descending order
   */
  function descendingComparator(
    a: Auth0OrganizationUser,
    b: Auth0OrganizationUser,
    field: OrderByField
  ) {
    // Access either "name" or "email"
    const valA = (a[field] || "").toLowerCase();
    const valB = (b[field] || "").toLowerCase();

    if (valB < valA) return -1;
    if (valB > valA) return 1;
    return 0;
  }

  function getComparator(order: Order, field: OrderByField) {
    return order === "desc"
      ? (a: Auth0OrganizationUser, b: Auth0OrganizationUser) =>
          descendingComparator(a, b, field)
      : (a: Auth0OrganizationUser, b: Auth0OrganizationUser) =>
          -descendingComparator(a, b, field);
  }

  /**
   * Implementation of stable sort
   */
  function stableSort(array: Auth0OrganizationUser[], comparator: Function) {
    const stabilized = array.map<[Auth0OrganizationUser, number]>((el, idx) => [
      el,
      idx,
    ]);
    stabilized.sort((a, b) => {
      const orderVal = comparator(a[0], b[0]);
      if (orderVal !== 0) return orderVal;
      return a[1] - b[1];
    });
    return stabilized.map((el) => el[0]);
  }

  const sortedUsers = stableSort(users, getComparator(order, orderBy));

  /**
   * Handle sort requests. If clicked field is the same, toggle asc/desc,
   * otherwise switch to new field with ascending order.
   */
  const handleRequestSort = (field: OrderByField) => {
    if (orderBy === field) {
      // just toggle asc/desc
      setOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      // new field, default to asc
      setOrder("asc");
      setOrderBy(field);
    }
  };

  // --------------------------------------------------
  // Invite Users
  // --------------------------------------------------
  const handleInviteClick = () => {
    setInviteModalOpen(true);
  };
  const handleInviteClose = () => {
    setInviteModalOpen(false);
    setInviteEmail("");
    setEmailError("");
  };

  const handleInviteSubmit = async () => {
    if (!validateEmail(inviteEmail)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setInviteLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await dispatch(
        inviteUser({ accessToken: token, inviteeEmail: inviteEmail })
      ).unwrap();
      showSnackbar(`Invitation sent to ${inviteEmail}`, "info");
      handleInviteClose();
      dispatch(fetchUsers({ accessToken: token }));
    } catch (err: any) {
      console.error("Failed to invite user:", err);
      showSnackbar(err.message || "Failed to invite user", "error");
    } finally {
      setInviteLoading(false);
    }
  };

  // --------------------------------------------------
  // Delete Users
  // --------------------------------------------------
  const handleDeleteClick = (user: Auth0OrganizationUser) => {
    setSelectedUser(user);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedUser) return;
    setDeleteLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await dispatch(
        deleteUser({ accessToken: token, userId: selectedUser.user_id })
      ).unwrap();
      showSnackbar(`Removed user: ${selectedUser.name}`, "info");
      setDeleteDialogOpen(false);
      setSelectedUser(null);
    } catch (err: any) {
      console.error("Failed to delete user:", err);
      showSnackbar(err.message || "Failed to delete user", "error");
    } finally {
      setDeleteLoading(false);
    }
  };

  // --------------------------------------------------
  // Toggle Admin Role
  // --------------------------------------------------
  const handleAdminChange = async (user: Auth0OrganizationUser) => {
    const isAdmin = user.roles?.some((role) => role.name === "admin");
    setSelectedUser(user);

    if (isAdmin) {
      // remove admin => confirm
      setAdminDialogOpen(true);
    } else {
      // add admin => direct
      try {
        const token = await getAccessTokenSilently();
        await dispatch(
          addAdminRole({ accessToken: token, userId: user.user_id })
        ).unwrap();
        showSnackbar(`Granted admin privileges to ${user.name}`, "info");
      } catch (err: any) {
        console.error("Failed to add admin role:", err);
        showSnackbar(err.message || "Failed to add admin role", "error");
      }
    }
  };

  const handleAdminConfirm = async () => {
    if (!selectedUser) return;
    setAdminRemoveLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await dispatch(
        removeAdminRole({ accessToken: token, userId: selectedUser.user_id })
      ).unwrap();
      showSnackbar(
        `Removed admin privileges from ${selectedUser.name}`,
        "info"
      );
      setAdminDialogOpen(false);
      setSelectedUser(null);
    } catch (err: any) {
      console.error("Failed to remove admin role:", err);
      showSnackbar(err.message || "Failed to remove admin role", "error");
    } finally {
      setAdminRemoveLoading(false);
    }
  };

  // --------------------------------------------------
  // Render: Loading or Error for initial fetch
  // --------------------------------------------------
  if (initialLoading) {
    return (
      <Box
        sx={{
          p: 5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
        <Typography>Loading users...</Typography>
      </Box>
    );
  }
  if (error) {
    return (
      <Box
        sx={{
          p: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  // --------------------------------------------------
  // Main Render
  // --------------------------------------------------
  return (
    <Box sx={{ p: 3 }}>
      {/* Header: Title & Invite Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          User Management
        </Typography>
        <Button variant="contained" color="primary" onClick={handleInviteClick}>
          Invite Users
        </Button>
      </Box>

      {/* Table */}
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: theme.shadows[1],
          borderRadius: "8px",
        }}
      >
        <TableContainer>
          <Table
            size="small"
            sx={{
              "& td, & th": {
                whiteSpace: "nowrap",
                padding: theme.spacing(1.25, 2),
              },
              "& tbody tr:nth-of-type(even)": {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? alpha(theme.palette.grey[300], 0.08)
                    : alpha(theme.palette.grey[200], 0.25),
              },
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor:
                    theme.palette.mode === "dark"
                      ? alpha(theme.palette.grey[400], 0.2)
                      : theme.palette.grey[50],
                }}
              >
                {/* NAME COLUMN */}
                <TableCell
                  sx={{ fontWeight: 600 }}
                  sortDirection={orderBy === "name" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={orderBy === "name" ? order : "asc"}
                    onClick={() => handleRequestSort("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>

                {/* EMAIL COLUMN */}
                <TableCell
                  sx={{ fontWeight: 600 }}
                  sortDirection={orderBy === "email" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "email"}
                    direction={orderBy === "email" ? order : "asc"}
                    onClick={() => handleRequestSort("email")}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>

                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  Admin
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  Delete User
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedUsers.map((user: Auth0OrganizationUser) => (
                <TableRow
                  key={user.user_id}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? alpha(theme.palette.grey[100], 0.05)
                          : alpha(theme.palette.grey[100], 0.2),
                    },
                  }}
                >
                  {/* NAME + AVATAR */}
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Avatar
                        src={user.picture}
                        alt={user.name}
                        sx={{ width: 32, height: 32 }}
                      />
                      {user.name}
                    </Box>
                  </TableCell>

                  {/* EMAIL */}
                  <TableCell>{user.email}</TableCell>

                  {/* ADMIN CHECKBOX */}
                  <TableCell align="center">
                    <Checkbox
                      checked={user.roles?.some(
                        (role: Auth0Role) => role.name === "admin"
                      )}
                      onChange={() => handleAdminChange(user)}
                      color="primary"
                    />
                  </TableCell>

                  {/* DELETE ICON */}
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation(); // prevent row click
                        handleDeleteClick(user);
                      }}
                      sx={{ color: theme.palette.error.main }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* --- INVITE USER DIALOG --- */}
      <Dialog
        open={inviteModalOpen}
        onClose={handleInviteClose}
        PaperProps={{
          sx: { minWidth: 450 },
        }}
      >
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={inviteEmail}
            onChange={(e) => {
              setInviteEmail(e.target.value);
              setEmailError("");
            }}
            error={Boolean(emailError)}
            helperText={emailError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInviteClose}>Cancel</Button>
          <Button
            onClick={handleInviteSubmit}
            variant="contained"
            color="primary"
            startIcon={
              inviteLoading ? (
                <CircularProgress size="1rem" color="inherit" />
              ) : null
            }
            disabled={!Boolean(validateEmail(inviteEmail)) || inviteLoading}
          >
            {inviteLoading ? "Sending..." : "Send Invitation"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* --- DELETE USER CONFIRMATION --- */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Remove User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove {selectedUser?.name} from your
            organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            startIcon={
              deleteLoading ? (
                <CircularProgress size="1rem" color="inherit" />
              ) : null
            }
            disabled={deleteLoading}
          >
            {deleteLoading ? "Removing..." : "Yes, Remove"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* --- REMOVE ADMIN CONFIRMATION --- */}
      <Dialog open={adminDialogOpen} onClose={() => setAdminDialogOpen(false)}>
        <DialogTitle>Remove Admin Role</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove admin privileges from{" "}
            {selectedUser?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAdminDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleAdminConfirm}
            color="error"
            variant="contained"
            startIcon={
              adminRemoveLoading ? (
                <CircularProgress size="1rem" color="inherit" />
              ) : null
            }
            disabled={adminRemoveLoading}
          >
            {adminRemoveLoading ? "Removing..." : "Yes, Remove Admin"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* --- SNACKBAR FOR NOTIFICATIONS --- */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserManagement;
