import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface CaseInfoSectionProps {
  device: string;
  from?: string;
  attacker?: string;
  target?: string;
  attackSurface: string;
}

const CaseInfoSection: React.FC<CaseInfoSectionProps> = ({
  device,
  from,
  attacker,
  target,
  attackSurface,
}) => {
  const theme = useTheme();

  // Decide if label is “From” or “Attacker.”
  const attackerLabel = attackSurface === "Email" ? "From" : "Attacker";
  const attackerValue = attackSurface === "Email" ? from : attacker;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(4),
      }}
    >
      {/* DEVICE SECTION */}
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <EmailIcon sx={{ color: theme.palette.grey[500], fontSize: 20 }} />
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Device
          </Typography>
          <Typography variant="body2">{device}</Typography>
        </Box>
      </Box>

      {/* ATTACKER (“FROM”) SECTION */}
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.error.light,
          }}
        >
          <PermIdentityIcon
            sx={{ color: theme.palette.error.dark, fontSize: 20 }}
          />
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {attackerLabel}
          </Typography>
          <Typography variant="body2">{attackerValue || "—"}</Typography>
        </Box>
      </Box>

      {/* ARROW BETWEEN */}
      <ArrowForwardIcon
        sx={{
          color: theme.palette.primary.main,
          fontSize: 24,
        }}
      />

      {/* TARGET SECTION */}
      <Box
        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
      >
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <GpsFixedIcon sx={{ color: theme.palette.grey[500], fontSize: 20 }} />
        </Box>
        <Box>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            Target
          </Typography>
          <Typography variant="body2">{target || "—"}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseInfoSection;
