import React, { useContext } from "react";
import { Box, IconButton, Menu, MenuItem, Tooltip, Zoom } from "@mui/material";
import {
  Brightness4,
  Brightness7,
  Palette,
  Contrast,
} from "@mui/icons-material";
import { CustomThemeContext } from "../../context/ThemeContext";

const THEME_OPTIONS = [
  {
    key: "light",
    label: "Light Mode",
    icon: <Brightness4 />,
  },
  {
    key: "dark",
    label: "Dark Mode",
    icon: <Brightness7 />,
  },
  {
    key: "sepia",
    label: "Sepia Mode",
    icon: <Palette />,
  },
  {
    key: "high-contrast",
    label: "High Contrast",
    icon: <Contrast />,
  },
];

const ThemeToggle: React.FC = () => {
  const { currentTheme, setTheme } = useContext(CustomThemeContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleThemeChange = (theme: string) => {
    setTheme(theme as any);
    handleClose();
  };

  return (
    <Box
      display="flex"
      gap={1}
      justifyContent="left"
      alignItems="center"
      // Feel free to adjust margins as needed
    >
      {THEME_OPTIONS.map((themeOption, index) => {
        const { key, label, icon } = themeOption;
        // Decide if this theme is the active one
        const isActive = currentTheme === key;

        return (
          <Zoom // or <Grow in>
            in={true}
            style={{ transitionDelay: `${index * 75}ms` }}
            key={key}
          >
            <Tooltip title={label} arrow>
              <IconButton
                onClick={() => handleThemeChange(key)}
                color={isActive ? "primary" : "default"}
                // Example subtle style highlight if active:
                sx={{
                  border: isActive ? "1px solid" : "none",
                  borderColor: "primary.main",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "scale(1.15)",
                  },
                }}
              >
                {icon}
              </IconButton>
            </Tooltip>
          </Zoom>
        );
      })}
    </Box>
  );
};

export default ThemeToggle;
