import React, { useEffect, useState } from "react";
import { Box, Collapse } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CaseTitleSection from "./CaseTitleSection";
import CaseInfoSection from "./CaseInfoSection";
import FeedbackSection from "./FeedbackSection";
import CaseDetailsSection from "./CaseDetailsSection";
import KeyEvidenceSection from "./KeyEvidenceSection";
import DescriptionSection from "./DescriptionSection";
import { CaseDetails } from "../types/caseDetailsTypes";
import { jwtDecode } from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";

interface CaseSummarySectionProps {
  caseNumber: string;
  caseTitle: string;
  caseConclusion: string;
  keyIndicators: string[];
  createdOn: string;
  attackSurface: string;
  alertTime: string;
  attacker?: string | null;
  target?: string | null;
  analyst: string;
  analystAvatar: string;
  status: string;
  severity: string;
  device: string;
  from: string;
  description: string;
  feedback: string | null;
  feedbackReason: string | null;
  feedbackLoading: boolean;
  feedbackError: string | null;
  isEditingFeedback: boolean;
  feedbackOption: string;
  feedbackText: string;
  onFeedbackOptionChange: (
    event: React.MouseEvent<HTMLElement>,
    newOption: string
  ) => void;
  onFeedbackTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmitFeedback: () => void;
  onAgree: (position: "agree", reason: string) => void;
  onDisagree: (position: "disagree", reason: string) => void;
  onEditFeedback: () => void;
  onCancelEdit: () => void;
  setFeedbackText: React.Dispatch<React.SetStateAction<string>>;
  setFeedbackOption: React.Dispatch<React.SetStateAction<string>>;
  onReprocessClick: (() => void) | undefined;
  reprocessLoading?: boolean;
  showSmeButton?: boolean;
  analyses: any;
  version: string;
  caseId: string;
  existingFeedback: any;
  caseDetail: CaseDetails;
  onEmailStatus: any;
  impact: string[];
  next_steps: string[];
  remediation: string[];
}

const CaseSummarySection: React.FC<CaseSummarySectionProps> = (props) => {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [enableSendEmailFlow, setEnableSendEmailFlow] = useState(false);

  // Local state to show/hide the description
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const decoded: any = jwtDecode(token) || {};

        // Check your for clientid and org
        const clientId = decoded["https://app.embedsecurity.com/client_id"];
        const orgId = decoded["https://app.embedsecurity.com/org_id"];

        // If either condition is true, enable flow
        if (
          clientId === "embedsecurity.com" ||
          clientId === "embedsecurity.ai" ||
          orgId === "org_VweyOG52AvCsnle0"
        ) {
          console.log("Enable send email flow");
          setEnableSendEmailFlow(true);
        }
      } catch (err) {
        console.error("Failed to decode token claims:", err);
      }
    })();
  }, [getAccessTokenSilently]);

  // Toggle handler for description
  const handleToggleDescription = () => {
    setShowDescription((prev) => !prev);
  };

  return (
    <Box sx={{ marginBottom: "2rem" }}>
      {/* Top Row: Title, Device, Attacker/From, Feedback */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: theme.spacing(4),
          }}
        >
          <CaseTitleSection
            caseTitle={props.caseTitle}
            caseConclusion={props.caseConclusion}
            onReprocessClick={props.onReprocessClick}
            reprocessLoading={props.reprocessLoading}
          />

          {/* Case Details */}
          <CaseDetailsSection
            createdOn={props.createdOn}
            alertTime={props.alertTime}
            analyst={""}
            analystAvatar={""}
            attackSurface={props.attackSurface}
          />
        </Box>

        <FeedbackSection
          feedback={props.feedback}
          feedbackReason={props.feedbackReason}
          feedbackLoading={props.feedbackLoading}
          feedbackError={props.feedbackError}
          isEditingFeedback={props.isEditingFeedback}
          feedbackOption={props.feedbackOption}
          feedbackText={props.feedbackText}
          onFeedbackOptionChange={props.onFeedbackOptionChange}
          onFeedbackTextChange={props.onFeedbackTextChange}
          onSubmitFeedback={props.onSubmitFeedback}
          onAgree={props.onAgree}
          onDisagree={props.onDisagree}
          onEditFeedback={props.onEditFeedback}
          onCancelEdit={props.onCancelEdit}
          setFeedbackText={props.setFeedbackText}
          setFeedbackOption={props.setFeedbackOption}
          showSmeButton={props.showSmeButton ?? false}
          analyses={props.analyses} // pass the entire array
          version={props.version}
          caseId={props.caseId}
          existingFeedback={props.existingFeedback}
          caseDetail={props.caseDetail}
          enableSendEmailFlow={enableSendEmailFlow} // TODO: We need to come with config/feature flag here
          onEmailStatus={props.onEmailStatus}
        />
      </Box>

      {/* CaseInfoSection */}
      <CaseInfoSection
        device={props.device}
        from={props.from}
        attacker={props.attacker || undefined}
        target={props.target || undefined}
        attackSurface={props.attackSurface}
      />

      {/* KeyEvidenceSection now includes Key Indicators vs Full Description,
          and Fix It vs Investigate More */}
      <Box sx={{ mt: 6 }}>
        <KeyEvidenceSection
          keyIndicators={props.keyIndicators}
          impact={props.impact}
          description={props.description}
          remediation={props.remediation}
          next_steps={props.next_steps}
        />
      </Box>
    </Box>
  );
};

export default CaseSummarySection;
