import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  useTheme,
  Paper,
  Typography,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField, // <-- import for the search box
} from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DownloadIcon from "@mui/icons-material/Download";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import InvestigativeStepsTabs from "./InvestigativeStepsTabs";
import ReactFlowSection from "./ReactFlowSection";

interface CloudFlowAndInvestigativeStepsProps {
  caseDetail: any;
  caseId: string;
  analyses: any;
  hasNavigation: boolean;
  canGoPrevious: boolean;
  canGoNext: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

// Artifact type
interface ArtifactContent {
  content: {
    EventTime: string;
    EventSource: string;
    EventName: string;
    ErrorCode?: string;
    [key: string]: any;
  };
  [key: string]: any;
}

const CloudFlowAndInvestigativeSteps: React.FC<
  CloudFlowAndInvestigativeStepsProps
> = ({
  caseDetail,
  caseId,
  analyses = [],
  hasNavigation,
  canGoPrevious,
  canGoNext,
  onPrevious,
  onNext,
}) => {
  const [selectedView, setSelectedView] = useState<"graph" | "rawlogs">(
    "graph"
  );
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();

  // Function to handle View Raw Logs button click
  const handleViewRawLogs = () => {
    setSelectedView("rawlogs");
  };

  // Extract and sort API call artifacts
  const apiCallLogs = useMemo(() => {
    if (!caseDetail?.artifacts) return [];

    const apiCallArtifacts = Object.entries(caseDetail.artifacts)
      .filter(([key]) => key.includes("apicall"))
      .map(([key, artifact]) => ({
        id: key,
        ...(artifact as ArtifactContent).content,
      }));

    return apiCallArtifacts.sort((a, b) => {
      const timeA = new Date(a.EventTime).getTime();
      const timeB = new Date(b.EventTime).getTime();
      return timeA - timeB;
    });
  }, [caseDetail?.artifacts]);

  // Filter logs by search term
  const filteredApiCallLogs = useMemo(() => {
    if (!searchTerm) {
      return apiCallLogs;
    }
    const lowerSearch = searchTerm.toLowerCase();
    return apiCallLogs.filter((log) => {
      // Convert the entire log object to a string, then check for the search term
      const logString = JSON.stringify(log).toLowerCase();
      return logString.includes(lowerSearch);
    });
  }, [apiCallLogs, searchTerm]);

  // Handle download of raw logs
  const handleDownloadLogs = useCallback(() => {
    if (!filteredApiCallLogs.length) return;

    const jsonContent = JSON.stringify(filteredApiCallLogs, null, 2);
    const blob = new Blob([jsonContent], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `api-logs-${caseId}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }, [filteredApiCallLogs, caseId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(72vh - 64px)",
        gap: "1rem",
        flexGrow: 1,
        mb: 1,
      }}
    >
      {/* Button Row */}
      <Box sx={{ display: "flex", gap: "1rem", marginBottom: "0.01rem" }}>
        {/* View Event Graph */}
        <Button
          onClick={() => setSelectedView("graph")}
          variant={selectedView === "graph" ? "contained" : "outlined"}
          startIcon={<AccountTreeIcon />}
          sx={{
            color:
              selectedView === "graph"
                ? theme.palette.common.white
                : theme.palette.grey[500],
            borderColor:
              selectedView === "graph"
                ? theme.palette.info.main
                : theme.palette.grey[500],
            backgroundColor:
              selectedView === "graph"
                ? theme.palette.info.main
                : "transparent",
            "&:hover": {
              backgroundColor:
                selectedView === "graph"
                  ? theme.palette.info.dark
                  : theme.palette.info.light,
              borderColor: theme.palette.info.dark,
            },
          }}
        >
          View Event Graph
        </Button>

        {/* View Raw Logs */}
        <Button
          onClick={handleViewRawLogs}
          variant={selectedView === "rawlogs" ? "contained" : "outlined"}
          startIcon={<DownloadIcon />}
          sx={{
            color:
              selectedView === "rawlogs"
                ? theme.palette.common.white
                : theme.palette.grey[500],
            borderColor:
              selectedView === "rawlogs"
                ? theme.palette.info.main
                : theme.palette.grey[500],
            backgroundColor:
              selectedView === "rawlogs"
                ? theme.palette.info.main
                : "transparent",
            "&:hover": {
              backgroundColor:
                selectedView === "rawlogs"
                  ? theme.palette.info.dark
                  : theme.palette.info.light,
              borderColor: theme.palette.info.dark,
            },
          }}
        >
          View Raw Logs
        </Button>

        {/* Navigation Buttons Group (pushed right) */}
        {hasNavigation && (
          <Box sx={{ marginLeft: "auto", display: "flex", gap: "0.5rem" }}>
            <Button
              variant="outlined"
              onClick={onPrevious}
              disabled={!canGoPrevious}
              sx={{
                minWidth: theme.spacing(12),
                color: theme.palette.text.secondary,
                borderColor: theme.palette.grey[500],
                "&:hover": {
                  borderColor: theme.palette.info.dark,
                },
              }}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              onClick={onNext}
              disabled={!canGoNext}
              sx={{
                minWidth: theme.spacing(12),
                color: theme.palette.text.secondary,
                borderColor: theme.palette.grey[500],
                "&:hover": {
                  borderColor: theme.palette.info.dark,
                },
              }}
            >
              Next
            </Button>
          </Box>
        )}
      </Box>

      {/* Main Content Row */}
      <Box sx={{ display: "flex", gap: "1rem", width: "100%", height: "100%" }}>
        {/* Main View */}
        <Box
          sx={{
            flexBasis: "70%",
            flexGrow: 1,
            height: "95%",
            position: "relative",
          }}
        >
          {selectedView === "graph" && (
            <ReactFlowSection
              data={caseDetail.graph}
              artifacts={caseDetail.artifacts}
              caseId={caseId}
            />
          )}

          {selectedView === "rawlogs" && (
            <Paper
              sx={{
                position: "relative",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                p: 2,
              }}
            >
              {/* Header Row with Title, Search, and Download */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" component="div">
                  CloudTrail Events ({filteredApiCallLogs.length})
                </Typography>

                {/* Search Box */}
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Search logs..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <IconButton
                    onClick={handleDownloadLogs}
                    disabled={!filteredApiCallLogs.length}
                    title="Download raw logs"
                    sx={{
                      color: theme.palette.info.main,
                      backgroundColor: theme.palette.background.paper,
                      boxShadow: theme.shadows[2],
                      "&:hover": {
                        backgroundColor: theme.palette.grey[100],
                        boxShadow: theme.shadows[6],
                      },
                      borderRadius: "4px",
                    }}
                    aria-label="Download raw logs"
                  >
                    <DownloadIcon />
                  </IconButton>
                </Box>
              </Box>

              {/* Table Container */}
              <TableContainer
                sx={{
                  flexGrow: 1,
                  overflow: "auto",
                  borderRadius: 1,
                  boxShadow: 3,
                  border: `1px solid ${theme.palette.divider}`,
                }}
              >
                <Table size="small" stickyHeader>
                  <TableHead
                    sx={{
                      backgroundColor: theme.palette.grey[200],
                      "& th": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{ width: "50px", fontWeight: "bold" }}
                      />
                      <TableCell>Event Time</TableCell>
                      <TableCell>Service</TableCell>
                      <TableCell>Event Name</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    sx={{
                      // Zebra striping
                      "& > tr:nth-of-type(odd)": {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    {/* If no filtered results, show a single row to indicate no matches */}
                    {filteredApiCallLogs.length === 0 ? (
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{ textAlign: "center", py: 2 }}
                        >
                          No events found matching your search.
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredApiCallLogs.map((log, index) => (
                        <CollapsibleRow key={log.id || index} log={log} />
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </Box>

        {/* Investigative Steps */}
        <Box
          sx={{
            flexBasis: "30%",
            flexGrow: 1,
            maxWidth: "30%",
            height: "95%",
          }}
        >
          <InvestigativeStepsTabs
            analyses={analyses}
            artifacts={caseDetail.artifacts}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CloudFlowAndInvestigativeSteps;

/**
 * A helper component to render each row with a collapsible section containing the raw JSON.
 */
const CollapsibleRow: React.FC<{ log: any }> = ({ log }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  // Extract the "service" from EventSource:
  // e.g., "cloudtrail.amazonaws.com" -> "cloudtrail"
  const service = log.EventSource?.split(".")[0] || "";

  return (
    <>
      <TableRow
        hover
        sx={{
          transition: "background-color 0.2s ease",
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        <TableCell align="center">
          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
            aria-label="expand row"
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {new Date(log.EventTime).toLocaleString()}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{service}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{log.EventName}</Typography>
        </TableCell>
      </TableRow>

      {/* Collapsible content row */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                m: 1,
                border: `1px solid ${theme.palette.grey[200]}`,
                borderRadius: 1,
                backgroundColor: theme.palette.grey[50],
                overflow: "auto",
                maxHeight: 300,
                p: 1,
              }}
            >
              <pre
                style={{
                  margin: 0,
                  fontSize: "12px",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                {JSON.stringify(log, null, 2)}
              </pre>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
