import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Auth0OrganizationUser,
  Invitation,
} from "../features/auth/types/user-types";

/**
 * Fetch organization users
 */
export const fetchUsers = createAsyncThunk<
  Auth0OrganizationUser[], // Return type on success
  { accessToken: string }, // Argument type
  { rejectValue: string } // Rejection type
>("users/fetchUsers", async ({ accessToken }, { rejectWithValue }) => {
  if (!accessToken) {
    return rejectWithValue("No access token; user not authenticated");
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1alpha/users`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      const text = await response.text().catch(() => "Unknown error");
      return rejectWithValue(text);
    }
    return await response.json(); // -> Auth0OrganizationUser[]
  } catch (err: any) {
    return rejectWithValue(err.message || "Failed to fetch users");
  }
});

/**
 * Invite a new user to the organization
 */
export const inviteUser = createAsyncThunk<
  Invitation,
  { accessToken: string; inviteeEmail: string },
  { rejectValue: string }
>(
  "users/inviteUser",
  async ({ accessToken, inviteeEmail }, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1alpha/users?invitee_email=${encodeURIComponent(inviteeEmail)}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.text().catch(() => "Unknown error");
        return rejectWithValue(text);
      }

      return await response.json(); // -> Invitation
    } catch (err: any) {
      return rejectWithValue(err.message || "Failed to invite user");
    }
  }
);

/**
 * Delete a user from the organization
 */
export const deleteUser = createAsyncThunk<
  string,
  { accessToken: string; userId: string },
  { rejectValue: string }
>("users/deleteUser", async ({ accessToken, userId }, { rejectWithValue }) => {
  if (!accessToken) {
    return rejectWithValue("No access token; user not authenticated");
  }

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1alpha/users/${userId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (!response.ok) {
      const text = await response.text().catch(() => "Unknown error");
      return rejectWithValue(text);
    }

    return userId; // Return userId so a reducer can remove that user from state
  } catch (err: any) {
    return rejectWithValue(err.message || "Failed to delete user");
  }
});

/**
 * Add admin role to a user
 */
export const addAdminRole = createAsyncThunk<
  { userId: string },
  { accessToken: string; userId: string },
  { rejectValue: string }
>(
  "users/addAdminRole",
  async ({ accessToken, userId }, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/users/${userId}/admin`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.text().catch(() => "Unknown error");
        return rejectWithValue(text);
      }

      // Return object with userId so the reducer can update the roles
      return { userId };
    } catch (err: any) {
      return rejectWithValue(err.message || "Failed to add admin role");
    }
  }
);

/**
 * Remove admin role from a user
 */
export const removeAdminRole = createAsyncThunk<
  { userId: string },
  { accessToken: string; userId: string },
  { rejectValue: string }
>(
  "users/removeAdminRole",
  async ({ accessToken, userId }, { rejectWithValue }) => {
    if (!accessToken) {
      return rejectWithValue("No access token; user not authenticated");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1alpha/users/${userId}/admin`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        const text = await response.text().catch(() => "Unknown error");
        return rejectWithValue(text);
      }

      return { userId };
    } catch (err: any) {
      return rejectWithValue(err.message || "Failed to remove admin role");
    }
  }
);
