import React, { useState, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  requestApplicationApproval,
  provisionUser,
  inviteUser,
  updateApplicationStatus,
} from "../api/onboardingApi";
import {
  Container,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Navigate, useLocation } from "react-router-dom"; // Import useLocation

// Import step components
import WelcomeStep from "../features/onboarding/components/WelcomeStep";
import ExperienceStep from "../features/onboarding/components/ExperienceStep";
import ApprovalStep from "../features/onboarding/components/ApprovalStep";
import AddUsersStep from "../features/onboarding/components/AddUsersStep";
import AlertSourcesStep from "../features/onboarding/components/AlertSourcesStep";
import CompletionStep from "../features/onboarding/components/CompletionStep";

import embedLogo from "../assets/embed-logo-text.svg";
import { useAuth0 } from "@auth0/auth0-react";
import m365Guide from "../assets/pdfs/M365_Email_Onboarding.pdf";
import { jwtDecode } from "jwt-decode";

// Add interface for location state
interface LocationState {
  initialStep?: number;
}

export const alertSources = [
  {
    title: "Google Workspace",
    type: "email-imap",
    id: "google-workspace-source",
    icon: "SVG",
    description: "Integrate with Google Workspace for email-based alerts",
    guidePdfUrl: undefined,
    formFields: [
      {
        title: "Username",
        name: "username",
        type: "text-input:100",
      },
      {
        title: "Password",
        name: "password",
        type: "text-input:30",
      },
    ],
  },
  {
    title: "Microsoft 365 Email",
    type: "email-m365",
    id: "m365-email-source",
    icon: "SVG",
    description:
      "Leverages Microsoft Graph API for email monitoring and alert collection",
    guidePdfUrl: m365Guide,
    formFields: [
      {
        title: "Tenant ID",
        name: "tenantId",
        type: "text-input:100",
      },
      {
        title: "Client ID",
        name: "clientId",
        type: "text-input:100",
      },
      {
        title: "Client Secret",
        name: "clientSecret",
        type: "text-input:100",
      },
      {
        title: "Inbox",
        name: "inbox",
        type: "text-input:100",
      },
    ],
  },
  {
    title: "Raw IMAP",
    type: "email-imap",
    id: "imap-email-source",
    icon: "SVG",
    description: "Connect directly to an email inbox via IMAP protocol",
    guidePdfUrl: undefined,
    formFields: [
      {
        title: "Username",
        name: "username",
        type: "text-input:100",
      },
      {
        title: "Password",
        name: "password",
        type: "text-input:30",
      },
      {
        title: "IMAP Server",
        name: "imapServer",
        type: "text-input:100",
      },
    ],
  },
  {
    title: "SentinelOne",
    type: "edr-sentinelone",
    id: "sentinelOne-source",
    icon: "SVG",
    description:
      "Integrate with SentinelOne's platform by providing tenant information",
    guidePdfUrl: undefined,
    formFields: [
      {
        title: "Tenant",
        name: "tenant",
        type: "text-input:10",
      },
      {
        title: "API Token",
        name: "token",
        type: "text-input:500",
      },
    ],
  },
];

const steps = [
  "Welcome",
  "Experience",
  "Approval",
  "Alert Sources",
  "Add Users",
  "Done",
];

const Onboarding = () => {
  const theme = useTheme();
  const location = useLocation();
  const locationState = location.state as LocationState;

  // Initialize activeStep with location state if provided
  const [activeStep, setActiveStep] = useState(locationState?.initialStep || 0);
  // const [activeStep, setActiveStep] = useState(3);
  const {
    isAuthenticated,
    isLoading: authLoading,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  useAuth0();
  const dispatch = useAppDispatch();

  // Add loading states to manage API calls
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);
  const [invitationErrors, setInvitationErrors] = useState<
    { email: string; error: string }[]
  >([]);

  // Local states for the wizard
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [teamName, setTeamName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);

  // Store configurations for each source
  const [sourcesFormData, setSourcesFormData] = useState<
    Record<string, Record<string, string>>
  >({});

  // A local loading/checking state while we do token checks or domain checks
  const [checkingAccess, setCheckingAccess] = useState(true);
  const [redirect, setRedirect] = useState<string | null>(null);

  useEffect(() => {
    // If Auth0 still loading, do nothing yet.
    if (authLoading) return;

    // If user is not authenticated at all, you might want to redirect them to /login
    if (!isAuthenticated) {
      setRedirect("/login");
      setCheckingAccess(false);
      return;
    }

    // Otherwise, user is authenticated => check their token claims or domain
    const checkOnboarding = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decoded: any = jwtDecode(token);

        const clientId = decoded["https://app.embedsecurity.com/client_id"];
        const applicationState =
          decoded["https://app.embedsecurity.com/application_state"];

        // If user has a clientId and is NOT in "new_user" state,
        // that means they're effectively onboarded => redirect away.
        if (clientId && applicationState !== "new_user") {
          setRedirect("/dashboard");
          setCheckingAccess(false);
          return;
        }

        // If user does NOT have a clientId, let's see if domain is recognized
        // (assuming your API does that check). If recognized => we do NOT want them here.
        if (!clientId) {
          const resp = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1alpha/users/verify`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (!resp.ok) {
            // If error, you might want to handle it or let them proceed.
            // Here, let's just redirect them away.
            setRedirect("/dashboard");
          } else {
            const data = await resp.json();
            const domainRecognized = data.user_has_known_domain;
            if (domainRecognized) {
              // Domain recognized => user shouldn’t need onboarding
              setRedirect("/wait-for-admin");
              // or /dashboard, or whatever your logic wants
            }
          }
        }
      } catch (err) {
        console.error("Onboarding check error:", err);
        // If something goes wrong, fallback to redirect or display an error.
        setRedirect("/dashboard");
      } finally {
        setCheckingAccess(false);
      }
    };

    checkOnboarding();
  }, [authLoading, isAuthenticated, getAccessTokenSilently]);

  // Add useEffect to handle initialStep changes
  useEffect(() => {
    if (locationState?.initialStep !== undefined) {
      setActiveStep(locationState.initialStep);
    }
  }, [locationState?.initialStep]);

  const handleNext = useCallback(async () => {
    setApiError(null);
    setInvitationErrors([]);

    // Handle API calls based on current step
    try {
      if (activeStep === 1) {
        // After Experience step, request application approval
        setIsLoading(true);
        const token = await getAccessTokenSilently({ cacheMode: "off" });
        await dispatch(
          requestApplicationApproval({
            accessToken: token,
            selectedOption,
          })
        ).unwrap();
        setIsLoading(false);
      } else if (activeStep === 4) {
        // After Add Users step, send invitations
        setIsLoading(true);
        const token = await getAccessTokenSilently({ cacheMode: "off" });

        // Combine current email input with already invited emails if needed
        let allEmails = [...invitedEmails];
        if (email && email.trim() && !invitedEmails.includes(email)) {
          allEmails.push(email);
        }

        // Process invitations one by one
        const errors = [];

        if (allEmails.length > 0) {
          for (const userEmail of allEmails) {
            try {
              await dispatch(
                inviteUser({
                  accessToken: token,
                  email: userEmail,
                })
              ).unwrap();
            } catch (error) {
              const errorMsg =
                error instanceof Error ? error.message : String(error);
              errors.push({
                email: userEmail,
                error: errorMsg,
              });
            }
          }
        }

        if (errors.length > 0) {
          setInvitationErrors(errors);
          // If we have errors, don't proceed to next step
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
      }

      // If user started at step 3 (AlertSources) from JWT, ensure we have required state
      // if (activeStep === 3 && selectedOption === null) {
      //   // TODO: Set a reasonable default for experience level
      //   setSelectedOption("Standard");
      // }

      // If we get here without errors, proceed to next step
      setActiveStep((prev) => prev + 1);
    } catch (error) {
      setIsLoading(false);
      setApiError(error instanceof Error ? error.message : "An error occurred");
      console.error("API call failed:", error);
    }
  }, [
    activeStep,
    dispatch,
    getAccessTokenSilently,
    selectedOption,
    email,
    invitedEmails,
  ]);

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
    setApiError(null);
    setInvitationErrors([]);
  };

  // This function should be called when clicking "Go to Dashboard" in CompletionStep
  const handleFinish = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently({ cacheMode: "off" });

      console.log("Sending updateApplicationStatus request...");
      await dispatch(
        updateApplicationStatus({
          accessToken: token,
        })
      ).unwrap();

      console.log("Application status updated successfully");
      setIsLoading(false);

      // Navigate to dashboard
      window.location.href = "/dashboard";
    } catch (error) {
      setIsLoading(false);
      setApiError(
        error instanceof Error ? error.message : "Failed to complete setup"
      );
      console.error("Failed to update application status:", error);
    }
  }, [dispatch, getAccessTokenSilently]);

  // If we’ve decided to redirect, do so
  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  // Or show a small spinner while checking
  if (checkingAccess) {
    return (
      <Container maxWidth="md" sx={{ mt: 10, textAlign: "center" }}>
        <Alert severity="info">Verifying onboarding status...</Alert>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="md"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
        padding: "40px",
        marginTop: "80px",
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        padding={4}
        position="absolute"
        top={0}
      >
        <img
          src={embedLogo}
          alt="Embed Security Logo"
          style={{ height: "40px" }}
        />
        <Button
          variant="outlined"
          style={{
            color: theme.palette.grey[700],
            borderColor: theme.palette.grey[500],
          }}
          onClick={() =>
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          }
        >
          Log Out
        </Button>
      </Box>

      {/* Stepper / breadcrumb */}
      <Box sx={{ width: "120%", mb: 4, mt: 2 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Step content */}
      <Box flex={1} width="100%">
        {apiError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {apiError}
          </Alert>
        )}

        {activeStep === 0 && <WelcomeStep onNext={handleNext} />}

        {activeStep === 1 && (
          <ExperienceStep
            selectedOption={selectedOption}
            onSelectOption={setSelectedOption}
            onNext={handleNext}
            onBack={handleBack}
            loading={isLoading}
          />
        )}

        {activeStep === 2 && (
          <ApprovalStep onNext={handleNext} onBack={handleBack} />
        )}

        {activeStep === 3 && (
          <AlertSourcesStep
            alertSources={alertSources}
            selectedSources={selectedSources}
            setSelectedSources={setSelectedSources}
            onNext={handleNext}
            onBack={handleBack}
            sourcesFormData={sourcesFormData}
            setSourcesFormData={setSourcesFormData}
          />
        )}

        {activeStep === 4 && (
          <AddUsersStep
            email={email}
            setEmail={setEmail}
            onNext={handleNext}
            onBack={handleBack}
            invitedEmails={invitedEmails}
            setInvitedEmails={setInvitedEmails}
            isLoading={isLoading}
            invitationErrors={invitationErrors}
          />
        )}

        {activeStep === 5 && (
          <CompletionStep
            onFinish={handleFinish}
            loading={isLoading}
            error={apiError}
          />
        )}
      </Box>
    </Container>
  );
};

export default Onboarding;
