import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  verifyUser,
  provisionUser,
  requestApplicationApproval,
  inviteUser,
  updateApplicationStatus,
  verifyIntegration,
  checkVerificationStatus,
  saveIntegration,
  deleteIntegration,
} from "../../../api/onboardingApi";

interface OnboardingState {
  loading: boolean;
  error: string | null;

  // Data from "verifyUser"
  hasCustomerId: boolean | null;
  userHasKnownDomain: boolean | null;

  // Data from "provisionUser"
  provisioning: boolean;
  provisioningError: string | null;
  provisioningSuccess: boolean;

  // Data for "requestApplicationApproval"
  approvalLoading: boolean;
  approvalError: string | null;
  approvalSuccess: boolean;

  // Data for inviting users
  invitingUsers: boolean;
  invitingUsersError: string | null;
  invitedUsers: string[];

  // Data for "updateApplicationStatus"
  updatingApplicationStatus: boolean;
  updateApplicationStatusError: string | null;
  updateApplicationStatusSuccess: boolean;

  // Data for "verifyIntegration"
  verifyingIntegration: boolean;
  verifyIntegrationError: string | null;
  verifyIntegrationSuccess: boolean;
  verifyIntegrationData: any; // or a more specific shape

  // Data for "checkVerificationStatus"
  checkingVerificationStatus: boolean;
  checkVerificationStatusError: string | null;
  checkVerificationStatusSuccess: boolean;
  verificationStatusData: any; // or a more specific shape

  // Data for "saveIntegration"
  savingIntegration: boolean;
  saveIntegrationError: string | null;
  saveIntegrationSuccess: boolean;
  savedIntegrationData: any; // or a more specific shape

  // For deleting an integration
  deletingIntegration: boolean;
  deleteIntegrationError: string | null;
  deleteIntegrationSuccess: boolean;
  deletedIntegrationId: string | null;
}

const initialState: OnboardingState = {
  loading: false,
  error: null,

  hasCustomerId: null,
  userHasKnownDomain: null,

  provisioning: false,
  provisioningError: null,
  provisioningSuccess: false,

  approvalLoading: false,
  approvalError: null,
  approvalSuccess: false,

  invitingUsers: false,
  invitingUsersError: null,
  invitedUsers: [],

  updatingApplicationStatus: false,
  updateApplicationStatusError: null,
  updateApplicationStatusSuccess: false,

  verifyingIntegration: false,
  verifyIntegrationError: null,
  verifyIntegrationSuccess: false,
  verifyIntegrationData: null,

  checkingVerificationStatus: false,
  checkVerificationStatusError: null,
  checkVerificationStatusSuccess: false,
  verificationStatusData: null,

  savingIntegration: false,
  saveIntegrationError: null,
  saveIntegrationSuccess: false,
  savedIntegrationData: null,

  deletingIntegration: false,
  deleteIntegrationError: null,
  deleteIntegrationSuccess: false,
  deletedIntegrationId: null,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    resetOnboarding: () => initialState,
  },
  extraReducers: (builder) => {
    // 1) verifyUser
    builder
      .addCase(verifyUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // e.g. { has_customer_id: false, user_has_known_domain: true }
        state.hasCustomerId = action.payload.has_customer_id;
        state.userHasKnownDomain = action.payload.user_has_known_domain;
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Error verifying user domain";
      });

    // 2) provisionUser
    builder
      .addCase(provisionUser.pending, (state) => {
        state.provisioning = true;
        state.provisioningError = null;
        state.provisioningSuccess = false;
      })
      .addCase(provisionUser.fulfilled, (state, action) => {
        state.provisioning = false;
        state.provisioningSuccess = true;
        // If the endpoint returns e.g. new_customer_id, store it
        // state.newCustomerId = action.payload.new_customer_id || null;
      })
      .addCase(provisionUser.rejected, (state, action) => {
        state.provisioning = false;
        state.provisioningError =
          (action.payload as string) || "Error provisioning user";
      });

    // 3) requestApplicationApproval
    builder
      .addCase(requestApplicationApproval.pending, (state) => {
        state.approvalLoading = true;
        state.approvalError = null;
        state.approvalSuccess = false;
      })
      .addCase(requestApplicationApproval.fulfilled, (state) => {
        state.approvalLoading = false;
        state.approvalSuccess = true;
        state.approvalError = null;
      })
      .addCase(requestApplicationApproval.rejected, (state, action) => {
        state.approvalLoading = false;
        state.approvalError =
          (action.payload as string) || "Error requesting application approval";
        state.approvalSuccess = false;
      });

    // 4) inviteUser
    builder
      .addCase(inviteUser.pending, (state) => {
        state.invitingUsers = true;
        state.invitingUsersError = null;
      })
      .addCase(inviteUser.fulfilled, (state, action) => {
        state.invitingUsers = false;
        if (
          action.payload?.email &&
          !state.invitedUsers.includes(action.payload.email)
        ) {
          state.invitedUsers.push(action.payload.email);
        }
      })
      .addCase(inviteUser.rejected, (state, action) => {
        state.invitingUsers = false;
        state.invitingUsersError =
          (action.payload as string) || "Error inviting user";
      });

    // 5) updateApplicationStatus
    builder
      .addCase(updateApplicationStatus.pending, (state) => {
        state.updatingApplicationStatus = true;
        state.updateApplicationStatusError = null;
        state.updateApplicationStatusSuccess = false;
      })
      .addCase(updateApplicationStatus.fulfilled, (state, action) => {
        state.updatingApplicationStatus = false;
        const { success, error } = action.payload;
        state.updateApplicationStatusSuccess = success;
        if (!success && error) {
          state.updateApplicationStatusError = error;
        }
      })
      .addCase(updateApplicationStatus.rejected, (state, action) => {
        state.updatingApplicationStatus = false;
        state.updateApplicationStatusError =
          (action.payload as string) || "Error updating application status";
        state.updateApplicationStatusSuccess = false;
      });

    // 6) verifyIntegration
    builder
      .addCase(verifyIntegration.pending, (state) => {
        state.verifyingIntegration = true;
        state.verifyIntegrationError = null;
        state.verifyIntegrationSuccess = false;
        state.verifyIntegrationData = null;
      })
      .addCase(verifyIntegration.fulfilled, (state, action) => {
        state.verifyingIntegration = false;
        state.verifyIntegrationSuccess = true;
        // Possibly includes e.g. { verification_id: "xyz123" }
        state.verifyIntegrationData = action.payload;
      })
      .addCase(verifyIntegration.rejected, (state, action) => {
        state.verifyingIntegration = false;
        state.verifyIntegrationError =
          (action.payload as string) || "Error verifying integration";
        state.verifyIntegrationSuccess = false;
      });

    // 7) checkVerificationStatus
    builder
      .addCase(checkVerificationStatus.pending, (state) => {
        state.checkingVerificationStatus = true;
        state.checkVerificationStatusError = null;
        state.checkVerificationStatusSuccess = false;
        state.verificationStatusData = null;
      })
      .addCase(checkVerificationStatus.fulfilled, (state, action) => {
        state.checkingVerificationStatus = false;
        state.checkVerificationStatusSuccess = true;
        // e.g. { verification_status: "completed", results: {...} }
        state.verificationStatusData = action.payload;
      })
      .addCase(checkVerificationStatus.rejected, (state, action) => {
        state.checkingVerificationStatus = false;
        state.checkVerificationStatusError =
          (action.payload as string) || "Error checking verification status";
        state.checkVerificationStatusSuccess = false;
      });

    // 8) saveIntegration
    builder
      .addCase(saveIntegration.pending, (state) => {
        state.savingIntegration = true;
        state.saveIntegrationError = null;
        state.saveIntegrationSuccess = false;
        state.savedIntegrationData = null;
      })
      .addCase(saveIntegration.fulfilled, (state, action) => {
        state.savingIntegration = false;
        state.saveIntegrationSuccess = true;
        // e.g. { integration_id: "abc123", ... }
        state.savedIntegrationData = action.payload;
      })
      .addCase(saveIntegration.rejected, (state, action) => {
        state.savingIntegration = false;
        state.saveIntegrationError =
          (action.payload as string) || "Error saving integration";
        state.saveIntegrationSuccess = false;
      });

    // 9) deleteIntegration
    builder
      .addCase(deleteIntegration.pending, (state) => {
        state.deletingIntegration = true;
        state.deleteIntegrationError = null;
        state.deleteIntegrationSuccess = false;
        state.deletedIntegrationId = null;
      })
      .addCase(deleteIntegration.fulfilled, (state, action) => {
        state.deletingIntegration = false;
        state.deleteIntegrationSuccess = true;
        state.deletedIntegrationId = action.payload.integrationId;
      })
      .addCase(deleteIntegration.rejected, (state, action) => {
        state.deletingIntegration = false;
        state.deleteIntegrationError =
          (action.payload as string) || "Error deleting integration";
        state.deleteIntegrationSuccess = false;
      });
  },
});

export const { resetOnboarding } = onboardingSlice.actions;
export default onboardingSlice.reducer;
