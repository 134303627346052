import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import Clarity from "@microsoft/clarity";
import "./index.css";

// Helper function to get query parameters
const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    organization: params.get("organization"),
    invitation: params.get("invitation"),
  };
};

const queryParams = getQueryParams();

const container = document.getElementById("root")!;
const root = createRoot(container);

// Only initialize Clarity in production
if (process.env.NODE_ENV === "production") {
  Clarity.init(process.env.REACT_APP_CLARITY_ID || "q4olmd25ez");
}

// For dev, first one
root.render(
  <React.StrictMode>
    {/* <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || "login.dev.orangebee.io"}
      clientId={
        process.env.REACT_APP_AUTH0_CLIENT_ID ||
        "x54aJ4jwqWayKkQwmnVyrKe09MFhqMKG"
      }
      authorizationParams={{
        audience:
          process.env.REACT_APP_AUTH0_AUDIENCE ||
          "https://app.embedsecurity.com/emaildemo/",
        redirect_uri: window.location.origin,
        organization: queryParams.organization || undefined,
        invitation: queryParams.invitation || undefined,
      }}
    > */}
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || "login.embedsecurity.com"}
      clientId={
        process.env.REACT_APP_AUTH0_CLIENT_ID ||
        "VbxkRAdQiS3qwbeiGJn9ES3GLIHrL60L"
      }
      authorizationParams={{
        audience:
          process.env.REACT_APP_AUTH0_AUDIENCE ||
          "https://app.embedsecurity.com/emaildemo/",
        redirect_uri: window.location.origin,
        organization: queryParams.organization || undefined,
        invitation: queryParams.invitation || undefined,
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
