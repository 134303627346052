import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress } from '@mui/material';
import { jwtDecode } from 'jwt-decode';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [hasAdminRole, setHasAdminRole] = useState<boolean | null>(null);

    useEffect(() => {
        const checkAdminRole = async () => {
            if (!isAuthenticated) {
                setHasAdminRole(false);
                return;
            }

            try {
                const token = await getAccessTokenSilently();
                const decoded: any = jwtDecode(token);
                const roles: string[] = decoded['https://app.embedsecurity.com/roles'] || [];
                const isAdmin = roles.some(role => role.toLowerCase() === 'admin');
                setHasAdminRole(isAdmin);
            } catch (error) {
                console.error('Error checking admin role:', error);
                setHasAdminRole(false);
            }
        };

        checkAdminRole();
    }, [isAuthenticated, getAccessTokenSilently]);

    if (isLoading || hasAdminRole === null) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!isAuthenticated || !hasAdminRole) {
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute; 