import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  useTheme,
  IconButton,
  Collapse,
  Menu,
  MenuItem,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ReplayIcon from "@mui/icons-material/Replay";
import DownloadIcon from "@mui/icons-material/Download";
import { useAppDispatch } from "../../../app/hooks";
import {
  verifyIntegration,
  checkVerificationStatus,
  saveIntegration, // POST
  updateIntegration, // PUT
  IntegrationConfig,
  deleteIntegration,
} from "../../../api/onboardingApi";
import { useAuth0 } from "@auth0/auth0-react";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

/** The local verification states we use. */
type VerificationStatus = "idle" | "verifying" | "success" | "failure";

/** Each source’s local state shape. */
interface SourceState {
  status: VerificationStatus;
  verificationId?: string;
  integrationId?: string; // from final POST/PUT
  error?: string;
  pollCount?: number;
}

/** Basic shape of a form field definition. */
interface FormField {
  title: string;
  name: string;
  type: string; // e.g. "text-input:100", "email", etc.
  formFields?: FormField[][];
}

interface AlertSource {
  title: string;
  type: string; // "email-imap" | "email-m365" | "edr-sentinelone"
  id: string; // unique ID
  icon: string;
  guidePdfUrl?: string;
  description?: string;
  formFields: FormField[];
}

interface AlertSourcesStepProps {
  alertSources: AlertSource[];
  selectedSources: string[];
  setSelectedSources: (val: string[]) => void;
  onNext: () => void;
  onBack: () => void;
  /** Keyed by sourceId => { fieldName => value } */
  sourcesFormData: Record<string, Record<string, string>>;
  setSourcesFormData: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, string>>>
  >;
}

const AlertSourcesStep: React.FC<AlertSourcesStepProps> = ({
  alertSources,
  selectedSources,
  setSelectedSources,
  onNext,
  onBack,
  sourcesFormData,
  setSourcesFormData,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // PDF display
  const [openGuideUrl, setOpenGuideUrl] = useState<string | null>(null);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  // Which source’s form is open
  const [activeSourceId, setActiveSourceId] = useState<string | null>(null);
  const [formOpen, setFormOpen] = useState(false);

  // Validation
  const [formErrors, setFormErrors] = useState<
    Record<string, Record<string, string>>
  >({});
  const [touchedFields, setTouchedFields] = useState<
    Record<string, Record<string, boolean>>
  >({});

  // Each source's local state
  const [sourceStates, setSourceStates] = useState<{
    [sourceId: string]: SourceState;
  }>({});

  // Next-step modals
  const [noSourcesModalOpen, setNoSourcesModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);

  // Menu
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuSourceId, setMenuSourceId] = useState<string | null>(null);

  // Confirm remove
  const [confirmDeselectOpen, setConfirmDeselectOpen] = useState(false);
  const [sourceToDeselect, setSourceToDeselect] = useState<string | null>(null);

  // Inside AlertSourcesStep component
  const [deletingIntegration, setDeletingIntegration] = useState(false);

  // =========== DEV DEBUG: Optional, watch sourceStates changes in console =============
  useEffect(() => {
    console.log("sourceStates changed:", sourceStates);
  }, [sourceStates]);
  // ====================================================================================

  // PDF Worker
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  // PDF onLoad
  const onDocumentLoadSuccess = (pdfData: any) => {
    setNumPages(pdfData.numPages);
    setPageNumber(1);
  };
  const goToNextPage = () => {
    if (pageNumber < numPages) setPageNumber((prev) => prev + 1);
  };
  const goToPrevPage = () => {
    if (pageNumber > 1) setPageNumber((prev) => prev - 1);
  };
  const handleOpenGuide = (pdfUrl?: string) => {
    if (!pdfUrl) return;
    setOpenGuideUrl(pdfUrl);
    setPageNumber(1);
  };
  const handleCloseGuide = () => setOpenGuideUrl(null);
  const handleDownloadGuide = (pdfUrl?: string) => {
    if (!pdfUrl) return;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = `guide-${Date.now()}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Next/back logic
  const handleNextClick = () => {
    if (selectedSources.length === 0) {
      setNoSourcesModalOpen(true);
      return;
    }
    // If any “failure,” show the “failures” modal
    const hasFailure = selectedSources.some(
      (id) => sourceStates[id]?.status === "failure"
    );
    if (hasFailure) {
      setFailureModalOpen(true);
      return;
    }
    onNext();
  };
  const handleNoSourcesContinue = () => {
    setNoSourcesModalOpen(false);
    onNext();
  };
  const handleFailuresContinue = () => {
    setFailureModalOpen(false);
    onNext();
  };
  const handleBackClick = () => {
    onBack();
  };

  // 3-dot menu
  const handleMenuOpen = (
    e: React.MouseEvent<HTMLButtonElement>,
    sourceId: string
  ) => {
    setMenuAnchorEl(e.currentTarget);
    setMenuSourceId(sourceId);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuSourceId(null);
  };

  // Remove source
  const handleConfirmDeselect = (sourceId: string) => {
    handleMenuClose();
    setSourceToDeselect(sourceId);
    setConfirmDeselectOpen(true);
  };

  const handleDeselectSource = async () => {
    if (sourceToDeselect) {
      // 1) Optionally read the integrationId from your local sourceStates
      const integrationId = sourceStates[sourceToDeselect]?.integrationId;

      // 2) If you have an integrationId, call deleteIntegration
      if (integrationId) {
        try {
          const token = await getAccessTokenSilently();
          await dispatch(
            deleteIntegration({
              accessToken: token,
              integrationId,
            })
          ).unwrap();
        } catch (err) {
          console.error("Failed to delete integration:", err);
          // Optionally handle error UI
        }
      }

      // 3) Remove the source from selectedSources in your local state
      setSelectedSources(
        selectedSources.filter((id) => id !== sourceToDeselect)
      );

      // 4) Remove from local sourceStates as well
      setSourceStates((prev) => {
        const updated = { ...prev };
        delete updated[sourceToDeselect];
        return updated;
      });
    }

    setConfirmDeselectOpen(false);
    setSourceToDeselect(null);
  };

  const handleCancelDeselect = () => {
    setConfirmDeselectOpen(false);
    setSourceToDeselect(null);
  };

  // Expanding the form
  const handleAddClick = (source: AlertSource) => {
    setActiveSourceId(source.id);
    setFormOpen(true);

    // If we have no form data yet, init
    if (!sourcesFormData[source.id]) {
      const init: Record<string, string> = {};
      const initFields = (fields: FormField[]) => {
        fields.forEach((f) => {
          if (f.formFields) {
            f.formFields.forEach((group) => initFields(group));
          } else {
            init[f.name] = "";
          }
        });
      };
      initFields(source.formFields);

      setSourcesFormData((prev) => ({ ...prev, [source.id]: init }));
      setTouchedFields((prev) => ({ ...prev, [source.id]: {} }));
    }

    // if we have no local SourceState yet
    setSourceStates((prev) => {
      if (!prev[source.id]) {
        const updated = {
          ...prev,
          [source.id]: { status: "idle" as VerificationStatus },
        };
        console.log("Initialized sourceState for", source.id, updated);
        return updated;
      }
      return prev;
    });
  };

  // Field changes
  const handleFormChange = (
    sourceId: string,
    fieldName: string,
    value: string
  ) => {
    setSourcesFormData((prev) => ({
      ...prev,
      [sourceId]: {
        ...prev[sourceId],
        [fieldName]: value,
      },
    }));
  };
  const handleFieldBlur = (sourceId: string, fieldName: string) => {
    setTouchedFields((prev) => ({
      ...prev,
      [sourceId]: {
        ...prev[sourceId],
        [fieldName]: true,
      },
    }));
  };

  // Validation
  const validateForm = (source: AlertSource) => {
    const sid = source.id;
    const newErrs: Record<string, string> = {};
    let isValid = true;

    const checkAll = (fields: FormField[]) => {
      fields.forEach((f) => {
        if (f.formFields) {
          f.formFields.forEach((grp) => checkAll(grp));
        } else {
          const [inputType] = f.type.split(":");
          const val = sourcesFormData[sid]?.[f.name] || "";
          if (!val.trim()) {
            newErrs[f.name] = `${f.title} is required`;
            isValid = false;
          }
          if (inputType === "email" && val) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(val)) {
              newErrs[f.name] = "Please enter a valid email address";
              isValid = false;
            }
          }
        }
      });
    };
    checkAll(source.formFields);

    setFormErrors((prev) => ({ ...prev, [sid]: newErrs }));
    return isValid;
  };

  // The “Save” button
  const handleSaveSource = async (source: AlertSource) => {
    const sid = source.id;
    if (!validateForm(source)) return;

    // set verifying
    setSourceStates((prev) => {
      const next = {
        ...prev,
        [sid]: {
          ...prev[sid],
          status: "verifying" as VerificationStatus,
          error: undefined,
        },
      };
      console.log("sourceStates after set verifying:", next);
      return next;
    });

    try {
      const token = await getAccessTokenSilently({ cacheMode: "off" });
      const cfg: IntegrationConfig = {
        integration_type: source.type as
          | "email-imap"
          | "email-m365"
          | "edr-sentinelone",
        ...sourcesFormData[sid],
      };
      if (source.id === "google-workspace-source") {
        cfg.imapServer = "imap.gmail.com";
      }

      // do verify => get verificationId
      const verifyResp = await dispatch(
        verifyIntegration({ accessToken: token, integrationConfig: cfg })
      ).unwrap();

      if (!verifyResp) {
        throw new Error("No verificationId from verifyIntegration!");
      }

      pollVerificationStatus(source, verifyResp, cfg, 0);
    } catch (err: any) {
      console.error("verifyIntegration error", err);
      setSourceStates((prev) => {
        const next = {
          ...prev,
          [sid]: {
            ...prev[sid],
            status: "failure" as VerificationStatus,
            error: err.message,
          },
        };
        console.log("sourceStates after verifyIntegration error:", next);
        return next;
      });
    }
  };

  // Poll
  const pollVerificationStatus = useCallback(
    async (
      source: AlertSource,
      verificationId: string,
      cfg: IntegrationConfig,
      attempt: number,
      maxAttempts = 8
    ) => {
      const sid = source.id;
      // final save => post or put
      const handleFinalSave = async (
        source: AlertSource,
        finalStatus: "success" | "failure",
        cfg: IntegrationConfig
      ) => {
        const sid = source.id;
        // read existing integrationId from state
        const existingId = sourceStates[sid]?.integrationId;

        try {
          const token = await getAccessTokenSilently({ cacheMode: "off" });
          let resp: any;

          if (!existingId) {
            // do POST
            resp = await dispatch(
              saveIntegration({
                accessToken: token,
                integrationConfig: cfg,
                createPullConnector: true,
                subClientId: null,
              })
            ).unwrap();
          } else {
            // do PUT
            resp = await dispatch(
              updateIntegration({
                accessToken: token,
                integrationId: existingId,
                integrationConfig: cfg,
                createPullConnector: true,
                subClientId: null,
              })
            ).unwrap();
          }

          const newId = resp.integration_metadata?.integration_id;
          const serverStatus =
            resp.integration_metadata?.verification_status?.toLowerCase() ||
            finalStatus;

          setSourceStates((prev) => {
            const next = {
              ...prev,
              [sid]: {
                ...prev[sid],
                integrationId: newId || existingId,
                status: (serverStatus === "success"
                  ? "success"
                  : "failure") as VerificationStatus,
              },
            };
            console.log("sourceStates after finalSave => ", next);
            return next;
          });

          // we add this source ID to selectedSources, so it stays "highlighted"
          if (serverStatus === "success" || serverStatus === "failure") {
            // only push if not already selected
            if (!selectedSources.includes(sid)) {
              setSelectedSources([...selectedSources, sid]);
            }
          }

          // If success => close card
          if (serverStatus === "success" && activeSourceId === sid) {
            setActiveSourceId(null);
            setFormOpen(false);
          }
        } catch (err: any) {
          console.error("handleFinalSave error:", err);
          setSourceStates((prev) => {
            const next = {
              ...prev,
              [sid]: {
                ...prev[sid],
                status: "failure" as VerificationStatus,
                error: err.message,
              },
            };
            console.log("sourceStates after finalSave catch => ", next);
            return next;
          });
        }
      };
      if (attempt >= maxAttempts) {
        console.warn(`Verification timed out for ${sid}`);
        await handleFinalSave(source, "failure", cfg);
        return;
      }

      try {
        const token = await getAccessTokenSilently({ cacheMode: "off" });
        // check verification
        const statusResp: { verification_status: string } = await dispatch(
          checkVerificationStatus({ accessToken: token, verificationId })
        ).unwrap();

        const s = statusResp.verification_status?.toLowerCase();
        if (s === "pending") {
          // remain "verifying"
          // increment pollCount for debug
          setSourceStates((prev) => {
            const next = {
              ...prev,
              [sid]: {
                ...prev[sid],
                status: "verifying" as VerificationStatus,
                pollCount: (prev[sid].pollCount || 0) + 1,
              },
            };
            console.log(`poll attempt ${attempt} => still pending:`, next);
            return next;
          });
          // poll again in 5s
          setTimeout(
            () =>
              pollVerificationStatus(source, verificationId, cfg, attempt + 1),
            5000
          );
        } else if (s === "success" || s === "completed") {
          await handleFinalSave(source, "success", cfg);
        } else if (s === "failure") {
          await handleFinalSave(source, "failure", cfg);
        } else {
          // unknown => fail
          await handleFinalSave(source, "failure", cfg);
        }
      } catch (err: any) {
        console.error("pollVerificationStatus error", err);
        await handleFinalSave(source, "failure", cfg);
      }
    },
    [dispatch, getAccessTokenSilently, activeSourceId, sourceStates]
  );

  // re-verify
  const doReverify = async (sourceId: string) => {
    const st = sourceStates[sourceId];
    if (!st?.verificationId) return;
    setSourceStates((prev) => {
      const next = {
        ...prev,
        [sourceId]: {
          ...prev[sourceId],
          status: "verifying" as VerificationStatus,
        },
      };
      console.log("sourceStates after doReverify => ", next);
      return next;
    });
    const src = alertSources.find((s) => s.id === sourceId);
    if (!src) return;
    const cfg: IntegrationConfig = {
      integration_type: src.type as
        | "email-imap"
        | "email-m365"
        | "edr-sentinelone",

      ...sourcesFormData[sourceId],
    };
    if (src.id === "google-workspace-source") {
      cfg.imapServer = "imap.gmail.com";
    }
    setTimeout(
      () => pollVerificationStatus(src, st.verificationId!, cfg, 0),
      500
    );
  };

  // Next is disabled if any in verifying
  const anyVerifying = selectedSources.some((id) => {
    const st = sourceStates[id];
    return st?.status === "verifying";
  });

  // ============== RENDER ==============
  return (
    <Box sx={{ p: 2 }}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Set up{" "}
          <span style={{ color: theme.palette.primary.main }}>
            Alert Sources
          </span>
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 1, mb: 2, color: theme.palette.grey[500] }}
        >
          We need you to add and save at least one alert ingestion source.
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {alertSources.map((source) => {
          const sid = source.id;
          const st = sourceStates[sid];
          const status: VerificationStatus = st?.status ?? "idle";

          const userManuallyOpened = activeSourceId === sid && formOpen;
          const isProblem =
            (status === "verifying" || status === "failure") &&
            activeSourceId === sid;
          const isCardOpen = userManuallyOpened || isProblem;

          // We'll do a quick function to get collapsed button content:
          const renderCollapsedButton = () => {
            switch (status) {
              case "verifying":
                return (
                  <>
                    Saving...
                    <CircularProgress size={16} sx={{ ml: 1, color: "#fff" }} />
                  </>
                );
              case "success":
                return "Success";
              case "failure":
                return (
                  <>
                    <ReplayIcon sx={{ mr: 0.5 }} />
                    Retry
                  </>
                );
              case "idle":
              default:
                return "Add";
            }
          };

          // Collapsed card if !isCardOpen
          const collapsedButtonDisabled = status === "verifying";

          return (
            <Grid item xs={12} sm={6} key={sid}>
              <Card
                sx={{
                  p: 2,
                  border: selectedSources.includes(sid)
                    ? `2px solid ${theme.palette.primary.main}`
                    : "2px solid transparent",
                }}
              >
                <Typography variant="h6">{source.title}</Typography>
                <Typography variant="body2" sx={{ mt: 1, color: "#666" }}>
                  {source.description || "No description"}
                </Typography>

                {/* expanded form */}
                <Collapse in={isCardOpen} unmountOnExit timeout="auto">
                  <Box sx={{ mt: 2, mb: 2 }}>
                    {source.formFields.map((field) => {
                      const renderFields = (fields: FormField[]) =>
                        fields.map((f) => {
                          if (f.formFields) {
                            return f.formFields.map((grp, idx) => (
                              <Box key={`${f.name}-grp-${idx}`} sx={{ pl: 2 }}>
                                {renderFields(grp)}
                              </Box>
                            ));
                          }
                          const val = sourcesFormData[sid]?.[f.name] || "";
                          const [inputType, maxLenStr] = f.type.split(":");
                          const maxLength = maxLenStr
                            ? parseInt(maxLenStr, 10)
                            : undefined;
                          const touched = touchedFields[sid]?.[f.name];
                          const errMsg = touched
                            ? formErrors[sid]?.[f.name]
                            : undefined;
                          const isSecret =
                            f.name.toLowerCase().includes("password") ||
                            f.name.toLowerCase().includes("token") ||
                            f.name.toLowerCase().includes("secret");

                          return (
                            <TextField
                              key={f.name}
                              label={f.title}
                              name={f.name}
                              margin="dense"
                              fullWidth
                              type={
                                inputType === "text-input"
                                  ? isSecret
                                    ? "password"
                                    : "text"
                                  : inputType
                              }
                              inputProps={{ maxLength }}
                              value={val}
                              onChange={(e) =>
                                handleFormChange(sid, f.name, e.target.value)
                              }
                              onBlur={() => handleFieldBlur(sid, f.name)}
                              error={!!errMsg}
                              helperText={errMsg || " "}
                              sx={{ mb: 1 }}
                            />
                          );
                        });

                      if (field.formFields) {
                        return field.formFields.map((g, i) => (
                          <Box key={`${field.name}-grp-${i}`} sx={{ mt: 2 }}>
                            {renderFields(g)}
                          </Box>
                        ));
                      } else {
                        return renderFields([field]);
                      }
                    })}
                  </Box>

                  {/* if success => the card collapses automatically so no bar needed */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 1,
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (activeSourceId === sid) {
                          setActiveSourceId(null);
                          setFormOpen(false);
                        }
                      }}
                      disabled={status === "verifying"}
                    >
                      Cancel
                    </Button>

                    {status === "failure" ? (
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: theme.palette.error.main }}
                        startIcon={<ReplayIcon />}
                        onClick={() => handleSaveSource(source)}
                      >
                        Retry
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled={status === "verifying"}
                        onClick={() => handleSaveSource(source)}
                        startIcon={
                          status === "verifying" ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <SaveIcon />
                          )
                        }
                      >
                        {status === "verifying" ? "Saving..." : "Save"}
                      </Button>
                    )}
                  </Box>
                </Collapse>

                {!isCardOpen && (
                  <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
                    <Button
                      variant="contained"
                      disabled={collapsedButtonDisabled}
                      sx={{
                        flex: 1,
                        color: "#fff",
                        backgroundColor:
                          (status as VerificationStatus) === "success"
                            ? theme.palette.success.main
                            : (status as VerificationStatus) === "failure"
                            ? theme.palette.error.main
                            : undefined,
                      }}
                      onClick={() => {
                        if (
                          (status as VerificationStatus) === "failure" ||
                          status === "idle" ||
                          status === "success"
                        ) {
                          // open form
                          setActiveSourceId(sid);
                          setFormOpen(true);
                        } else {
                          // verifying => do nothing
                        }
                      }}
                    >
                      {renderCollapsedButton()}
                    </Button>

                    {source.guidePdfUrl && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ flex: 1 }}
                        onClick={() => handleOpenGuide(source.guidePdfUrl)}
                      >
                        View Guide
                      </Button>
                    )}

                    {selectedSources.includes(sid) && (
                      <IconButton
                        size="small"
                        color="primary"
                        sx={{
                          border: `1px solid ${theme.palette.primary.main}`,
                        }}
                        onClick={(e) => handleMenuOpen(e, sid)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    )}
                  </Box>
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Confirm remove */}
      <Dialog open={confirmDeselectOpen} onClose={handleCancelDeselect}>
        <DialogTitle>Remove Alert Source?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove this alert source? The verification
            state will be cleared.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeselect} disabled={deletingIntegration}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            // If we're deleting, show the spinner, else show the Delete icon
            startIcon={
              deletingIntegration ? (
                <CircularProgress size={20} />
              ) : (
                <DeleteIcon />
              )
            }
            // disable the button while deleting
            disabled={deletingIntegration}
            onClick={async () => {
              setDeletingIntegration(true);
              try {
                await handleDeselectSource();
              } finally {
                setDeletingIntegration(false);
              }
            }}
          >
            {deletingIntegration ? "Removing..." : "Remove"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 3-dot menu */}
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            if (!menuSourceId) return;
            const src = alertSources.find((x) => x.id === menuSourceId);
            if (src) {
              setActiveSourceId(src.id);
              setFormOpen(true);
            }
          }}
        >
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit Configuration
        </MenuItem>

        {menuSourceId && sourceStates[menuSourceId]?.verificationId && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              doReverify(menuSourceId);
            }}
          >
            <ReplayIcon fontSize="small" sx={{ mr: 1 }} />
            Re-verify
          </MenuItem>
        )}

        <MenuItem
          sx={{ color: theme.palette.error.main }}
          onClick={() => {
            handleMenuClose();
            if (menuSourceId) handleConfirmDeselect(menuSourceId);
          }}
        >
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Remove Source
        </MenuItem>
      </Menu>

      {/* Next/back */}
      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button variant="outlined" onClick={handleBackClick}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNextClick}
          disabled={anyVerifying}
        >
          Next
        </Button>
      </Box>

      {/* PDF Dialog */}
      <Dialog
        open={Boolean(openGuideUrl)}
        onClose={handleCloseGuide}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Viewing Guide
          <IconButton
            aria-label="close"
            onClick={handleCloseGuide}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {openGuideUrl && (
            <>
              <Document
                file={openGuideUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Typography>Loading PDF...</Typography>}
                error={<Typography>Failed to load PDF</Typography>}
              >
                <Page pageNumber={pageNumber} scale={1.2} />
              </Document>

              {numPages > 1 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Button onClick={goToPrevPage} disabled={pageNumber <= 1}>
                    Previous
                  </Button>
                  <Typography>
                    Page {pageNumber} of {numPages}
                  </Typography>
                  <Button
                    onClick={goToNextPage}
                    disabled={pageNumber >= numPages}
                  >
                    Next
                  </Button>
                </Box>
              )}

              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  onClick={() => handleDownloadGuide(openGuideUrl)}
                >
                  Download Guide
                </Button>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>

      {/* No sources selected */}
      <Dialog
        open={noSourcesModalOpen}
        onClose={() => setNoSourcesModalOpen(false)}
      >
        <DialogTitle>No Alert Sources Selected</DialogTitle>
        <DialogContent>
          <Typography>
            You haven’t added any alert source. Are you sure you want to
            continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNoSourcesModalOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleNoSourcesContinue}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* Failures modal */}
      <Dialog
        open={failureModalOpen}
        onClose={() => setFailureModalOpen(false)}
      >
        <DialogTitle>Some Sources Failed</DialogTitle>
        <DialogContent>
          <Typography>
            At least one selected source is in a failure state. Do you want to
            continue anyway?
          </Typography>
          <Box mt={2}>
            {selectedSources
              .filter((id) => sourceStates[id]?.status === "failure")
              .map((sid) => (
                <Alert key={sid} severity="error" sx={{ mb: 1 }}>
                  {alertSources.find((x) => x.id === sid)?.title ?? sid} failed.
                </Alert>
              ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFailureModalOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleFailuresContinue}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AlertSourcesStep;
