import React from "react";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  Checkbox,
  Button,
  useTheme,
} from "@mui/material";
import { CoPresent, Slideshow } from "@mui/icons-material";

interface ExperienceStepProps {
  selectedOption: string | null;
  onSelectOption: (option: string) => void;
  onNext: () => void;
  onBack: () => void;
  loading?: boolean; // Add loading prop
}

const ExperienceStep: React.FC<ExperienceStepProps> = ({
  selectedOption,
  onSelectOption,
  onNext,
  onBack,
  loading = false, // Default to false
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        mt={4}
      >
        <Typography
          variant="h4"
          style={{
            fontFamily: theme.typography.h3.fontFamily,
            fontWeight: theme.typography.h3.fontWeight,
            fontSize: theme.typography.h3.fontSize,
            color: theme.palette.text.primary,
          }}
        >
          Choose Your{" "}
          <span style={{ color: theme.palette.primary.main }}>Experience</span>
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" gap={6} marginTop={4}>
        {/* Demo Card */}
        <Card
          style={{
            padding: "40px",
            textAlign: "center",
            minWidth: "280px",
            position: "relative",
            cursor: "pointer",
            boxShadow: theme.shadows[3],
            border:
              selectedOption === "Demo"
                ? `2px solid ${theme.palette.primary.main}`
                : "2px solid transparent",
            backgroundColor:
              selectedOption === "Demo" ? theme.palette.primary.light : "white",
          }}
          onClick={() => onSelectOption("Demo")}
        >
          <CardActionArea>
            <CoPresent
              style={{
                fontSize: 60,
                color:
                  selectedOption === "Demo"
                    ? theme.palette.primary.dark
                    : theme.palette.grey[500],
              }}
            />
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", fontSize: "14px", marginTop: 8 }}
            >
              Demo
            </Typography>

            {/* Moved “Demo” descriptive text here */}
            <Typography
              variant="body2"
              style={{
                fontSize: "12px",
                marginTop: "6px",
                color: theme.palette.grey[500],
              }}
            >
              Explore sample alerts before connecting my data.
            </Typography>
          </CardActionArea>
        </Card>

        {/* Get Started Card */}
        <Card
          style={{
            padding: "40px",
            textAlign: "center",
            minWidth: "280px",
            position: "relative",
            cursor: "pointer",
            boxShadow: theme.shadows[3],
            border:
              selectedOption === "Get Started"
                ? `2px solid ${theme.palette.primary.main}`
                : "2px solid transparent",
            backgroundColor:
              selectedOption === "Get Started"
                ? theme.palette.primary.light
                : "white",
          }}
          onClick={() => onSelectOption("Get Started")}
        >
          <CardActionArea>
            <Slideshow
              style={{
                fontSize: 60,
                color:
                  selectedOption === "Get Started"
                    ? theme.palette.primary.dark
                    : theme.palette.grey[500],
              }}
            />
            <Typography
              variant="body2"
              style={{ fontWeight: "bold", fontSize: "14px", marginTop: 8 }}
            >
              Get Started
            </Typography>

            {/* Moved “Get Started” descriptive text here */}
            <Typography
              variant="body2"
              style={{
                fontSize: "12px",
                marginTop: "6px",
                color: theme.palette.grey[500],
              }}
            >
              Connect my data sources and benefit today!
            </Typography>
          </CardActionArea>
        </Card>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginTop={4}
      >
        <Button variant="outlined" onClick={onBack} disabled={loading}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          disabled={!selectedOption || loading}
        >
          {loading ? "Processing..." : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default ExperienceStep;
