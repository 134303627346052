import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Typography,
  Popover,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
import Draggable from "react-draggable";

import SmeFeedbackDialog from "./SmeFeedbackDialog";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/rootReducer";
import {
  fetchReplyTemplates,
  fetchEligibleRecipients,
  postReplyEmail,
  pollEmailReplyStatus,
  ReplyTemplate,
  putSmeFeedback,
} from "../../../api/caseDetailsApi";
import { useAuth0 } from "@auth0/auth0-react";
import { CaseDetails } from "../types/caseDetailsTypes";

/** Decide which template type to pick from “replyTemplates.” */
function pickTemplateType(
  conclusion: string,
  userOption: string
): "Malicious" | "Benign" {
  const c = conclusion.toLowerCase();
  const u = userOption.toLowerCase();
  if (c === "malicious" && u === "agree") return "Malicious";
  if (c === "malicious" && u === "disagree") return "Benign";
  if (c === "benign" && u === "agree") return "Benign";
  if (c === "benign" && u === "disagree") return "Malicious";
  return "Benign";
}

/** Compute the actual text for a chosen template. */
function getTemplateText(
  conclusion: string,
  userOption: string,
  all: ReplyTemplate[]
): string {
  let neededType: "Malicious" | "Benign" = "Malicious";
  const c = conclusion.toLowerCase();
  const u = userOption.toLowerCase();

  if (c === "malicious" && u === "agree") neededType = "Malicious";
  else if (c === "malicious" && u === "disagree") neededType = "Benign";
  else if (c === "benign" && u === "agree") neededType = "Benign";
  else if (c === "benign" && u === "disagree") neededType = "Malicious";

  const found = all.find((tpl) => tpl.type === neededType);
  return found ? found.text : "";
}

/**
 * Overlay style: absolutely positioned under the toggle (for example).
 */
const overlayStyle = {
  position: "absolute" as const,
  marginTop: "0.5rem",
  border: "1px solid #ccc",
  borderRadius: 2,
  backgroundColor: "#fff",
  padding: 4,
  boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
  zIndex: 9999,
  minWidth: 455,
  overflow: "visible",
};

interface FeedbackSectionProps {
  feedback: string | null;
  feedbackReason: string | null;
  feedbackLoading: boolean;
  feedbackError: string | null;
  isEditingFeedback: boolean;
  feedbackOption: string;
  feedbackText: string;
  onFeedbackOptionChange: (
    event: React.MouseEvent<HTMLElement>,
    newOption: string
  ) => void;
  onFeedbackTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmitFeedback: () => void;
  onAgree: (position: "agree", reason: string) => void;
  onDisagree: (position: "disagree", reason: string) => void;
  onEditFeedback: () => void;
  onCancelEdit: () => void;
  setFeedbackText: React.Dispatch<React.SetStateAction<string>>;
  setFeedbackOption: React.Dispatch<React.SetStateAction<string>>;
  showSmeButton: boolean;
  analyses: any;
  version: string;
  caseId: string;
  existingFeedback: any;
  caseDetail: CaseDetails;

  /** If true, show “Email?” toggle & overlay. */
  enableSendEmailFlow?: boolean;

  /** For toast/snackbar messages in parent. */
  onEmailStatus: (
    message: string,
    severity: "info" | "success" | "error"
  ) => void;
}

const FeedbackSection: React.FC<FeedbackSectionProps> = ({
  feedback,
  feedbackReason,
  feedbackLoading,
  feedbackError,
  isEditingFeedback,
  feedbackOption,
  feedbackText,
  onFeedbackOptionChange,
  onFeedbackTextChange,
  onSubmitFeedback,
  onAgree,
  onDisagree,
  onEditFeedback,
  onCancelEdit,
  setFeedbackText,
  setFeedbackOption,
  showSmeButton,
  analyses,
  version,
  caseId,
  existingFeedback,
  caseDetail,
  enableSendEmailFlow = false,
  onEmailStatus,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  // Existing feedback popover
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openPopover = Boolean(anchorEl);
  const handleIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleInfoIconClose = () => {
    setAnchorEl(null);
  };

  // Local states for reason validation
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isDisagreeSelected, setIsDisagreeSelected] = useState(false);
  const [isAgreeSelected, setIsAgreeSelected] = useState(false);

  // “Email?” toggle
  const [sendEmailChecked, setSendEmailChecked] = useState(false);

  // Recipients & template text
  const [allRecipients, setAllRecipients] = useState<string[]>([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [templateText, setTemplateText] = useState("");

  // For convenience, check whether we can send email
  const [cannotSendEmailReason, setCannotSendEmailReason] = useState<
    string | null
  >(null);

  // Redux store data for templates & recipients
  const {
    replyTemplates,
    replyTemplatesLoading,
    replyTemplatesError,
    eligibleRecipients,
    recipientsLoading,
    recipientsError,
  } = useAppSelector((state: RootState) => state.caseDetails);

  // Once we have “eligibleRecipients” or errors, decide if we can or cannot send email
  useEffect(() => {
    if (!enableSendEmailFlow || !sendEmailChecked) {
      setCannotSendEmailReason(null);
      return;
    }

    if (replyTemplatesError) {
      setCannotSendEmailReason(replyTemplatesError);
      return;
    }
    if (recipientsError) {
      setCannotSendEmailReason(recipientsError);
      return;
    }

    if (!eligibleRecipients || eligibleRecipients.length === 0) {
      setCannotSendEmailReason(
        "No verified recipients found. Cannot send email."
      );
      return;
    }

    // If we get here, we have recipients and no error
    setCannotSendEmailReason(null);
  }, [
    enableSendEmailFlow,
    sendEmailChecked,
    replyTemplatesError,
    recipientsError,
    eligibleRecipients,
  ]);

  // If user toggles “Email?” on, fetch templates & recipients
  useEffect(() => {
    if (!enableSendEmailFlow) return;
    if (sendEmailChecked) {
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          // fetch in parallel
          dispatch(fetchReplyTemplates({ accessToken: token }));
          dispatch(fetchEligibleRecipients({ accessToken: token, caseId }));
        } catch (err) {
          console.error("Error fetching templates/recipients:", err);
        }
      })();
    } else {
      // If user toggles Email? off, clear recipients
      setAllRecipients([]);
      setSelectedEmail("");
      setTemplateText("");
    }
  }, [
    sendEmailChecked,
    enableSendEmailFlow,
    getAccessTokenSilently,
    dispatch,
    caseId,
  ]);

  // Once we have “eligibleRecipients” from Redux, pick a default
  useEffect(() => {
    if (!enableSendEmailFlow || !sendEmailChecked) {
      setAllRecipients([]);
      setSelectedEmail("");
      return;
    }

    if (!eligibleRecipients || eligibleRecipients.length === 0) {
      setAllRecipients([]);
      setSelectedEmail("");
      return;
    }

    let defaultEmail = eligibleRecipients[0];

    // If caseDetail.target is a single string or array that matches
    if (
      typeof caseDetail.target === "string" &&
      eligibleRecipients.includes(caseDetail.target)
    ) {
      defaultEmail = caseDetail.target;
    } else if (Array.isArray(caseDetail.target)) {
      for (const t of caseDetail.target) {
        if (eligibleRecipients.includes(t)) {
          defaultEmail = t;
          break;
        }
      }
    }

    setAllRecipients(eligibleRecipients);
    setSelectedEmail(defaultEmail);
  }, [enableSendEmailFlow, sendEmailChecked, eligibleRecipients, caseDetail]);

  // Choose the correct email template text
  useEffect(() => {
    if (!enableSendEmailFlow || !sendEmailChecked) {
      setTemplateText("");
      return;
    }
    if (!replyTemplates) {
      setTemplateText("");
      return;
    }
    const chosenText = getTemplateText(
      caseDetail.conclusion || "",
      feedbackOption,
      replyTemplates
    );
    setTemplateText(chosenText);
  }, [
    enableSendEmailFlow,
    sendEmailChecked,
    replyTemplates,
    feedbackOption,
    caseDetail.conclusion,
  ]);

  // Actually send the email
  async function sendEmailIfNeeded() {
    if (!enableSendEmailFlow || !sendEmailChecked || !selectedEmail) {
      return;
    }
    try {
      onEmailStatus("Sending email, please wait...", "info");
      const token = await getAccessTokenSilently();
      const finalTemplateType = pickTemplateType(
        caseDetail.conclusion || "",
        feedbackOption
      );

      const rawEmailId = await dispatch(
        postReplyEmail({
          accessToken: token,
          caseId,
          body: { recipient: selectedEmail, reply_template: finalTemplateType },
        })
      ).unwrap();

      const emailId = rawEmailId.replace(/%22/g, "");
      if (!emailId) {
        onEmailStatus("No tracking ID returned. Email not sent?", "error");
        return;
      }

      const status = await dispatch(
        pollEmailReplyStatus({ accessToken: token, caseId, emailId })
      ).unwrap();

      if (status === "Success") {
        onEmailStatus("Email was sent successfully!", "success");
      } else {
        onEmailStatus("Email failed to send.", "error");
      }
    } catch (err) {
      console.error("Error sending email:", err);
      onEmailStatus("Error sending email", "error");
    }
  }

  // Validation if "disagree"
  const handleFeedbackTextChangeLocal = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onFeedbackTextChange(event);
    const val = event.target.value;
    if (feedbackOption.toLowerCase() === "disagree") {
      if (val.trim().length < 5) {
        setErrorText("Reason must be at least 5 characters long.");
      } else {
        setErrorText(null);
      }
    } else {
      setErrorText(null);
    }
  };

  const isDisagreeSubmitDisabled =
    feedbackLoading ||
    (feedbackOption.toLowerCase() === "disagree" &&
      feedbackText.trim().length < 5 &&
      (isDisagreeSelected || isEditingFeedback));

  /** Called inside the overlay or main button to finalize. */
  async function handleSubmitFeedback() {
    onSubmitFeedback(); // This triggers the normal feedback logic
    if (enableSendEmailFlow && sendEmailChecked && !cannotSendEmailReason) {
      await sendEmailIfNeeded();
    }
  }

  /** Cancel from within the overlay if we cannot send email. */
  function handleCancelEmailOverlay() {
    setSendEmailChecked(false);
  }

  /** A small helper to pick the text for the overlay's Submit button. */
  function getOverlaySubmitLabel() {
    if (feedbackLoading) {
      return <CircularProgress size={20} />;
    }
    // If "can send email," show "Submit & Send"
    if (!cannotSendEmailReason) {
      return "Submit & Send";
    }
    // else fallback
    return "Submit";
  }

  // ------------------------------------------------------------------
  // (A) ALREADY HAVE FEEDBACK, non-editing
  // ------------------------------------------------------------------
  if (feedback && !isEditingFeedback) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="View Feedback" arrow>
          <IconButton
            onClick={handleIconClick}
            sx={{
              backgroundColor:
                feedback.toLowerCase() === "agree"
                  ? theme.palette.success.main
                  : theme.palette.error.main,
              color: "#fff",
              "&:hover": {
                backgroundColor:
                  feedback.toLowerCase() === "agree"
                    ? theme.palette.success.dark
                    : theme.palette.error.dark,
              },
            }}
          >
            {feedback.toLowerCase() === "agree" ? (
              <ThumbUpIcon />
            ) : (
              <ThumbDownIcon />
            )}
          </IconButton>
        </Tooltip>
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleInfoIconClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Box sx={{ p: 2, minWidth: 300 }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              User Provided Feedback:
            </Typography>
            <Typography variant="body1">
              {feedbackReason && feedbackReason.trim().length > 0
                ? feedbackReason
                : "None"}
            </Typography>
          </Box>
        </Popover>

        {/* Edit button */}
        <Tooltip title="Edit" arrow>
          <IconButton onClick={onEditFeedback} sx={{ ml: 1 }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  // ------------------------------------------------------------------
  // (B) EDITING EXISTING FEEDBACK
  // ------------------------------------------------------------------
  if (isEditingFeedback) {
    return (
      <Box sx={{ position: "relative" }}>
        {/* Row for toggles + reason/comment */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          {/* ToggleButtons */}
          <ToggleButtonGroup
            value={feedbackOption}
            exclusive
            onChange={onFeedbackOptionChange}
          >
            <ToggleButton
              value="agree"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: theme.palette.success.main,
                  color: "#fff",
                  "&:hover": { backgroundColor: theme.palette.success.dark },
                },
              }}
            >
              <ThumbUpIcon sx={{ mr: 1 }} />
              Agree
            </ToggleButton>
            <ToggleButton
              value="disagree"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: theme.palette.error.main,
                  color: "#fff",
                  "&:hover": { backgroundColor: theme.palette.error.dark },
                },
              }}
            >
              <ThumbDownIcon sx={{ mr: 1 }} />
              Disagree
            </ToggleButton>
          </ToggleButtonGroup>

          {/* Reason/Comment */}
          <TextField
            label={
              feedbackOption.toLowerCase() === "disagree"
                ? "Reason"
                : "Comment (Optional)"
            }
            variant="outlined"
            value={feedbackText}
            onChange={handleFeedbackTextChangeLocal}
            error={!!errorText}
            helperText={errorText}
            sx={{ maxWidth: 400 }}
          />

          {/* Show main Submit/Cancel only if Email is OFF */}
          {!sendEmailChecked && (
            <>
              <Button
                variant="contained"
                onClick={handleSubmitFeedback}
                disabled={
                  feedbackLoading ||
                  (feedbackOption.toLowerCase() === "disagree" && !!errorText)
                }
              >
                {feedbackLoading ? <CircularProgress size={20} /> : "Submit"}
              </Button>
              <Button
                variant="text"
                onClick={onCancelEdit}
                disabled={feedbackLoading}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>

        {/* “Email?” toggle + overlay if turned on */}
        {enableSendEmailFlow && (
          <Box sx={{ position: "relative", display: "inline-block", mt: 1 }}>
            <FormControlLabel
              label="Send Email"
              control={
                <Switch
                  size="small"
                  checked={sendEmailChecked}
                  onChange={(e) => setSendEmailChecked(e.target.checked)}
                />
              }
            />

            {sendEmailChecked && (
              <Box sx={overlayStyle}>
                {/* Loading */}
                {(replyTemplatesLoading || recipientsLoading) &&
                  !replyTemplatesError &&
                  !recipientsError && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <CircularProgress size={18} />
                      <Typography variant="body2">
                        Loading email data...
                      </Typography>
                    </Box>
                  )}

                {/* If we absolutely cannot send email */}
                {cannotSendEmailReason &&
                  !replyTemplatesLoading &&
                  !recipientsLoading && (
                    <Box>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {cannotSendEmailReason}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={handleCancelEmailOverlay}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}

                {/* Otherwise, can send email => show fields + local Submit/Cancel */}
                {!cannotSendEmailReason &&
                  !replyTemplatesLoading &&
                  !recipientsLoading && (
                    <>
                      {allRecipients.length > 1 ? (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Recipient</InputLabel>
                          <Select
                            label="Recipient"
                            value={selectedEmail}
                            onChange={(e) =>
                              setSelectedEmail(e.target.value as string)
                            }
                            MenuProps={{ sx: { zIndex: 999999 } }}
                          >
                            {allRecipients.map((addr) => (
                              <MenuItem key={addr} value={addr}>
                                {addr}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : allRecipients.length === 1 ? (
                        <Typography sx={{ mb: 2 }}>
                          To: <strong>{allRecipients[0]}</strong>
                        </Typography>
                      ) : null}

                      <TextField
                        label="Template"
                        multiline
                        rows={5}
                        value={templateText}
                        disabled
                        fullWidth
                      />

                      {/* Now, local Submit/Cancel in the overlay */}
                      <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                        <Button
                          variant="contained"
                          onClick={handleSubmitFeedback}
                          disabled={
                            feedbackLoading ||
                            (feedbackOption.toLowerCase() === "disagree" &&
                              !!errorText)
                          }
                        >
                          {getOverlaySubmitLabel()}
                        </Button>
                        <Button
                          variant="text"
                          onClick={handleCancelEmailOverlay}
                          disabled={feedbackLoading}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }

  // ------------------------------------------------------------------
  // (C) NO FEEDBACK YET, user clicked “Disagree”
  // ------------------------------------------------------------------
  if (isDisagreeSelected) {
    return (
      <Box sx={{ position: "relative" }}>
        {/* Row: toggles, reason, and Submit/Cancel if Email OFF */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          {/* ToggleButtons */}
          <ToggleButtonGroup
            value={feedbackOption}
            exclusive
            onChange={onFeedbackOptionChange}
          >
            <ToggleButton
              value="agree"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: theme.palette.success.main,
                  color: "#fff",
                  "&:hover": { backgroundColor: theme.palette.success.dark },
                },
              }}
            >
              <ThumbUpIcon sx={{ mr: 1 }} />
              Agree
            </ToggleButton>
            <ToggleButton
              value="disagree"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: theme.palette.error.main,
                  color: "#fff",
                  "&:hover": { backgroundColor: theme.palette.error.dark },
                },
              }}
            >
              <ThumbDownIcon sx={{ mr: 1 }} />
              Disagree
            </ToggleButton>
          </ToggleButtonGroup>
          <TextField
            label="Reason"
            variant="outlined"
            size="small"
            value={feedbackText}
            onChange={handleFeedbackTextChangeLocal}
            required
            error={!!errorText}
            helperText={errorText}
            sx={{ maxWidth: 400 }}
          />

          {/* Submit/Cancel only if Email is OFF */}
          {!sendEmailChecked && (
            <>
              <Button
                variant="contained"
                onClick={async () => {
                  await onDisagree("disagree", feedbackText);
                }}
                disabled={isDisagreeSubmitDisabled}
              >
                {feedbackLoading ? <CircularProgress size={20} /> : "Submit"}
              </Button>
              <Button
                variant="text"
                onClick={() => setIsDisagreeSelected(false)}
                disabled={feedbackLoading}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>

        {/* Container for the “Email?” toggle + overlay */}
        {enableSendEmailFlow && (
          <Box sx={{ position: "relative", display: "inline-block", mt: 1 }}>
            <FormControlLabel
              label="Send Email"
              control={
                <Switch
                  size="small"
                  checked={sendEmailChecked}
                  onChange={(e) => setSendEmailChecked(e.target.checked)}
                />
              }
            />

            {sendEmailChecked && (
              <Box sx={overlayStyle}>
                {(replyTemplatesLoading || recipientsLoading) &&
                  !replyTemplatesError &&
                  !recipientsError && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <CircularProgress size={18} />
                      <Typography variant="body2">
                        Loading email data...
                      </Typography>
                    </Box>
                  )}

                {cannotSendEmailReason &&
                  !replyTemplatesLoading &&
                  !recipientsLoading && (
                    <Box>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {cannotSendEmailReason}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={handleCancelEmailOverlay}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}

                {!cannotSendEmailReason &&
                  !replyTemplatesLoading &&
                  !recipientsLoading && (
                    <>
                      {allRecipients.length > 1 ? (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Recipient</InputLabel>
                          <Select
                            label="Recipient"
                            value={selectedEmail}
                            onChange={(e) =>
                              setSelectedEmail(e.target.value as string)
                            }
                            MenuProps={{ sx: { zIndex: 999999 } }}
                          >
                            {allRecipients.map((addr) => (
                              <MenuItem key={addr} value={addr}>
                                {addr}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : allRecipients.length === 1 ? (
                        <Typography sx={{ mb: 2 }}>
                          To: <strong>{allRecipients[0]}</strong>
                        </Typography>
                      ) : null}

                      <TextField
                        label="Template"
                        multiline
                        rows={5}
                        value={templateText}
                        disabled
                        fullWidth
                      />

                      {/* Overlay Submit/Cancel */}
                      <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                        <Button
                          variant="contained"
                          onClick={async () => {
                            await onDisagree("disagree", feedbackText);
                            if (!isDisagreeSubmitDisabled) {
                              await sendEmailIfNeeded();
                            }
                          }}
                          disabled={isDisagreeSubmitDisabled}
                        >
                          {feedbackLoading ? (
                            <CircularProgress size={20} />
                          ) : // If we can send email => "Submit & Send" else "Submit"
                          !cannotSendEmailReason ? (
                            "Submit & Send"
                          ) : (
                            "Submit"
                          )}
                        </Button>
                        <Button
                          variant="text"
                          onClick={handleCancelEmailOverlay}
                          disabled={feedbackLoading}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }

  // ------------------------------------------------------------------
  // (D) NO FEEDBACK YET, user clicked “Agree”
  // ------------------------------------------------------------------
  if (isAgreeSelected) {
    return (
      <Box sx={{ position: "relative" }}>
        {/* Row: toggles, comment, Submit/Cancel if Email OFF */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          {/* ToggleButtons */}
          <ToggleButtonGroup
            value={feedbackOption}
            exclusive
            onChange={onFeedbackOptionChange}
          >
            <ToggleButton
              value="agree"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: theme.palette.success.main,
                  color: "#fff",
                  "&:hover": { backgroundColor: theme.palette.success.dark },
                },
              }}
            >
              <ThumbUpIcon sx={{ mr: 1 }} />
              Agree
            </ToggleButton>
            <ToggleButton
              value="disagree"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: theme.palette.error.main,
                  color: "#fff",
                  "&:hover": { backgroundColor: theme.palette.error.dark },
                },
              }}
            >
              <ThumbDownIcon sx={{ mr: 1 }} />
              Disagree
            </ToggleButton>
          </ToggleButtonGroup>
          <TextField
            label="Comment (Optional)"
            size="small"
            variant="outlined"
            value={feedbackText}
            onChange={handleFeedbackTextChangeLocal}
            sx={{ maxWidth: 400 }}
          />

          {!sendEmailChecked && (
            <>
              <Button
                variant="contained"
                onClick={async () => {
                  await onAgree("agree", feedbackText);
                }}
                disabled={feedbackLoading}
              >
                {feedbackLoading ? <CircularProgress size={20} /> : "Submit"}
              </Button>
              <Button
                variant="text"
                onClick={() => setIsAgreeSelected(false)}
                disabled={feedbackLoading}
              >
                Cancel
              </Button>
            </>
          )}
        </Box>

        {/* “Email?” toggle + overlay */}
        {enableSendEmailFlow && (
          <Box sx={{ position: "relative", display: "inline-block", mt: 1 }}>
            <FormControlLabel
              label="Send Email"
              control={
                <Switch
                  size="small"
                  checked={sendEmailChecked}
                  onChange={(e) => setSendEmailChecked(e.target.checked)}
                />
              }
            />

            {sendEmailChecked && (
              <Box sx={overlayStyle}>
                {(replyTemplatesLoading || recipientsLoading) &&
                  !replyTemplatesError &&
                  !recipientsError && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <CircularProgress size={18} />
                      <Typography variant="body2">
                        Loading email data...
                      </Typography>
                    </Box>
                  )}

                {cannotSendEmailReason &&
                  !replyTemplatesLoading &&
                  !recipientsLoading && (
                    <Box>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        {cannotSendEmailReason}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={handleCancelEmailOverlay}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}

                {!cannotSendEmailReason &&
                  !replyTemplatesLoading &&
                  !recipientsLoading && (
                    <>
                      {allRecipients.length > 1 ? (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <InputLabel>Recipient</InputLabel>
                          <Select
                            label="Recipient"
                            value={selectedEmail}
                            onChange={(e) =>
                              setSelectedEmail(e.target.value as string)
                            }
                            MenuProps={{ sx: { zIndex: 999999 } }}
                          >
                            {allRecipients.map((addr) => (
                              <MenuItem key={addr} value={addr}>
                                {addr}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : allRecipients.length === 1 ? (
                        <Typography sx={{ mb: 2 }}>
                          To: <strong>{allRecipients[0]}</strong>
                        </Typography>
                      ) : null}

                      <TextField
                        label="Template"
                        multiline
                        rows={5}
                        value={templateText}
                        disabled
                        fullWidth
                      />

                      {/* Overlay Submit/Cancel */}
                      <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                        <Button
                          variant="contained"
                          onClick={async () => {
                            await onAgree("agree", feedbackText);
                            if (enableSendEmailFlow && !feedbackLoading) {
                              await sendEmailIfNeeded();
                            }
                          }}
                          disabled={feedbackLoading}
                        >
                          {/* If we can send => "Submit & Send" else "Submit" */}
                          {feedbackLoading ? (
                            <CircularProgress size={20} />
                          ) : !cannotSendEmailReason ? (
                            "Submit & Send"
                          ) : (
                            "Submit"
                          )}
                        </Button>
                        <Button
                          variant="text"
                          onClick={handleCancelEmailOverlay}
                          disabled={feedbackLoading}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  }

  // ------------------------------------------------------------------
  // (E) NO FEEDBACK YET, no selection
  // ------------------------------------------------------------------
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.success.main,
          color: "#fff",
          "&:hover": { backgroundColor: theme.palette.success.dark },
        }}
        onClick={() => {
          setFeedbackText("");
          setErrorText(null);
          setIsAgreeSelected(true);
          setFeedbackOption("agree");
        }}
        disabled={feedbackLoading}
      >
        {feedbackLoading ? <CircularProgress size={20} /> : "AGREE"}
      </Button>
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.error.main,
          color: "#fff",
          "&:hover": { backgroundColor: theme.palette.error.dark },
        }}
        onClick={() => {
          setFeedbackText("");
          setErrorText(null);
          setIsDisagreeSelected(true);
          setFeedbackOption("disagree");
        }}
        disabled={feedbackLoading}
      >
        {feedbackLoading ? <CircularProgress size={20} /> : "DISAGREE"}
      </Button>

      {feedbackError && (
        <Typography color="error" sx={{ ml: 3 }}>
          {feedbackError}
        </Typography>
      )}
    </Box>
  );
};

// Wrap with SME Feedback container
const FeedbackSectionWrapper: React.FC<FeedbackSectionProps> = (props) => {
  const {
    showSmeButton,
    analyses,
    version,
    existingFeedback,
    caseDetail,
    caseId,
  } = props;
  const theme = useTheme();
  const [smeDialogOpen, setSmeDialogOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const handleOpenSmeDialog = () => setSmeDialogOpen(true);
  const handleCloseSmeDialog = () => setSmeDialogOpen(false);

  const iStepOptions = analyses.map((a: { name: any }) => a.name);
  const urlsAnalysis = analyses.find(
    (a: { name: string }) => a.name === "URLs"
  );
  const availableUrls = urlsAnalysis
    ? [
        ...(urlsAnalysis.data.malicious || []),
        ...(urlsAnalysis.data.benign || []),
        ...(urlsAnalysis.data.inconclusive || []),
      ]
    : [];

  // SME feedback
  async function handleSmeFeedbackSubmit(payload: any) {
    try {
      const token = await getAccessTokenSilently();
      await dispatch(
        putSmeFeedback({
          accessToken: token,
          caseId,
          payload,
        })
      ).unwrap();
      console.log("SME feedback submitted successfully!");
    } catch (err) {
      console.error("Failed to PUT SME feedback:", err);
    }
  }

  const smeFeedback = existingFeedback?.smeFeedback;
  const smeFeedbackDetails = smeFeedback?.feedback;

  return (
    <Box sx={{ position: "relative" }}>
      {/* The main feedback UI */}
      <FeedbackSection {...props} />

      {/* Draggable “SME Feedback” tab if needed */}
      {showSmeButton && (
        <Draggable axis="x">
          <Box
            sx={{
              position: "fixed",
              top: 0,
              right: "81%",
              display: "flex",
              alignItems: "center",
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
              borderRadius: "0 0 0 4px",
              px: 2,
              py: 1,
              cursor: "grab",
              userSelect: "none",
            }}
            onClick={handleOpenSmeDialog}
          >
            <Typography variant="button" sx={{ fontSize: 14 }}>
              SME Feedback
            </Typography>
          </Box>
        </Draggable>
      )}

      <SmeFeedbackDialog
        open={smeDialogOpen}
        onClose={handleCloseSmeDialog}
        iStepOptions={iStepOptions}
        availableUrls={availableUrls}
        version={version}
        onSubmitSmeFeedback={handleSmeFeedbackSubmit}
        existingFeedback={smeFeedbackDetails}
        caseDetail={caseDetail}
      />
    </Box>
  );
};

export default FeedbackSectionWrapper;
