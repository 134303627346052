import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import MainLayout from "../layouts/MainLayout";
import Sidebar from "../layouts/Sidebar";
import DashboardPage from "../pages/DashboardPage";
import CasesListPage from "../pages/CasesListPage";
import CaseDetailsPage from "../pages/CaseDetailsPage";
import CustomerAdminPage from "../pages/CustomerAdminPage";

import LoginPage from "../pages/LoginPage";
import Onboarding from "../pages/OnboardingPage";
import PrivateRoute from "./PrivateRoute";
import MyLottieAnimation from "../common/components/MyLottieAnimation";
import DebugTokenLogger from "../pages/DebugTokenLogger";
import WaitForAdminPage from "../pages/WaitForAdminPage";

const AppRoutes = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  // This just runs the Lottie animation on first load
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    const hasSeenAnimation = localStorage.getItem("hasSeenAnimation");
    if (hasSeenAnimation) {
      setAnimationCompleted(true);
    }
  }, []);

  const handleAnimationComplete = () => {
    localStorage.setItem("hasSeenAnimation", "true");
    setAnimationCompleted(true);
  };

  // If Lottie not done => show the splash
  if (!animationCompleted) {
    return (
      <MyLottieAnimation
        onAnimationComplete={handleAnimationComplete}
        speed={2.5}
      />
    );
  }

  return (
    <MainLayout>
      <div style={{ display: "flex" }}>
        {/* <DebugTokenLogger /> */}
        {/* Hide Sidebar if on Onboarding */}
        {location.pathname !== "/onboard" &&
          location.pathname !== "/wait-for-admin" && <Sidebar />}
        <div style={{ flex: 1, padding: "20px" }}>
          <Routes>
            {/* Root route: if authenticated => /dashboard, else => /login */}
            <Route
              path="/"
              element={
                isAuthenticated ? (
                  <Navigate to="/dashboard" replace />
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />

            {/* Login page */}
            <Route path="/login" element={<LoginPage />} />

            {/* Onboarding route (public/unprotected for new domain) */}
            <Route path="/onboard" element={<Onboarding />} />
            <Route path="/wait-for-admin" element={<WaitForAdminPage />} />

            {/* Private routes => must pass PrivateRoute checks */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <>
                    <DashboardPage />
                  </>
                </PrivateRoute>
              }
            />
            <Route
              path="/cases"
              element={
                <PrivateRoute>
                  <CasesListPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/cases/:id"
              element={
                <PrivateRoute>
                  <CaseDetailsPage />
                </PrivateRoute>
              }
            />

            {/* Customers route - protected */}
            <Route
              path="/customers"
              element={
                <PrivateRoute>
                  <CustomerAdminPage />
                </PrivateRoute>
              }
            />

            {/* Customers route - protected */}
            <Route
              path="/customers"
              element={
                <PrivateRoute>
                  <CustomerAdminPage />
                </PrivateRoute>
              }
            />

            {/* Catch-all */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
    </MainLayout>
  );
};

export default AppRoutes;
