import React, { useState, useEffect } from "react";
import {
  Typography,
  Tooltip,
  IconButton,
  Box,
  SxProps,
  Theme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { Case } from "../types/types";
import { useNavigate } from "react-router-dom";
import { setCaseListAndIndex } from "../../case-navigation/redux/caseNavigationSlice";
import { useAppDispatch } from "../../../app/hooks";

interface CaseTitleTooltipProps {
  caseItem: Case;
  handleCopyId: (id: string) => void;
  sx?: SxProps<Theme>;
  filteredCases: Case[];
  textSx?: SxProps<Theme>;
}

const CaseTitleTooltip: React.FC<CaseTitleTooltipProps> = ({
  caseItem,
  handleCopyId,
  sx = {},
  filteredCases,
  textSx,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [copied, setCopied] = useState(false);

  const handleIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleCopyId(caseItem.caseId);
    setCopied(true);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (copied) {
      timer = setTimeout(() => setCopied(false), 1500);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [copied]);

  // TODO: need to update previous, next to now grouped
  const handleCaseClick = () => {
    // Find index of this case in filteredCases, flat version of our grouping
    const index = filteredCases.findIndex((c) => c.caseId === caseItem.caseId);
    dispatch(
      setCaseListAndIndex({ source: "cases", cases: filteredCases, index })
    );
    navigate(`/cases/${caseItem.caseId}`, { state: { from: "/cases" } });
  };

  return (
    <Tooltip
      title={
        <Box
          position="relative"
          sx={{
            borderRadius: 1, // Rounded corners
            minWidth: "150px", // Minimum width for consistency
            maxWidth: "250px", // Set a max width for consistent wrapping
            paddingRight: "30px", // Space to accommodate the icon
            whiteSpace: "normal",
            wordBreak: "break-word", // Ensure words break properly
            ...sx,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: "white",
              fontSize: "0.75rem", // Consistent font size
            }}
          >
            ID: {caseItem.caseId}
          </Typography>
          <IconButton
            size="small"
            onClick={handleIconClick}
            sx={{
              position: "absolute", // Position icon absolutely inside the box
              top: "50%",
              right: "8px",
              transform: "translateY(-50%)",
              color: "white",
              padding: 0, // Minimal padding for alignment
              height: "1.25rem", // Ensures IconButton has a consistent height
              width: "1.25rem", // Ensures IconButton has a consistent width
            }}
          >
            {copied ? (
              <CheckIcon fontSize="small" />
            ) : (
              <ContentCopyIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
      }
      placement="bottom-start"
      PopperProps={{
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              boundary: "viewport", // Ensures the tooltip remains within the viewport
            },
          },
        ],
      }}
    >
      <Typography
        variant="subtitle2"
        component="div"
        sx={{ fontWeight: "bold", cursor: "pointer", ...textSx }}
        onClick={handleCaseClick}
      >
        {caseItem.title || "\u200B"}
      </Typography>
    </Tooltip>
  );
};

export default CaseTitleTooltip;
