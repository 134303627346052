import React from "react";
import { Box } from "@mui/material";
import UserManagement from "../features/customer-admin/components/UserManagement";
import ProtectedRoute from "../routes/ProtectedRoute";

const CustomerAdminPage: React.FC = () => {
  return (
    <ProtectedRoute>
      <Box>
        <UserManagement />
      </Box>
    </ProtectedRoute>
  );
};

export default CustomerAdminPage;
