import React from "react";
import { BrowserRouter } from "react-router-dom";
import { CustomThemeProvider } from "./context/ThemeContext";
import AppRoutes from "./routes/AppRoutes";
import { useDispatch } from "react-redux";
import { setAuthState } from "./features/auth/redux/userSlice";
import { useAuth0 } from "@auth0/auth0-react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const App = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated, getAccessTokenSilently, isLoading, error } =
    useAuth0();

  React.useEffect(() => {
    const fetchToken = async () => {
      if (isAuthenticated) {
        try {
          // const token = await getAccessTokenSilently();
          dispatch(
            setAuthState({
              user: {
                name: user?.name || "",
                email: user?.email || "",
                picture: user?.picture || "",
              },
              token: "", // TODO: we will not persist the token (change if in dev for POSTMAN calls)
              isAuthenticated: true,
              loading: false,
              error: null,
            })
          );
        } catch (error: any) {
          console.error("Error fetching token: ", error);
          dispatch(
            setAuthState({
              user: null,
              token: null,
              isAuthenticated: false,
              loading: false,
              error: error.message,
            })
          );
        }
      } else if (!isLoading) {
        dispatch(
          setAuthState({
            user: null,
            token: null,
            isAuthenticated: false,
            loading: false,
            error: error ? error.message : null,
          })
        );
      }
    };

    fetchToken();
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    dispatch,
    user,
    isLoading,
    error,
  ]);

  return (
    <CustomThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </LocalizationProvider>
    </CustomThemeProvider>
  );
};

export default App;
