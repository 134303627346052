import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  useTheme,
  Breadcrumbs,
  Link as MuiLink,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
  Button,
  Modal,
  IconButton,
  Paper,
} from "@mui/material";
import {
  Link as RouterLink,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CaseSummarySection from "../features/case-details/components/CaseSummarySection";
import EmailFlowAndInvestigativeSteps from "../features/case-details/components/EmailFlowAndInvestigativeSteps";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/rootReducer";
import { useSelector } from "react-redux";
import {
  fetchCaseDetail,
  pollReprocessCase,
  startReprocessCase,
  submitFeedback,
} from "../api/caseDetailsApi";
import CaseDetailsLoadingSkeleton from "../features/case-details/components/CaseDetailsIsLoadingSkeleton";
import RefreshIcon from "@mui/icons-material/Refresh";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { setCurrentIndex } from "../features/case-navigation/redux/caseNavigationSlice";
import { reprocessCase } from "../api/caseDetailsApi";
// import cloudCaseData from "./cloud-response-v1.json";
import CloudFlowAndInvestigativeSteps from "../features/case-details/components/CloudFlowAndInvestigativeSteps";
import { transformCaseDetailApiResponse } from "../features/case-details/helpers/transformCaseDetailApiResponse";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 380,
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

interface ErrorInfo {
  message: string;
  code?: number;
  details?: any;
}

const CaseDetailsPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();

  const from = location.state?.from || "/cases";
  const { filteredCases, currentIndex } = useAppSelector(
    (state: RootState) => state.caseNavigation
  );

  const hasNavigation = filteredCases.length > 1;
  const canGoPrevious = hasNavigation && currentIndex > 0;
  const canGoNext = hasNavigation && currentIndex < filteredCases.length - 1;

  const handlePrevious = () => {
    if (canGoPrevious) {
      const newIndex = currentIndex - 1;
      const newCaseId = filteredCases[newIndex].caseId;
      dispatch(setCurrentIndex(newIndex));
      navigate(`/cases/${newCaseId}`, { state: { from } });
    }
  };

  const handleNext = () => {
    if (canGoNext) {
      const newIndex = currentIndex + 1;
      const newCaseId = filteredCases[newIndex].caseId;
      dispatch(setCurrentIndex(newIndex));
      navigate(`/cases/${newCaseId}`, { state: { from } });
    }
  };

  // Manage feedback editing
  const [isEditingFeedback, setIsEditingFeedback] = useState(false);
  const pollRef = useRef<NodeJS.Timeout | null>(null);

  // Redux store data
  const caseDetail = useSelector(
    (state: RootState) => state.caseDetails.caseDetail
  );

  // test cloud data
  // const caseDetail = transformCaseDetailApiResponse(cloudCaseData as any);
  const isCloudCase = caseDetail?.attackSurface === "Cloud";

  const loading = useSelector((state: RootState) => state.caseDetails.loading);
  const error = useSelector((state: RootState) => state.caseDetails.error);
  const feedbackLoading = useSelector(
    (state: RootState) => state.caseDetails.feedbackLoading
  );
  const feedbackError = useSelector(
    (state: RootState) => state.caseDetails.feedbackError
  );
  const isProcessing = useAppSelector(
    (state: RootState) => state.caseDetails.isProcessing
  );
  const reprocessLoading = useAppSelector(
    (state: RootState) => state.caseDetails.reprocessLoading
  );
  const reprocessError = useAppSelector(
    (state: RootState) => state.caseDetails.reprocessError
  );

  // Feedback from caseDetail
  const feedbackEntry =
    caseDetail && caseDetail.feedbacks && caseDetail.feedbacks.length > 0
      ? caseDetail.feedbacks[0]
      : null;
  const feedback = isEditingFeedback ? null : feedbackEntry?.result || null;
  const feedbackReason = isEditingFeedback ? null : feedbackEntry?.text || null;

  // local feedback state
  const [feedbackOption, setFeedbackOption] = useState(feedback || "agree");
  const [feedbackText, setFeedbackText] = useState(feedbackReason || "");

  // Auth and data fetch
  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      try {
        const token = await getAccessTokenSilently();
        dispatch(fetchCaseDetail({ accessToken: token, caseId: id }));
      } catch (err) {
        console.error("Error fetching access token:", err);
      }
    };
    fetchData();
  }, [dispatch, getAccessTokenSilently, id]);

  useEffect(() => {
    const checkIfProcessing = async () => {
      if (!id || !caseDetail) return;
      if (caseDetail.status === "Processing") {
        try {
          const token = await getAccessTokenSilently();
          await dispatch(pollReprocessCase({ accessToken: token, caseId: id }));
        } catch (e) {
          console.error("Error auto-polling reprocess:", e);
        }
      }
    };
    checkIfProcessing();
  }, [caseDetail, dispatch, getAccessTokenSilently, id]);

  // Go back to /cases
  const handleGoBackToCases = () => {
    navigate("/cases");
  };

  // Manual refresh
  const handleRefresh = async () => {
    if (!id) return;
    try {
      const token = await getAccessTokenSilently();
      await dispatch(
        fetchCaseDetail({ accessToken: token, caseId: id })
      ).unwrap();
      setSnackbarMessage("Case details refreshed successfully!");
      setSnackbarSeverity("success");
    } catch (e: any) {
      console.error("Error refreshing case details:", e);
      setSnackbarMessage("Failed to refresh case details.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  // Feedback handlers
  const handleFeedbackOptionChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: string
  ) => {
    if (newOption !== null) {
      setFeedbackOption(newOption);
    }
  };

  const handleFeedbackTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    if (!id) return;
    try {
      const token = await getAccessTokenSilently();
      await dispatch(
        submitFeedback({
          accessToken: token,
          caseId: id,
          position: feedbackOption as "agree" | "disagree",
          reason: feedbackText,
        })
      );
      setIsEditingFeedback(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleNewSubmitFeedback = async (
    position: "agree" | "disagree",
    reason: string
  ) => {
    if (!id) return;
    try {
      const token = await getAccessTokenSilently();
      await dispatch(
        submitFeedback({
          accessToken: token,
          caseId: id,
          position,
          reason,
        })
      );
      setIsEditingFeedback(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleEditFeedback = () => {
    setIsEditingFeedback(true);
    setFeedbackOption(feedback || "agree");
    setFeedbackText(feedbackReason || "");
  };

  const handleCancelEdit = () => {
    setIsEditingFeedback(false);
    setFeedbackOption(feedback || "agree");
    setFeedbackText(feedbackReason || "");
  };

  const emailImageUrl = id
    ? `${process.env.REACT_APP_CASES_API_URL}/${id}/files/eml.png`
    : "";

  // Reprocess confirm modal
  const [showReprocessModal, setShowReprocessModal] = useState(false);
  const handleConclusionClick = () => setShowReprocessModal(true);
  const handleCloseReprocessModal = () => {
    if (!reprocessLoading) setShowReprocessModal(false);
  };
  const handleConfirmReprocess = async () => {
    try {
      const token = await getAccessTokenSilently();
      setShowReprocessModal(false);
      await dispatch(
        startReprocessCase({ accessToken: token, caseId: id! })
      ).unwrap();
      const result = await dispatch(
        pollReprocessCase({ accessToken: token, caseId: id! })
      ).unwrap();
      setSnackbarMessage("Case reprocessed successfully!");
      setSnackbarSeverity("success");
    } catch (err: any) {
      setSnackbarMessage(err.message || "Reprocess failed");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "info"
  >("success");
  const handleSnackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Add a helper function for the modal to call:
  function handleEmailStatus(
    message: string,
    severity: "success" | "error" | "info"
  ) {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }

  // -- RENDER --
  return (
    <Box sx={{ padding: "2rem" }}>
      {/* Loading */}
      {loading && <CaseDetailsLoadingSkeleton />}

      {/* Error */}
      {error && !loading && (
        <Box
          sx={{
            // This centers the content both vertically and horizontally,
            // and ensures it takes at least the majority of the view height.
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "60vh",
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 3,
              maxWidth: 600,
              width: "90%", // so it shrinks nicely on small screens
              textAlign: "center",
            }}
          >
            {typeof error === "string" ? (
              <Alert severity="error">{error}</Alert>
            ) : (
              <Alert severity="error" sx={{ textAlign: "left" }}>
                <Typography variant="h6" gutterBottom>
                  Error : {error.message}
                </Typography>
                {error.code && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    Status Code: {error.code}
                  </Typography>
                )}
                {error.details && (
                  <Box
                    component="pre"
                    sx={{
                      textAlign: "left",
                      backgroundColor: (theme) => theme.palette.grey[100],
                      p: 1,
                      mt: 1,
                      borderRadius: 1,
                      overflowX: "auto", // in case the JSON is wide
                    }}
                  >
                    {JSON.stringify(error.details, null, 2)}
                  </Box>
                )}
              </Alert>
            )}

            <Box
              sx={{ mt: 3, display: "flex", justifyContent: "center", gap: 2 }}
            >
              <Button variant="contained" onClick={handleGoBackToCases}>
                Back to Cases
              </Button>
              <Button variant="outlined" onClick={handleRefresh}>
                Refresh
              </Button>
            </Box>
          </Paper>
        </Box>
      )}

      {/* If "processing" */}
      {!loading && !error && isProcessing && (
        <Box sx={{ p: 4 }}>
          <Box
            sx={{
              border: `1px dashed ${theme.palette.warning.light}`,
              borderRadius: 2,
              p: 3,
              mb: 2,
            }}
          >
            <Typography variant="h6" mb={2} color="warning.main">
              This case is still being analyzed...
            </Typography>
            <Typography variant="body1" mb={2}>
              We’re automatically polling. You can also manually refresh:
            </Typography>
            <IconButton onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
          </Box>
        </Box>
      )}

      {/* Normal success */}
      {!loading && !error && !isProcessing && caseDetail && (
        <>
          <Box sx={{ marginBottom: theme.spacing(1.5) }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {from === "/cases" ? (
                <MuiLink
                  component={RouterLink}
                  to="/cases"
                  underline="hover"
                  color="inherit"
                  sx={{ fontSize: "0.75rem", color: theme.palette.grey[600] }}
                >
                  Cases
                </MuiLink>
              ) : (
                <MuiLink
                  component={RouterLink}
                  to="/dashboard"
                  underline="hover"
                  color="inherit"
                  sx={{ fontSize: "0.75rem", color: theme.palette.grey[600] }}
                >
                  Dashboard
                </MuiLink>
              )}
              <Typography
                sx={{ fontSize: "0.75rem", color: theme.palette.info.main }}
              >
                {isCloudCase
                  ? caseDetail.title
                  : `Subject: ${caseDetail.title}`}
              </Typography>
            </Breadcrumbs>
          </Box>

          <CaseSummarySection
            caseTitle={caseDetail.title}
            caseConclusion={caseDetail.conclusion || ""}
            keyIndicators={caseDetail.keyIndicators || []}
            caseNumber={caseDetail.id}
            createdOn={caseDetail.createdAt || ""}
            attackSurface={caseDetail.attackSurface}
            alertTime={
              caseDetail.alerts && caseDetail.alerts.length > 0
                ? caseDetail.alerts[0].timestamp || ""
                : ""
            }
            // TODO: will send in attacker & target for the new logic:
            attacker={caseDetail.attacker || null}
            target={caseDetail.target || null}
            // TODO: can still pass from if it's email, or simply pass an empty string:
            from={caseDetail.attacker}
            // TODO: will pass empty strings to analyst for now
            analyst={""}
            analystAvatar={""}
            status={caseDetail.state || "Unreviewed"}
            severity={caseDetail.severity || "Unknown"}
            device={caseDetail.attackSurface}
            description={caseDetail.summary || ""}
            feedback={feedback}
            feedbackReason={feedbackReason}
            feedbackLoading={feedbackLoading}
            feedbackError={feedbackError}
            isEditingFeedback={isEditingFeedback}
            feedbackOption={feedbackOption}
            feedbackText={feedbackText}
            onFeedbackOptionChange={handleFeedbackOptionChange}
            onFeedbackTextChange={handleFeedbackTextChange}
            onSubmitFeedback={handleSubmitFeedback}
            onAgree={(position, reason) =>
              handleNewSubmitFeedback(position, reason)
            }
            onDisagree={(position, reason) =>
              handleNewSubmitFeedback(position, reason)
            }
            onEditFeedback={handleEditFeedback}
            onCancelEdit={handleCancelEdit}
            setFeedbackText={setFeedbackText}
            setFeedbackOption={setFeedbackOption}
            onReprocessClick={handleConclusionClick}
            reprocessLoading={reprocessLoading}
            showSmeButton={!!caseDetail.internal}
            // For SME Feedback
            analyses={caseDetail.details?.analyses}
            version={caseDetail.version}
            caseId={caseDetail.id}
            existingFeedback={caseDetail.internal}
            caseDetail={caseDetail}
            onEmailStatus={handleEmailStatus}
            impact={caseDetail.impact || []}
            next_steps={caseDetail.next_steps || []}
            remediation={caseDetail.remediation || []}
          />

          {/* If it's a Cloud case, render the CloudFlow. Otherwise, if Email, render EmailFlow */}
          {isCloudCase ? (
            <CloudFlowAndInvestigativeSteps
              caseDetail={caseDetail}
              caseId={caseDetail.id}
              analyses={
                caseDetail.details?.analyses
                  ? caseDetail.details.analyses.map(
                      (analysis: { conclusion: any }) => ({
                        ...analysis,
                        conclusion: analysis.conclusion ?? "",
                      })
                    )
                  : []
              }
              hasNavigation={hasNavigation}
              canGoPrevious={canGoPrevious}
              canGoNext={canGoNext}
              onPrevious={handlePrevious}
              onNext={handleNext}
            />
          ) : (
            <EmailFlowAndInvestigativeSteps
              emailImageUrl={emailImageUrl}
              caseDetail={caseDetail}
              caseId={caseDetail.id}
              analyses={
                caseDetail.details?.analyses
                  ? caseDetail.details.analyses.map(
                      (analysis: { conclusion: any }) => ({
                        ...analysis,
                        conclusion: analysis.conclusion ?? "",
                      })
                    )
                  : []
              }
              hasNavigation={hasNavigation}
              canGoPrevious={canGoPrevious}
              canGoNext={canGoNext}
              onPrevious={handlePrevious}
              onNext={handleNext}
            />
          )}
        </>
      )}

      {/* Reprocess confirm modal */}
      <Modal open={showReprocessModal} onClose={handleCloseReprocessModal}>
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            Reprocess Case
          </Typography>
          {reprocessError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {reprocessError}
            </Alert>
          )}
          <Typography>Are you sure you want to reprocess this case?</Typography>

          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button
              variant="text"
              color="info"
              onClick={handleCloseReprocessModal}
              disabled={reprocessLoading}
              sx={{
                mr: 2,
                backgroundColor: "transparent",
                color: theme.palette.text.primary,
                width: "120px",
                "&:hover": {
                  backgroundColor: theme.palette.grey[200],
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={handleConfirmReprocess}
              disabled={reprocessLoading}
              sx={{
                backgroundColor: theme.palette.info.main,
                color: "#fff",
                width: "120px",
                "&:hover": {
                  backgroundColor: theme.palette.info.dark,
                },
              }}
            >
              {reprocessLoading ? (
                <CircularProgress size={20} sx={{ mr: 1 }} />
              ) : (
                "Reprocess"
              )}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CaseDetailsPage;
